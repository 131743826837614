import React from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import green from "../../../../../assets/images/green.png";

const UnsortedeModal = ({
  showUnsorted,
  handleCloseUnsorted,
  form,
  handleFormChange,
  unsortedValue,

  cleanUnsortedDeduction,
  cleanUnsortedKg,
  handleInputChangeCU,
}) => {
  const history = useHistory();
  return (
    <div>
      <Modal
        show={showUnsorted}
        onHide={() => {
          handleCloseUnsorted();
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left">
            <h5>Price Deductions</h5>

            <p className="my-4">
              Reduce the price of the Used Plastics due to impurities noticed.
              Enter a value deduction in percentage
            </p>

            <div className="mb-3 mt-2">
              <label className="mb-1 form-label">Clean Unsorted</label>
              <input
                type="text"
                className="form-control form-control-lg inputStyle"
                placeholder="% Deduction"
                value={cleanUnsortedDeduction}
                onChange={handleInputChangeCU}
                disabled={cleanUnsortedKg === "" || cleanUnsortedKg === 0}
                maxLength={1}
              />
            </div>

            <div className="pt-4 pb-5">
              <button
                type="button"
                className="btn loginBtn btn-sm btn-block"
                onClick={handleCloseUnsorted}
              >
                Make Deduction
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UnsortedeModal;
