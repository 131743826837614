import React, { useState } from "react";
import { toast } from "react-toastify";

const Selected = ({ assignDriver, driversList, date }) => {
  const [selectedDriver, setSelectedDriver] = useState("");
  return (
    <div>
      <select
        name=""
        id=""
        className="bg-white py-1 px-2 border-0 rounded"
        style={{
          cursor: "pointer",
        }}
        multiple={false}
        onChange={(e) => {
          setSelectedDriver(e.target.value);
          if (e.target.value === "") {
            toast.error("Kindly select a Driver", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              pauseOnHover: true,
              progress: undefined,
              theme: "light",
            });
            return;
          } else {
            assignDriver(e.target.value, date.code);
          }
        }}
        value={selectedDriver}
        key={date.id}
      >
        <option value="">
          {date?.driver?.name ? date?.driver?.name : "Select Driver"}
        </option>
        {driversList.map((driver, index) => (
          <option key={index} value={driver.id}>
            {`Driver: ${driver.name}`}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Selected;
