import React, { useState, useEffect } from "react";

const SettingsCollections = ({ setTitleName }) => {
  const center = localStorage.getItem("center");
  const centerInformation = JSON.parse(localStorage.getItem("centerInfo"));
  // console.log(centerInformation);
  useEffect(() => {
    setTitleName("Settings");
  }, []);
  return (
    <main>
      {/* Settings */}
      <div className="px-4 py-3" style={{ zIndex: "2" }}>
        <div className="w-60 pt-3 newW">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <label className="label-control mb-0 labelStyle">
                  Email Address
                </label>
                <input
                  type="email"
                  className="form-control inputStyle"
                  placeholder="Enter Your Email Address"
                  value={centerInformation?.collection_center?.email}
                  disabled
                />
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <label className="label-control mb-0 labelStyle">
                  Location
                </label>
                <input
                  type="text"
                  className="form-control inputStyle"
                  placeholder="5th Cerescent Avenue, Lekki Phase 1, Lagos"
                  value={centerInformation?.collection_center?.address}
                  disabled
                />
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <label className="label-control mb-0 labelStyle">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control inputStyle"
                  placeholder="090 2442 2433"
                  value={centerInformation?.collection_center?.phone}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div className="my-1">
          <p style={{ fontSize: "13px" }}>Want to edit any detail?</p>
        </div>
        <div className="pt-3 w-25 adjW2">
          <button
            type="button"
            className="btn loginBtn btn-sm btn-block"
            onClick={() =>
              (window.location = `mailto:switch@switchrecycling.com?subject=${center}`)
            }
          >
            Contact Switch Admin
          </button>
        </div>
      </div>
    </main>
  );
};

export default SettingsCollections;
