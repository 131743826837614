import moment from "moment";
import React from "react";
import "./index.css";
import { MdKeyboardArrowUp } from "react-icons/md";
import { RiArrowDownSLine } from "react-icons/ri";

const Resultss = ({ req, index, data }) => {
  // console.log("data", data);
  // console.log("req", req);
  return (
    <div key={index}>
      <div className="d-flex justify-content-center mb-3">
        <h6 className="py-2 notificaationDate">{req}</h6>
      </div>

      {data.map((notification, index) => {
        return (
          <div key={index}>
            {/* <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center mb-1">
                <div
                  style={{
                    height: "10px",
                    width: "10px",
                    borderRadius: "50%",
                    backgroundColor: "#C4DA00",
                  }}
                ></div>
                <p className="mb-0 ml-3">{notification.data.message}</p>
              </div>
              <div>
                <p className="mb-0">
                  {moment(notification.created_at).format("hh:mm A")}
                </p>
              </div>
            </div> */}
            <div className="d-flex justify-content-start">
              <div>
                <MdKeyboardArrowUp
                  size={24}
                  color="#fff"
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "#000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </div>
              <div className="ml-3">
                <p className="mb-0 notificationText">
                  {notification.data.message}
                </p>
                <p className="mb-0 mt-1 notificationAmount">
                  {notification.data?.amount}
                </p>
              </div>
            </div>
            <hr style={{ background: "#F8FA82" }} />
          </div>
        );
      })}
    </div>
  );
};

export default Resultss;
