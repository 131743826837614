import axios from "axios";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { switchEndpoint } from "../../../../../../../../redux/actions/endpoints";
import "./index.css";

const UnregistereEnterCodeModal = ({
  showCode,
  handleCloseCode,
  handleShowBottle,
  getHouseholdReq,
  getAgentReq,
}) => {
  const [unregistered, setUunregistered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropOff, setDropOff] = useState("");
  const [dropOffData, setDropOffData] = useState({});

  const savedName = localStorage.getItem("names");

  const getSingleDropOff = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/single-dropoff/${dropOff}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data.data);
        setDropOffData(response.data.data);
        setLoading(false);
        handleCloseCode();
        setDropOff("");
        handleShowBottle();
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal
        show={showCode}
        onHide={() => {
          setDropOff("");
          handleCloseCode();

          getHouseholdReq();
          getAgentReq();
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <h5 className="sec-headers mb-3">Enter Drop Off Code</h5>
              <h6>Drop Off Code Sent Succesfully</h6>
              <p className="ftsz-regular" style={{ fontSize: "14px" }}>
                A Drop Off code has been sent to {savedName}, input the code to
                get started.
              </p>
            </div>
            <div className="w-50">
              <div className="mb-3">
                <label className="mb-1 form-label">Drop Off Code</label>
                <input
                  type="text"
                  className="form-control form-control-lg inputStyle"
                  placeholder="Enter drop off code"
                  value={dropOff}
                  onChange={(e) => setDropOff(e.target.value)}
                />
              </div>

              <div className="mt-4 mb-5">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={() => {
                    getSingleDropOff();
                  }}
                  disabled={loading}
                >
                  {loading ? "Validating ..." : "Start Transaction"}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UnregistereEnterCodeModal;
