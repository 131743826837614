export function getWeightByMonth(month, weightData) {
  // Iterate through the array to find the object with the matching month
  for (const data of weightData) {
    if (data.month === month) {
      return data.weight; // Return the weight if the month matches
    }
  }

  // If the desired month is not found in the array, you can return null or any other appropriate value.
  return null;
}
