import React, { useState } from "react";
import "./navbar.css";
import newLogo from "../../../../../assets/images/newLogo.png";
import message from "../../../../../assets/images/messages.png";
import notification from "../../../../../assets/images/notification.png";
import logout from "../../../../../assets/images/logout.png";
import { useHistory, useLocation } from "react-router-dom";
import LogoutModal from "./modal/logout";
import { HiMenuAlt2, HiOutlineMenuAlt3 } from "react-icons/hi";
import { GrFormClose } from "react-icons/gr";

const DriversNavigation = ({ openSidebar, titleName }) => {
  const center = localStorage.getItem("center");
  const history = useHistory();
  const location = useLocation();

  const [showLogout, setShowLogout] = useState(false);
  const handleCloseLogout = () => setShowLogout(false);
  const handleShowLogout = () => setShowLogout(true);

  const [toggle, setToggle] = useState(false);

  return (
    <nav>
      <div className="navbar d-flex justify-content-between align-items-center">
        <div
          className="sidebar__imgs"
          style={{ cursor: "pointer", width: "120px" }}
          onClick={() => history.push("/drivers/dashboard")}
        >
          <img src={newLogo} alt="logo" style={{ width: "100%" }} />
        </div>
        <p className="mb-0">{titleName}</p>
        <div>
          <div
            style={{
              backgroundColor: "#c4da00",
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              transition: "0.5s all ease-in",
            }}
            onClick={() => setToggle(!toggle)}
          >
            {toggle ? (
              <GrFormClose style={{ color: "#fff" }} />
            ) : (
              <HiOutlineMenuAlt3 style={{ color: "#fff" }} />
            )}
          </div>
        </div>
      </div>
      {toggle && (
        <div
          style={{
            position: "absolute",
            top: "20",
            right: "0",
            minWidth: "300px",
            background: "white",
            border: "1px solid #c4da00",
            boxShadow: "5px 5px 13px #ededed, -5px 13px #fff",
            zIndex: "999",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="mx-4 my-2 py-3 px-2"
        >
          <div className="form-group">
            <div className="menuIc">
              <ul className="text-right">
                <li
                  className={`py-1 mt-5 mb-3 text-right ${
                    location.pathname === "/drivers/dashboard"
                      ? "active-menu_link"
                      : "sidebar__link"
                  }`}
                  style={{ borderRadius: "5px" }}
                >
                  <a href="/drivers/dashboard">
                    <img src={message} alt="nav logo" /> &nbsp; Pick Up
                  </a>
                </li>

                <li
                  className={`py-1 mb-3 ${
                    location.pathname === "/drivers/messages"
                      ? "active-menu_link"
                      : "sidebar__link"
                  }`}
                  style={{ borderRadius: "5px" }}
                >
                  <a href="/drivers/messages">
                    <img src={notification} alt="nav logo" /> &nbsp; Messages
                  </a>
                </li>
                <li
                  className={`py-1 mb-3 ${
                    location.pathname === "/drivers/messages"
                      ? "active-menu_link"
                      : "sidebar__link"
                  }`}
                  style={{ borderRadius: "5px" }}
                >
                  <a href="/drivers/notifications">
                    <img src={notification} alt="nav logo" /> &nbsp;
                    Notifications
                  </a>
                </li>
                <li
                  className={`py-1 mb-3 ${
                    location.pathname === "/drivers/wallet"
                      ? "active-menu_link"
                      : "sidebar__link"
                  }`}
                  style={{ borderRadius: "5px" }}
                >
                  <a href="/drivers/wallet">
                    <img src={notification} alt="nav logo" /> &nbsp; Wallet
                  </a>
                </li>
                <li
                  className={`py-1 ${
                    location.pathname === "/dashboard"
                      ? "active-menu_link"
                      : "sidebar__link"
                  }`}
                  style={{ borderRadius: "5px" }}
                  onClick={handleShowLogout}
                >
                  <a href="#">
                    <img src={notification} alt="nav logo" /> &nbsp; Log Out
                  </a>
                </li>
                <li className="sidebar__link"></li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <LogoutModal
        handleCloseLogout={handleCloseLogout}
        showLogout={showLogout}
        history={history}
      />
    </nav>
  );
};

export default DriversNavigation;
