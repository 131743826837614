import { Form, Input } from "antd";
import React from "react";
import { Modal } from "react-bootstrap";

const AuthenticateModal = ({
  handleCloseAuthenticate,
  showAuthenticate,
  handleShowSuccess,
}) => {
  return (
    <div>
      <Modal
        show={showAuthenticate}
        onHide={handleCloseAuthenticate}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <div className="text-center mt-3 mb-5">
                <h5 className="sec-headers mb-3">Authenticate Transaction</h5>

                <div className="text-left w-50 m-auto">
                  <label className="label-control mb-0 labelStyle">
                    Enter Password
                  </label>
                  <Form>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    //   value={password}
                    //   onChange={(e) => setPassword(e.target.value)}
                    >
                      <Input.Password placeholder="*************" />
                    </Form.Item>
                  </Form>
                </div>

                <div className="mt-1 mb-5 w-btn m-auto pt-3">
                  <button
                    type="button"
                    className="btn loginBtn btn-sm btn-block d-flex justify-content-center align-items-center"
                    onClick={() => {
                        handleCloseAuthenticate();
                        handleShowSuccess();
                    }}
                    // disabled={loading}
                  >
                    Authenticate Transaction
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AuthenticateModal;
