import React from "react";
import { useState, useCallback, useRef, useEffect } from "react";
import { GoogleMap, Marker, DirectionsRenderer } from "@react-google-maps/api";
import { GiPathDistance, GiDuration } from "react-icons/gi";

const NewMapComponent = ({ directionDetails, longitude, latitude }) => {
  const initialCoordinates = {
    lat: directionDetails.owner.latitude,
    lng: directionDetails.owner.longitude,
  };

  //   console.log(directionDetails);
  const initialCoordinates2 = { lat: latitude, lng: longitude };
  const [directions, setDirections] = useState();
  const [office, setOffice] = useState(initialCoordinates);
  const mapRef = useRef;

  const onLoad = useCallback((map) => (mapRef.current = map));

  const fetchDirections = (location) => {
    const google = window.google;
    if (!office) return;

    const service = new google.maps.DirectionsService();
    service.route(
      {
        origin: location,
        destination: office,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === "OK" && result) {
          setDirections(result);
        }
      }
    );
  };

  useEffect(() => {
    fetchDirections(initialCoordinates2);
  }, [longitude, latitude]);

  //   if (directions) console.log(directions);
  return (
    <div>
      <div>
        <GoogleMap
          zoom={15}
          center={initialCoordinates}
          mapContainerStyle={{
            height: directions ? "85vh" : "90vh",
            width: "100%",
            overflowY: "hidden",
          }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={onLoad}
        >
          {directions && (
            <div
              class="btn btn-secondary"
              data-toggle="tooltip"
              data-placement="right"
              title="Tooltip on right"
            >
              <DirectionsRenderer
                data-toggle="tooltip"
                data-placement="right"
                title="Tooltip on right"
                directions={directions}
                options={{
                  polylineOptions: {
                    zIndex: 50,
                    strokeColor: "#1976D2",
                    strokeWeight: 5,
                  },
                }}
              />
            </div>
          )}
          {office && (
            <>
              <Marker
                position={initialCoordinates}
                icon={{
                  scaledSize: new window.google.maps.Size(40, 40),
                  url: require("./carMarkerActive.png"),
                }}
                onClick={() => {
                  fetchDirections(initialCoordinates2);
                }}
              />
            </>
          )}
        </GoogleMap>
      </div>
      {directions && (
        <div className="distance mx-2 mt-4 text-center">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div>
                <GiPathDistance size={24} />
              </div>
              <p className="mb-0 ml-1">
                {directions?.routes[0]?.legs[0]?.distance?.text}
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <GiDuration size={24} />
              </div>
              <p className="mb-0 ml-1">
                {directions?.routes[0]?.legs[0]?.duration?.text}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewMapComponent;
