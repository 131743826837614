import { Table } from "antd";
import React, { useState } from "react";
import AuthenticateModal from "./modals/authenticate";
import FundModal from "./modals/fund";
import SuccessfulModal from "./modals/successful";
import "./index.css";

const FundDriver = ({
  switchToTransaction,
  switchToFundCenter,
  driversList,
  getWalletBalance,
  switchToFloat,
}) => {
  const [showFund, setShowFund] = useState(false);
  const handleCloseFund = () => setShowFund(false);
  const handleShowFund = () => setShowFund(true);

  const [showAuthenticate, setShowAuthenticate] = useState(false);
  const handleCloseAuthenticate = () => setShowAuthenticate(false);
  const handleShowAuthenticate = () => setShowAuthenticate(true);

  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);

  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      key: "id",
      sorter: false,
      render: (id, record, index) => {
        ++index;
        return index;
      },
    },
    {
      title: "Driver",
      dataIndex: "name",
      key: "driver",
      //   render: (text) => <a>{text}</a>,
    },
    {
      title: "Wallet Balance",
      dataIndex: `account`,
      key: "walletBalance",
      render: (account) => {
        return (
          <p className="mb-0">{`₦${
            account.account_balance ? account.account_balance : "0.00"
          }`}</p>
        );
      },
    },
    {
      title: "Cash Balance",
      dataIndex: `cash_balance`,
      key: "cash_balance",
      render: (driver) => {
        return <p className="mb-0">{`₦${driver ? driver : "0.00"}`}</p>;
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phoneNumber",
    },
    {
      title: "",
      dataIndex: "",
      key: "action",
      render: (driver) => {
        return (
          <p
            className="mb-0"
            style={{
              backgroundColor: "#c4da00",
              boxShadow: "none !important",
              width: "max-content",
              padding: "5px 10px",
              textAlign: "center",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              localStorage.setItem("driver", JSON.stringify(driver));
              handleShowFund();
            }}
          >
            Fund Wallet
          </p>
        );
      },
    },
  ];
  return (
    <div>
      <div className="requestss">
        <div className="cardss p-0 py-1 px-2">
          <div className="buttonHolders py-3">
            <button className="unstyled def" onClick={switchToTransaction}>
              Transactions
            </button>
            <button className="unstyled def ml-2" onClick={switchToFundCenter}>
              Fund Collection Center
            </button>
            <button className="styled def ml-2" style={{ color: "#fff" }}>
              Fund Driver
            </button>
            <button className="unstyled def ml-2" onClick={switchToFloat}>
              Float Requests
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex mb-3">
              <b>Fund Driver </b>
              <div>
                <div className="form-check form-check-inline ml-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                    // checked
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    Low to High
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="option1"
                  />
                  <label className="form-check-label" for="inlineRadio2">
                    High to Low
                  </label>
                </div>
              </div>
            </div>
            <div className="fpw mr-2">
              <p style={{ color: "#231F20", fontSize: "13px" }} className="">
                Filter By
                <span style={{ color: "#c4da00", marginLeft: "5px" }}>
                  &#62;
                </span>
              </p>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={driversList}
            size="middle"
            pagination={true}
          />
        </div>
      </div>

      <FundModal
        handleCloseFund={handleCloseFund}
        showFund={showFund}
        handleShowAuthenticate={handleShowAuthenticate}
      />
      <SuccessfulModal
        handleCloseSuccess={handleCloseSuccess}
        showSuccess={showSuccess}
        getWalletBalance={getWalletBalance}
      />
      <AuthenticateModal
        handleCloseAuthenticate={handleCloseAuthenticate}
        showAuthenticate={showAuthenticate}
        handleShowSuccess={handleShowSuccess}
        getWalletBalance={getWalletBalance}
        handleShowFund={handleShowFund}
      />
    </div>
  );
};

export default FundDriver;
