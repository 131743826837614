import React, { useState, useEffect } from "react";
import "./index.css";
import newLogo from "../../../../assets/images/newLogo.png";
import { Form, Input } from "antd";
import { switchEndpoint } from "../../../../redux/actions/endpoints";
import axios from "axios";
import { toast } from "react-toastify";

const SuperAdminReset = (props) => {
  const [loading, setLoading] = useState(false);
  const [upperCase, setUppercase] = useState(false);
  const [numbersIncl, setNumbersIncl] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [resetData, setResetData] = useState({
    password: "",
    password_confirmation: "",
  });

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  useEffect(() => {
    const em = localStorage.getItem("email");
    const cd = localStorage.getItem("code");
    setEmail(em);
    setCode(cd);
  }, []);

  const handleResetForm =
    (name) =>
    ({ target }) => {
      setResetData({
        ...resetData,
        [name]: target.value,
      });
      const hasUppercase = /[A-Z]/.test(target.value);
      if (hasUppercase) {
        setUppercase(true);
      } else {
        setUppercase(false);
      }

      const hasNums = /[0-9]/.test(target.value);
      if (hasNums) {
        setNumbersIncl(true);
      } else {
        setNumbersIncl(false);
      }

      const specialCharRegex = /[^a-zA-Z0-9\s]/;
      const hasSpecialCharacter = specialCharRegex.test(target.value);

      if (hasSpecialCharacter) {
        setSpecialChar(true);
      } else {
        setSpecialChar(false);
      }
    };

  // const handleReset = () => {
  //   if (resetData.password.length < 8) {
  //     toast.error("Password must be at least 8 characters.", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       pauseOnHover: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     return;
  //   } else if (resetData.password !== resetData.password_confirmation) {
  //     toast.error("Passwords do not match.", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       pauseOnHover: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     return;
  //   } else if (!upperCase) {
  //     toast.error("Passwords must contain an uppercase character.", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       pauseOnHover: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     return;
  //   } else if (!numbersIncl) {
  //     toast.error("Passwords must contain at least a number.", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       pauseOnHover: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     return;
  //   } else if (!specialChar) {
  //     toast.error(
  //       "Passwords must contain a special character (e.g @#$()!-_+).",
  //       {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         pauseOnHover: true,
  //         progress: undefined,
  //         theme: "light",
  //       }
  //     );
  //     return;
  //   } else {
  //     let payload = {
  //       email: email,
  //       password: resetData.password,
  //       password_confirmation: resetData.password_confirmation,
  //       code: code,
  //     };
  //     console.log(payload);
  //     setLoading(true);
  //     axios
  //       .post(`${switchEndpoint}/auth/reset-password-email`, payload)
  //       .then((response) => {
  //         console.log(response.data);
  //         setLoading(false);
  //         toast.success(response.data.message, {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           pauseOnHover: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //         localStorage.setItem("token", response.data.token);
  //         props.history.push("/dashboard");
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setLoading(false);
  //         toast.error(error.response.data.message, {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           pauseOnHover: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //       });
  //   }
  // };

  return (
    <div>
      <div className="row noMarginPadding">
        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-8 col-xs-12 PaddingLeft-10">
          <div className="container pl-4 pt-4 PaddingLeft-10">
            <div style={{ width: "150px" }}>
              <img src={newLogo} alt="logo" style={{ width: "100%" }} />
            </div>
            <div className="w-75 ml-5 mt-5 containerWidth">
              <h5 className="headerText headerh5">Switch Admin</h5>
              <p className="loginStyle mb-0">Reset Password</p>
              <div>
                <label className="label-control mb-0 labelStyle">
                  Enter New Password
                </label>
                <Form>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    value={resetData.password}
                    onChange={handleResetForm("password")}
                  >
                    <Input.Password placeholder="*************" />
                  </Form.Item>
                </Form>
              </div>
              <div>
                <label className="label-control mb-0 labelStyle">
                  Confirm New Password
                </label>
                <Form>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    value={resetData.password_confirmation}
                    onChange={handleResetForm("password_confirmation")}
                  >
                    <Input.Password placeholder="*************" />
                  </Form.Item>
                </Form>
              </div>

              <div className="mx-3">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  disabled={loading}
                  // onClick={handleReset}
                  onClick={() => {
                    props.history.push("/admin");
                  }}
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-4 col-md-4 rightImg"></div>
      </div>
    </div>
  );
};

export default SuperAdminReset;
