import React from "react";
import Map from "./map";

const TestComponent = ({ drivers }) => {
  return (
    <div>
      <Map drivers={drivers} />
    </div>
  );
};

export default TestComponent;
