import React, { useState, useEffect } from "react";
import DriversNavigation from "../components/Navbar/navbar";
import calendar from "../../../../assets/images/cal.png";
import axios from "axios";
import { switchEndpoint } from "../../../../redux/actions/endpoints";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import Result from "./Results";
import FilterModal from "./filter";
import FloatSuccessfulModal from "../main/modals/floatSuccess";
import FloatRequestModal from "../main/modals/floatRequest";

const DriversWallet = () => {
  const [loading, setLoading] = useState(false);
  const [walletBal, setWalletBal] = useState([]);
  const [walletHistory, setWalletHistory] = useState([]);
  const [groupedDate, setGroupedDate] = useState({});
  const othersObj = {};
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => setShowFilter(false);
  const handleShowFilter = () => setShowFilter(true);

  const fetchBalance = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/driver/fetch-balance`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data;
        // console.log(result);
        setWalletBal(result);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  const fetchHistory = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let payload = {
      start_date: "",
      end_date: currentDate,
    };
    axios
      .post(`${switchEndpoint}/driver/transaction-history`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data.data;
        // console.log(result);
        setWalletHistory(result);
        result.forEach((element) => {
          const date = element.date;
          if (date in othersObj) {
            othersObj[date] = [...othersObj[date], element];
          } else {
            othersObj[date] = [element];
          }
        });
        setGroupedDate(othersObj);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  const filterHistory = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let payload = {
      start_date: start,
      end_date: end,
    };
    axios
      .post(`${switchEndpoint}/driver/transaction-history`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data.data;
        // console.log(result);
        setWalletHistory(result);
        result.forEach((element) => {
          const date = element.date;
          if (date in othersObj) {
            othersObj[date] = [...othersObj[date], element];
          } else {
            othersObj[date] = [element];
          }
        });
        setGroupedDate(othersObj);
        setLoading(false);
        handleCloseFilter();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
        handleCloseFilter();
      });
  };

  const [showFloat, setShowFloat] = useState(false);
  const handleCloseFloat = () => setShowFloat(false);
  const handleShowFloat = () => setShowFloat(true);

  const [showFloatSuccess, setShowFloatSuccess] = useState(false);
  const handleCloseFloatSuccess = () => setShowFloatSuccess(false);
  const handleShowFloatSuccess = () => setShowFloatSuccess(true);

  useEffect(() => {
    fetchBalance();
    fetchHistory();
  }, []);
  return (
    <div>
      <DriversNavigation />
      <div className="">
        <div className="mx-4">
          <p
            className="mb-0 my-3"
            style={{ fontSize: "22px", fontWeight: "500" }}
          >
            Wallet
          </p>
        </div>
        <div className="">
          {/* <div className="cardz">
            {loading ? (
              <span
                style={{
                  color: "000",
                  marginLeft: "2px",
                  marginTop: "1px",
                }}
                className="text-center py-5"
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </span>
            ) : (
              <div className="text-center">
                <p>Wallet Balance</p>
                <h4 className="mb-0" style={{ fontWeight: "400" }}>
                  {`₦${walletBal[0]?.balance}`}
                </h4>

                <div className="mt-3 text-center">
                  <p className="" style={{ fontSize: "15px" }}>
                    Cash Balance: {`₦${walletBal[0]?.cash_balance}`}
                  </p>
                  <p style={{ fontSize: "15px" }}>
                    Wallet Balance: {`₦${walletBal[0]?.wallet_balance}`}
                  </p>
                </div>
              </div>
            )}

            <div className="pt-3 w-25 adjW2">
              <button
                type="button"
                className="btn loginBtn btn-sm btn-block"
                onClick={handleShowFloat}
                style={{ fontWeight: "400" }}
              >
                Request For Float
              </button>
            </div>
          </div> */}

          <div className="">
            <div className="cardz">
              <p className="mb-0">Wallet Balance</p>
              {loading ? (
                <span
                  style={{
                    color: "000",
                    marginLeft: "2px",
                    marginTop: "1px",
                  }}
                  className="text-center py-2"
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </span>
              ) : (
                <h4 className="mb-0" style={{ fontWeight: "400" }}>
                  {`₦${walletBal[0]?.wallet_balance}`}
                </h4>
              )}

              <p className="mt-3 mb-0">Cash Balance</p>
              {loading ? (
                <span
                  style={{
                    color: "000",
                    marginLeft: "2px",
                    marginTop: "1px",
                  }}
                  className="text-center py-2"
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </span>
              ) : (
                <h4 className="mb-0" style={{ fontWeight: "400" }}>
                  {`₦${walletBal[0]?.cash_balance}`}
                </h4>
              )}

              <div className="pt-3 w-25 adjW2">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={handleShowFloat}
                  style={{ fontWeight: "400" }}
                >
                  Request For Float
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-4 mt-3">
          <h5
            className="card_inners_amount mb-4"
            style={{
              fontSize: "18px",
              paddingTop: "0",
              marginTop: "0",
            }}
          >
            Transaction History
          </h5>

          <div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div></div>
              <p className="mb-0"></p>
              <div>
                <div
                  style={{
                    backgroundColor: "#C4DA00",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                  onClick={handleShowFilter}
                >
                  <img src={calendar} style={{ width: "60%" }} alt="calendar" />
                </div>
              </div>
            </div>

            <div className="mt-2">
              {/* <p className="mb-0 py-3">No pick up available</p> */}
              {walletHistory.length === 0 ? (
                <p className="my-3">No history available</p>
              ) : (
                <div className="mt-3">
                  {loading ? (
                    <div className="card_inners_amount mt-5 text-center">
                      <span>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                  ) : (
                    <div>
                      {!Object.keys(groupedDate).length ? (
                        ""
                      ) : (
                        <div>
                          {Object.keys(groupedDate).length &&
                            Object.keys(groupedDate).map((req, index) => {
                              return (
                                <Result
                                  req={req}
                                  data={groupedDate[req]}
                                  index={index}
                                  fetchHistory={fetchHistory}
                                  // avatar={avatar}
                                />
                              );
                            })}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <FilterModal
        showFilter={showFilter}
        handleCloseFilter={handleCloseFilter}
        start={start}
        end={end}
        setStart={setStart}
        setEnd={setEnd}
        filterHistory={filterHistory}
        loading={loading}
      />

      <FloatRequestModal
        showFloat={showFloat}
        handleCloseFloat={handleCloseFloat}
        handleShowFloatSuccess={handleShowFloatSuccess}
      />

      <FloatSuccessfulModal
        showFloatSuccess={showFloatSuccess}
        handleCloseFloatSuccess={handleCloseFloatSuccess}
      />
    </div>
  );
};

export default DriversWallet;
