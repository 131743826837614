import React from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import green from "../../../../../assets/images/failed.png";

const PaymentRejectedModal = ({ showRejected, handleCloseRejected }) => {
  const history = useHistory();
  return (
    <div>
      <Modal
        show={showRejected}
        onHide={() => {
          handleCloseRejected();
          //   history.push("/drivers/start");
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="py-5">
              <img src={green} alt="green" style={{ width: "60%" }} />
            </div>

            <div className="pb-4">
              <p
                className="mt-2 mb-4"
                style={{ fontWeight: "600", fontSize: "16px" }}
              >
                Payment Request Rejected
              </p>
              <div className="pb-3">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={() => {
                    history.push("/drivers/dashboard");
                  }}
                  //   disabled={loading}
                >
                  Back to Pick Up
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentRejectedModal;
