import React, { useState } from "react";
import DriversNavigation from "../../components/Navbar/navbar";
import AuthenticateDriverModal from "../modals/authenticate";
import TransactionSuccessfulModal from "../modals/transactionS";

const ReviewTransaction = () => {
  const pickUpDetails = JSON.parse(localStorage.getItem("pickUp"));
  const pickUpDetail = JSON.parse(localStorage.getItem("pickUpDetails"));
  const initiateDetails = JSON.parse(localStorage.getItem("initiate"));
  const userDetails = JSON.parse(localStorage.getItem("user"));
  // console.log(userDetails);
  // console.log(pickUpDetails);
  const [showAuth, setShowAuth] = useState(false);
  const handleCloseAuth = () => setShowAuth(false);
  const handleShowAuth = () => setShowAuth(true);

  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);

  return (
    <div>
      <DriversNavigation />
      <div className="mx-3">
        <p style={{ fontSize: "16px", fontWeight: "500" }} className="py-3">
          Review
        </p>
        <div className="cardss py-5">
          <div className="d-flex align-items-center">
            <p className="mb-0">From: </p>
            <p className="mb-0 ml-2">{`${userDetails?.customer?.account?.first_name} ${userDetails?.customer?.account?.last_name}`}</p>
          </div>
          <hr style={{ background: "#F8FA82" }} />

          <div className="d-flex align-items-center">
            <p className="mb-0">To: </p>
            <p className="mb-0 ml-2">{initiateDetails?.owner?.name}</p>
          </div>
          <hr style={{ background: "#F8FA82" }} />

          <div className="d-flex align-items-center">
            <p className="mb-0">Total Weight: </p>
            <p className="mb-0 ml-2">
              {pickUpDetail
                ? `${pickUpDetail?.pickup_details?.total_kg}kg`
                : `${pickUpDetails?.pickup_details?.total_kg}kg`}
            </p>
          </div>
          <hr style={{ background: "#F8FA82" }} />

          <div className="d-flex align-items-center">
            <p className="mb-0">Amount: </p>
            <p className="mb-0 ml-2">
              {pickUpDetail
                ? `₦${pickUpDetail?.pickup_details?.total_price}`
                : `₦${pickUpDetails?.pickup_details?.total_price}`}
            </p>
          </div>
          <hr style={{ background: "#F8FA82" }} />

          <div className="d-flex align-items-center">
            <p className="mb-0">Deduction: </p>
            <p className="mb-0 ml-2">
              {pickUpDetail
                ? `₦${pickUpDetail?.pickup_details?.total_deduction}`
                : `₦${pickUpDetails?.pickup_details?.total_deduction}`}
            </p>
          </div>
          <hr style={{ background: "#F8FA82" }} />

          <div className="d-flex align-items-center">
            <p className="mb-0">
              Total Debit:{" "}
              {pickUpDetail
                ? `₦${pickUpDetail?.pickup_details?.total_price}`
                : `₦${pickUpDetails?.pickup_details?.total_price}`}
            </p>
            <p className="mb-0 ml-2"></p>
          </div>
        </div>
        <div className="mt-5">
          <button
            type="button"
            className="btn greenBtn btn-sm btn-block"
            onClick={handleShowAuth}
            // disabled={loading}
          >
            Transfer
          </button>
        </div>
      </div>

      <AuthenticateDriverModal
        showAuth={showAuth}
        handleCloseAuth={handleCloseAuth}
        handleShowSuccess={handleShowSuccess}
      />

      <TransactionSuccessfulModal
        showSuccess={showSuccess}
        handleCloseSuccess={handleCloseSuccess}
      />
    </div>
  );
};

export default ReviewTransaction;
