import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import WithInactivityTimer from "../../../inactivityTimer/withActivityTimer";

const SuperAdminDashboard = ({ modalStatus }) => {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [titleName, setTitleName] = useState("Dashboard");
  const [open, setOpen] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  // console.log(isMobile);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 500px)");
    // console.log(mediaQuery);
    setIsMobile(mediaQuery.matches);
    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return (
    <div className="containerss">
      <h6>Hello Dashboard !!!</h6>
      {/* {isMobile ? (
        <NavbarMobile
          sidebarOpen={sidebarOpen}
          openSidebar={openSidebar}
          titleName={titleName}
        />
      ) : (
        <Navbar
          sidebarOpen={sidebarOpen}
          openSidebar={openSidebar}
          titleName={titleName}
        />
      )} */}

      {/* <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} /> */}

      {/* {location.pathname === "/dashboard" && <MainArea />}

      {location.pathname === "/dashboard/dropOff" && (
        <PickUp setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboard/pickUp" && (
        <Pick setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboard/drivers" && (
        <DriversAggregation setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboard/collectionCenter" && (
        <CollectionCenter setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboard/support" && (
        <SupportAggregation setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboard/settings" && (
        <SettingsAggregation setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboard/wallet" && (
        <WalletAggregation setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboard/collections" && (
        <Collectionn setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboard/history" && (
        <AggregationHistory setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboard/notification" && (
        <AggregationNotification setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboard/message" && (
        <MessagesComponents setTitleName={setTitleName} />
      )} */}
    </div>
  );
};

// export default SuperAdminDashboard;
export default WithInactivityTimer(SuperAdminDashboard);
