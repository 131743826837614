import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import "./index.css";
import newLogo from "../../../../assets/images/newLogo.png";
import { switchEndpoint } from "../../../../redux/actions/endpoints";

const DriverLogin = (props) => {
  const [loading, setLoading] = useState(false);

  const [loginData, setLoginData] = useState({
    phone: "",
    password: "",
  });

  // const [loginData, setLoginData] = useState({
  //   phone: "09045368473",
  //   password: "julipels",
  // });

  // console.log(envs)

  const handleLoginForm =
    (name) =>
      ({ target }) => {
        setLoginData({
          ...loginData,
          [name]: target.value,
        });
      };

  let payload = {
    phone: loginData.phone,
    password: loginData.password,
  };

  const handleLogin = () => {
    setLoading(true);
    axios
      .post(`${switchEndpoint}/driver/login`, payload)
      .then((response) => {
        // console.log(response.data);
        setLoading(false);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data));
        //   localStorage.setItem(
        //     "centerInfo",
        //     JSON.stringify(response.data.customer)
        //   );
        //   localStorage.setItem(
        //     "center",
        //     response.data.customer.aggregation_center.name
        //   );
        props.history.push("/drivers/dashboard");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error(error?.response?.data?.message || error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const validate = () => {
    if (!loginData.phone) {
      toast.error("Phone number not provided !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (loginData.phone.length < 11) {
      toast.error("Phone number must be at least 11 characters !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!loginData.password) {
      toast.error("Password not provided !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    handleLogin();
  };

  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <div>
      <div className="row noMarginPadding">
        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-8 col-xs-12 PaddingLeft-10">
          <div className="container pl-4 pt-4 PaddingLeft-10">
            <div style={{ width: "150px" }}>
              <img src={newLogo} alt="logo" style={{ width: "100%" }} />
            </div>
            <div className="w-75 ml-5 mt-5 containerWidth">
              <h5 className="headerText headerh5 newH5">Switch Driver</h5>
              <p className="loginStyle mb-0">Login</p>
              <div className="form-group">
                <label className="label-control mb-0 labelStyle">
                  Phone Number
                </label>
                <input
                  type="email"
                  className="form-control inputStyle"
                  placeholder="Enter phone number"
                  // no-border
                  value={loginData.phone}
                  onChange={handleLoginForm("phone")}
                />
              </div>
              <div>
                <label className="label-control mb-0 labelStyle">
                  Enter Password
                </label>
                <Form>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    value={loginData.password}
                    onChange={handleLoginForm("password")}
                  >
                    <Input.Password placeholder="*************" />
                  </Form.Item>
                </Form>
              </div>

              <div className="forgotPassword">
                <div></div>
                <div className="fpw">
                  <a
                    href="/drivers/forgotPassword"
                    style={{ color: "#231F20", fontSize: "13px" }}
                  >
                    Forgot Password{" "}
                    <span style={{ color: "#c4da00" }}>&#62;</span>
                  </a>
                </div>
              </div>

              <div className="btnBottomDiv">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={validate}
                  disabled={loading}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-4 col-md-4 rightImg"></div>
      </div>
    </div>
  );
};

export default DriverLogin;
