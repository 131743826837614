import moment from "moment";
import React from "react";

const Resultss = ({ req, index, data }) => {
  // console.log("data", data);
  // console.log("req", req);
  return (
    <div key={index}>
      <h6 className="py-2">{req}</h6>
      {data.map((notification, index) => {
        return (
          <div key={index}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center mb-1">
                <div
                  style={{
                    height: "10px",
                    width: "10px",
                    borderRadius: "50%",
                    backgroundColor: "#C4DA00",
                  }}
                ></div>
                <p className="mb-0 ml-3">{notification.data.message}</p>
              </div>
              <div>
                <p className="mb-0">
                  {moment(notification.created_at).format("hh:mm A")}
                </p>
              </div>
            </div>
            <hr style={{ background: "#F8FA82" }} />
          </div>
        );
      })}
    </div>
  );
};

export default Resultss;
