import { Form, Input } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { switchEndpoint } from "../../../../../../../../redux/actions/endpoints";
import { MdArrowBack } from "react-icons/md";

const AuthenticateModal = ({
  handleCloseAuthenticate,
  showAuthenticate,
  handleShowSuccess,
  getWalletBalance,
  handleShowFund,
}) => {
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);
  const driversDetails = JSON.parse(localStorage.getItem("levels"));
  const amt = localStorage.getItem("amount");
  const payment = localStorage.getItem("paymentMethod");

  const fundDriver = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let payload = {
      password: pass,
      driver_id: driversDetails.id,
      amount: amt,
      type: payment,
    };
    axios
      .post(`${switchEndpoint}/collection-center/fund-driver`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        handleCloseAuthenticate();
        handleShowSuccess();
        getWalletBalance();
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };
  return (
    <div>
      <Modal
        show={showAuthenticate}
        onHide={handleCloseAuthenticate}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <MdArrowBack
            size={24}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleCloseAuthenticate();
              handleShowFund();
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <div className="text-center mt-3 mb-5">
                <h5 className="sec-headers mb-3">Authenticate Transaction</h5>

                <div className="text-left w-50 m-auto">
                  <label className="label-control mb-0 labelStyle">
                    Enter Password
                  </label>
                  <Form>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                      value={pass}
                      onChange={(e) => setPass(e.target.value)}
                    >
                      <Input.Password placeholder="*************" />
                    </Form.Item>
                  </Form>
                </div>

                <div className="mt-1 mb-5 w-btn m-auto pt-3">
                  <button
                    type="button"
                    className="btn loginBtn btn-sm btn-block d-flex justify-content-center align-items-center"
                    onClick={() => {
                      if (!pass) {
                        toast.error("Enter password", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          pauseOnHover: true,
                          progress: undefined,
                          theme: "light",
                        });
                      } else {
                        fundDriver();
                      }
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <span>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </span>
                    ) : (
                      "Authenticate Transaction"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AuthenticateModal;
