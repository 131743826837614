// import axios from "axios";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import DriversNavigation from "../../components/Navbar/navbar";
import PaymentConfirmedModal from "../modals/paymentC";
import PaymentRejectedModal from "../modals/paymentR";

const AwaitingConfirmation = () => {
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState(1);

  const [showConfirmed, setShowConfirmed] = useState(false);
  const handleCloseConfirmed = () => setShowConfirmed(false);
  const handleShowConfirmed = () => setShowConfirmed(true);

  const [showRejected, setShowRejected] = useState(false);
  const handleCloseRejected = () => setShowRejected(false);
  const handleShowRejected = () => setShowRejected(true);

  const pickUpDetails = JSON.parse(localStorage.getItem("pickUp"));
  const pickUpDetailss = JSON.parse(localStorage.getItem("pickUps"));
  const paymentMethods = localStorage.getItem("paymentMethods");
  const methods = localStorage.getItem("methods");
  const initiateDetails = JSON.parse(localStorage.getItem("initiate"));

  const getSinglePickUp = (code) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/driver/single-pickup/${code}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data;
        localStorage.setItem("reference", JSON.stringify(response.data));
        if (response.data.status === "accepted") {
          handleShowConfirmed();
          setSteps(0);
        } else if (response.data.status === "rejected") {
          handleShowRejected();
          setSteps(0);
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (steps === 1) {
      const interval = setInterval(() => {
        getSinglePickUp(initiateDetails.code);
      }, 1000 * 5);
      return () => clearInterval(interval);
    }
  }, [steps === 1]);

  // console.log(pickUpDetails);

  return (
    <div>
      <DriversNavigation />
      <div className="mx-3">
        <div className="text-left">
          <div className="mb-2 mt-4">
            <p
              className="sec-headers mb-3"
              style={{ fontSize: "16px", fontWeight: "500" }}
            >
              Waiting for confirmation
            </p>

            {(pickUpDetails?.pickup_details?.clean_sorted?.KG > 0 ||
              pickUpDetailss?.clean_sorted?.KG > 0) && (
              <div>
                <div className="cardss mt-3">
                  <div className="reqBg d-flex pl-4">
                    <p className="mb-0 mr-3">Clean Sorted</p>
                    <div className="d-flex">
                      <p className="fontBold">{`${
                        !pickUpDetailss
                          ? pickUpDetails?.pickup_details?.clean_sorted?.KG
                          : pickUpDetailss?.clean_sorted?.KG
                      }kg`}</p>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <p className="fontBold">{`₦${
                        !pickUpDetailss
                          ? (pickUpDetails?.pickup_details?.clean_sorted?.amount).toFixed(
                              2
                            )
                          : (pickUpDetailss?.clean_sorted?.amount).toFixed(2)
                      }`}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(pickUpDetails?.pickup_details?.clean_unsorted?.KG > 0 ||
              pickUpDetailss?.clean_unsorted?.KG > 0) && (
              <div>
                <div className="cardss my-3">
                  <div className="reqBg d-flex pl-4">
                    <p className="mb-0 mr-3">Clean Unsorted</p>
                    <div className="d-flex">
                      <p className="fontBold">{`${
                        !pickUpDetailss
                          ? pickUpDetails?.pickup_details?.clean_unsorted?.KG
                          : pickUpDetailss?.clean_unsorted?.KG
                      }kg`}</p>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <p className="fontBold">{`₦${
                        !pickUpDetailss
                          ? (pickUpDetails?.pickup_details?.clean_unsorted?.amount).toFixed(
                              2
                            )
                          : (pickUpDetailss?.clean_unsorted?.amount).toFixed(2)
                      }`}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(pickUpDetails?.pickup_details?.mixed_plastics?.KG > 0 ||
              pickUpDetailss?.mixed_plastics?.KG > 0) && (
              <div>
                <div className="cardss my-3">
                  <div className="reqBg d-flex pl-4">
                    <p className="mb-0 mr-3">Mixed Plasics</p>
                    <div className="d-flex">
                      <p className="fontBold">{`${
                        !pickUpDetailss
                          ? pickUpDetails?.pickup_details?.mixed_plastics?.KG
                          : pickUpDetailss?.mixed_plastics?.KG
                      }kg`}</p>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <p className="fontBold">{`₦${
                        !pickUpDetailss
                          ? (pickUpDetails?.pickup_details?.mixed_plastics?.amount).toFixed(
                              2
                            )
                          : (pickUpDetailss?.mixed_plastics?.amount).toFixed(2)
                      }`}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(pickUpDetails?.pickup_details?.pure_water_sachet_dry?.KG > 0 ||
              pickUpDetailss?.pure_water_sachet_dry?.KG > 0) && (
              <div>
                <div className="cardss my-3">
                  <div className="reqBg d-flex pl-4">
                    <p className="mb-0 mr-3">Pure Water Nylon</p>
                    <div className="d-flex">
                      <p className="fontBold">{`${
                        !pickUpDetailss
                          ? pickUpDetails?.pickup_details?.pure_water_sachet_dry
                              ?.KG
                          : pickUpDetailss?.pure_water_sachet_dry?.KG
                      }kg`}</p>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <p className="fontBold">{`₦${
                        !pickUpDetailss
                          ? (pickUpDetails?.pickup_details?.pure_water_sachet_dry?.amount).toFixed(
                              2
                            )
                          : (pickUpDetailss?.pure_water_sachet_dry?.amount).toFixed(
                              2
                            )
                      }`}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(pickUpDetails?.pickup_details?.single_colour_caps?.KG > 0 ||
              pickUpDetailss?.single_colour_caps?.KG > 0) && (
              <div>
                <div className="cardss my-3">
                  <div className="reqBg d-flex pl-4">
                    <p className="mb-0 mr-3">Single color caps</p>
                    <div className="d-flex">
                      <p className="fontBold">{`${
                        !pickUpDetailss
                          ? pickUpDetails?.pickup_details?.single_colour_caps
                              ?.KG
                          : pickUpDetailss?.single_colour_caps?.KG
                      }kg`}</p>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <p className="fontBold">{`₦${
                        !pickUpDetailss
                          ? (pickUpDetails?.pickup_details?.single_colour_caps?.amount).toFixed(
                              2
                            )
                          : (pickUpDetailss?.single_colour_caps?.amount).toFixed(
                              2
                            )
                      }`}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(pickUpDetails?.pickup_details?.hard_packing_bags_dry?.KG > 0 ||
              pickUpDetailss?.hard_packing_bags_dry?.KG > 0) && (
              <div>
                <div className="cardss my-3">
                  <div className="reqBg d-flex pl-4">
                    <p className="mb-0 mr-3">Hard packing bags (dry)</p>
                    <div className="d-flex">
                      <p className="fontBold">{`${
                        !pickUpDetailss
                          ? pickUpDetails?.pickup_details?.hard_packing_bags_dry
                              ?.KG
                          : pickUpDetailss?.hard_packing_bags_dry?.KG
                      }kg`}</p>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <p className="fontBold">{`₦${
                        !pickUpDetailss
                          ? (pickUpDetails?.pickup_details?.hard_packing_bags_dry?.amount).toFixed(
                              2
                            )
                          : (pickUpDetailss?.hard_packing_bags_dry?.amount).toFixed(
                              2
                            )
                      }`}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(pickUpDetails?.pickup_details?.hdpe_plastics_below_five_meters
              ?.KG > 0 ||
              pickUpDetailss?.hdpe_plastics_below_five_meters?.KG > 0) && (
              <div>
                <div className="cardss my-3">
                  <div className="reqBg d-flex pl-4">
                    <p className="mb-0 mr-3">HDPE plastics below 5 Litres</p>
                    <div className="d-flex">
                      <p className="fontBold">{`${
                        !pickUpDetailss
                          ? pickUpDetails?.pickup_details
                              ?.hdpe_plastics_below_five_meters?.KG
                          : pickUpDetailss?.hdpe_plastics_below_five_meters?.KG
                      }kg`}</p>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <p className="fontBold">{`₦${
                        !pickUpDetailss
                          ? (pickUpDetails?.pickup_details?.hdpe_plastics_below_five_meters?.amount).toFixed(
                              2
                            )
                          : (pickUpDetailss?.hdpe_plastics_below_five_meters?.amount).toFixed(
                              2
                            )
                      }`}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(pickUpDetails?.pickup_details?.pp_plastics?.KG > 0 ||
              pickUpDetailss?.pp_plastics?.KG > 0) && (
              <div>
                <div className="cardss my-3">
                  <div className="reqBg d-flex pl-4">
                    <p className="mb-0 mr-3">PP Plastics</p>
                    <div className="d-flex">
                      <p className="fontBold">{`${
                        !pickUpDetailss
                          ? pickUpDetails?.pickup_details?.pp_plastics?.KG
                          : pickUpDetailss?.pp_plastics?.KG
                      }kg`}</p>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <p className="fontBold">{`₦${
                        !pickUpDetailss
                          ? (pickUpDetails?.pickup_details?.pp_plastics?.amount).toFixed(
                              2
                            )
                          : (pickUpDetailss?.pp_plastics?.amount).toFixed(2)
                      }`}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(pickUpDetails?.pickup_details?.packing_bags?.KG > 0 ||
              pickUpDetailss?.packing_bags?.KG > 0) && (
              <div>
                <div className="cardss my-3">
                  <div className="reqBg d-flex pl-4">
                    <p className="mb-0 mr-3">Pure Water Packaging Bags</p>
                    <div className="d-flex">
                      <p className="fontBold">{`${
                        !pickUpDetailss
                          ? pickUpDetails?.pickup_details?.packing_bags?.KG
                          : pickUpDetailss?.packing_bags?.KG
                      }kg`}</p>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <p className="fontBold">{`₦${
                        !pickUpDetailss
                          ? (pickUpDetails?.pickup_details?.packing_bags?.amount).toFixed(
                              2
                            )
                          : (pickUpDetailss?.packing_bags?.amount).toFixed(2)
                      }`}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(pickUpDetails?.pickup_details?.cans?.KG > 0 ||
              pickUpDetailss?.cans?.KG > 0) && (
              <div>
                <div className="cardss my-3">
                  <div className="reqBg d-flex pl-4">
                    <p className="mb-0 mr-3">Cans</p>
                    <div className="d-flex">
                      <p className="fontBold">{`${
                        !pickUpDetailss
                          ? pickUpDetails?.pickup_details?.cans?.KG
                          : pickUpDetailss?.cans?.KG
                      }kg`}</p>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <p className="fontBold">{`₦${
                        !pickUpDetailss
                          ? (pickUpDetails?.pickup_details?.cans?.amount).toFixed(
                              2
                            )
                          : (pickUpDetailss?.cans?.amount).toFixed(2)
                      }`}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="mb-3">
              <label className="mb-1 form-label">Deductions</label>
              <input
                type="text"
                className="form-control form-control-lg inputStyle bg-dark text-white"
                // placeholder="-N0.00"
                value={`₦${
                  !pickUpDetailss
                    ? Math.round(
                        (pickUpDetails?.pickup_details?.total_deduction +
                          Number.EPSILON) *
                          100
                      ) / 100
                    : Math.round(
                        (pickUpDetailss?.total_deduction + Number.EPSILON) * 100
                      ) / 100
                }`}
                disabled
              />
            </div>

            <div className="mb-3">
              <label className="mb-1 form-label">
                Penalty for delivering less than 20kg
              </label>
              <input
                type="text"
                className="form-control form-control-lg inputStyle bg-dark text-white"
                // placeholder="-N0.00"
                value={`₦${
                  !pickUpDetailss
                    ? Math.round(
                        (pickUpDetails?.pickup_details
                          ?.deduction_total_for_20kg +
                          Number.EPSILON) *
                          100
                      ) / 100
                    : Math.round(
                        (pickUpDetailss?.deduction_total_for_20kg +
                          Number.EPSILON) *
                          100
                      ) / 100
                }`}
                disabled
              />
            </div>

            <div>
              <div className="totalBg d-flex pl-4">
                <p className="mb-0 mr-3">Sum Total</p>
                <div className="d-flex">
                  <p className="fontBold mb-0">{`${
                    !pickUpDetailss
                      ? pickUpDetails?.pickup_details?.total_kg
                      : pickUpDetailss?.total_kg
                  }kg`}</p>
                  <span className="mx-3" style={{ color: "#c4da00" }}>
                    &#8644;
                  </span>
                  <p className="fontBold mb-0">{`₦${
                    !pickUpDetailss
                      ? (pickUpDetails?.pickup_details?.total_price).toFixed(2)
                      : (pickUpDetailss?.total_price).toFixed(2)
                  }`}</p>
                </div>
              </div>
            </div>

            <div
              className="my-4"
              // onClick={handleShowConfirmed}
            >
              <p>
                Payment Method: {!pickUpDetailss ? paymentMethods : methods}
              </p>
              {/* <p>Payment Method: {detail}</p> */}
            </div>
          </div>
        </div>
      </div>

      <PaymentConfirmedModal
        showConfirmed={showConfirmed}
        handleCloseConfirmed={handleCloseConfirmed}
        handleShowRejected={handleShowRejected}
      />

      <PaymentRejectedModal
        showRejected={showRejected}
        handleCloseRejected={handleCloseRejected}
      />
    </div>
  );
};

export default AwaitingConfirmation;
