import React from "react";
import DriversNavigation from "../components/Navbar/navbar";

const DriversMessages = () => {
  return (
    <div>
      <DriversNavigation />
      <div className="mx-3">
        <div className="text-center d-flex justify-content-center align-items-center py-5">
          <h3>Coming soon ...</h3>
        </div>
      </div>
    </div>
  );
};

export default DriversMessages;
