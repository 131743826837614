import React, { Fragment } from "react";

const Clock = ({ timerMinutes, timerSeconds }) => {
  return (
    <Fragment>
      <section className="timer-container">
        <section className="timer">
          <div className="clock">
            <h2 style={{ fontWeight: "bolder" }}>
              {timerMinutes}
              <span style={{ fontWeight: "bold", fontSize: "20px" }}>mins</span>
              :{timerSeconds}
              <span style={{ fontWeight: "bold", fontSize: "20px" }}>secs</span>
            </h2>
          </div>
        </section>
      </section>
    </Fragment>
  );
};

Clock.defaultProps = {
  timerMinutes: 9,
  timerSeconds: 30,
};

export default Clock;
