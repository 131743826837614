import React from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";

const PackagingBagModal = ({
  showPackagingBag,
  handleClosePackagingBag,
  form,
  handleFormChange,
  packagingBagValue,
  packagingBagDeduction,
  packagingBagKg,
  handleInputChangePB,
}) => {
  const history = useHistory();
  return (
    <div>
      <Modal
        show={showPackagingBag}
        onHide={() => {
          handleClosePackagingBag();
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left">
            <h5>Price Deductions</h5>

            <p className="my-4">
              Reduce the price of the Used Plastics due to impurities noticed.
              Enter a value deduction in percentage
            </p>

            <div className="mb-3 mt-2">
              <label className="mb-1 form-label">
                Pure Water Packaging Bags
              </label>
              <input
                type="text"
                className="form-control form-control-lg inputStyle"
                placeholder="% Deduction"
                value={packagingBagDeduction}
                onChange={handleInputChangePB}
                disabled={packagingBagKg === "" || packagingBagKg === 0}
                maxLength={1}
              />
            </div>

            <div className="pt-4 pb-5">
              <button
                type="button"
                className="btn loginBtn btn-sm btn-block"
                onClick={handleClosePackagingBag}
              >
                Make Deduction
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PackagingBagModal;
