import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { walletAction } from "../../../../../redux/actions";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import FloatRequest from "./components/float";
import FundCenter from "./components/fundCollection";
import FundDriver from "./components/fundDriver";
import Transaction from "./components/transactions";

const WalletAggregation = ({ setTitleName }) => {
  const dispatch = useDispatch();
  const details = JSON.parse(localStorage.getItem("centerInfo"));
  // console.log(details);
  const { display } = useSelector(({ wallet }) => wallet);
  const [selectedDuration1, setSelectedDuration1] = useState("");
  const [selectedDuration2, setSelectedDuration2] = useState("");
  const [transactionsList, setTransactionsList] = useState([]);
  const [centersList, setCentersList] = useState([]);
  const [driversList, setDriversList] = useState([]);
  const [balance, setBalance] = useState({});
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("all");
  const [float, setFloat] = useState([]);

  const switchToTransaction = () => {
    dispatch(walletAction.setDisplay("transactions"));
  };
  const switchToFundCenter = () => {
    dispatch(walletAction.setDisplay("fundCenter"));
  };
  const switchToFundDriver = () => {
    dispatch(walletAction.setDisplay("fundDriver"));
  };
  const switchToFloat = () => {
    dispatch(walletAction.setDisplay("float"));
  };

  const getTransaction = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/transactions`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        let result = response.data.data;
        let credit = result.filter((x) => x.transaction_type == "Credit");
        let debit = result.filter((x) => x.transaction_type == "Debit");
        if (selected === "all") {
          setTransactionsList(result);
        } else if (selected === "credit") {
          setTransactionsList(credit);
        } else if (selected === "debit") {
          setTransactionsList(debit);
        }
        // setTransactionsList(response.data.data);
        // console.log(response.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const getFloatRequests = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/float-requests`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        let result = response.data;
        console.log(result);
        setFloat(result);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const getCenter = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/aggregation-center/collection-center-dropoffs/centers-list`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setCentersList(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const getWalletBalance = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/fetch-balance`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data);
        setBalance(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  const getDriver = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/drivers`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDriversList(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    getTransaction();
    getFloatRequests();
  }, [selected]);

  useEffect(() => {
    setTitleName("Wallet");
    getWalletBalance();
    getCenter();
    getDriver();
  }, []);
  return (
    <main>
      <div className="main__container">
        {/* Wallet */}
        <div className="separatingDivs">
          <div>
            <div className="main__cardss">
              <div className="cardsss cards_extra">
                <div className="card_inners text-left">
                  <p className="card_inners_p text-left">Wallet Balance</p>
                  {loading ? (
                    <span style={{ color: "#c4da00", fontSize: "20px" }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </span>
                  ) : (
                    <h2
                      className="card_inners_amount"
                      style={{ color: "#c4da00", fontSize: "30px" }}
                    >
                      {`₦${balance.chams_wallet?.account_balance}`}
                    </h2>
                  )}
                </div>
              </div>
              <div className="cardsss">
                <div className="card_inners text-left">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="card_inners_p text-left">Total Credit</p>
                    </div>
                    <div>
                      <select
                        name=""
                        id=""
                        className="bg-white py-1 px-2 border-0 rounded"
                        style={{
                          cursor: "pointer",
                        }}
                        multiple={false}
                        onChange={(e) => {
                          setSelectedDuration1(e.target.value);
                        }}
                        value={selectedDuration1}
                      >
                        <option value="">This Month</option>
                        <option value="">This Year</option>
                      </select>
                    </div>
                  </div>
                  {loading ? (
                    <span style={{ color: "#c4da00", fontSize: "20px" }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </span>
                  ) : (
                    <h2
                      className="card_inners_amount"
                      style={{ color: "#c4da00", fontSize: "30px" }}
                    >
                      {`₦${balance?.total_credit}`}
                    </h2>
                  )}
                </div>
              </div>
              <div className="cardsss">
                <div className="card_inners text-left">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="card_inners_p text-left">Total Debit</p>
                    </div>
                    <div>
                      <select
                        name=""
                        id=""
                        className="bg-white py-1 px-2 border-0 rounded"
                        style={{
                          cursor: "pointer",
                        }}
                        multiple={false}
                        onChange={(e) => {
                          setSelectedDuration2(e.target.value);
                        }}
                        value={selectedDuration2}
                      >
                        <option value="">This Month</option>
                        <option value="">This Year</option>
                      </select>
                    </div>
                  </div>
                  {loading ? (
                    <span style={{ color: "#EB5757", fontSize: "20px" }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </span>
                  ) : (
                    <h2
                      className="card_inners_amount"
                      style={{ color: "#EB5757", fontSize: "30px" }}
                    >
                      {`₦${balance?.total_debit}`}
                    </h2>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {display === "transactions" && (
          <Transaction
            switchToFundCenter={switchToFundCenter}
            switchToFundDriver={switchToFundDriver}
            transactionsList={transactionsList}
            getTransaction={getTransaction}
            setSelected={setSelected}
            switchToFloat={switchToFloat}
          />
        )}
        {display === "fundDriver" && (
          <FundDriver
            switchToTransaction={switchToTransaction}
            switchToFundCenter={switchToFundCenter}
            driversList={driversList}
            getWalletBalance={getWalletBalance}
            switchToFloat={switchToFloat}
          />
        )}
        {display === "fundCenter" && (
          <FundCenter
            switchToTransaction={switchToTransaction}
            switchToFundDriver={switchToFundDriver}
            centersList={centersList}
            getTransaction={getTransaction}
            getWalletBalance={getWalletBalance}
            switchToFloat={switchToFloat}
          />
        )}
        {display === "float" && (
          <FloatRequest
            switchToTransaction={switchToTransaction}
            switchToFundDriver={switchToFundDriver}
            getWalletBalance={getWalletBalance}
            float={float}
            getFloatRequests={getFloatRequests}
          />
        )}
      </div>
    </main>
  );
};

export default WalletAggregation;
