import { Table } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "react-toastify";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import AcceptModal from "./modal/accept";

const ScheduledDropOff = ({ setTitleName }) => {
  const [collData, setCollData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState("");

  const [showAccept, setShowAccept] = useState(false);
  const handleCloseAccept = () => setShowAccept(false);
  const handleShowAccept = () => setShowAccept(true);

  const columns = [
    {
      title: "ID",
      dataIndex: "key",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
    },
    {
      title: "Name",
      dataIndex: "",
      key: "name",
      render: (collection_center) => {
        return <p>{`${collection_center?.aggregation_center?.name}`}</p>;
      },
    },
    {
      title: "Weight",
      dataIndex: "weight_in_kg",
      key: "weight",
      render: (weight_in_kg) => {
        return <p>{`${weight_in_kg}kg`}</p>;
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Date",
      dataIndex: "date_type",
      key: "date",
    },
    {
      title: "Action",
      //   dataIndex: "status",
      key: "action",
      render: (code) => {
        return (
          <p
            className="mb-0"
            style={{
              backgroundColor: "#c4da00",
              boxShadow: "none !important",
              width: "max-content",
              padding: "5px 10px",
              textAlign: "center",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleShowAccept();
              setCodes(code.code);
            }}
          >
            Cancel Drop Off
          </p>
        );
      },
    },

    {
      title: " ",
      key: "action",
      render: () => <BsThreeDotsVertical style={{ cursor: "pointer" }} />,
    },
  ];

  const getCollection = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/collection-center/collection-dropoffs/all`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data);
        setCollData(response.data);
      })
      .catch((error) => {
        toast.error(error.response, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const cancelSchedule = (dropCode) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/collection-center/collection-dropoffs/cancel-dropoff/${dropCode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log(response.data);
        getCollection();
        handleCloseAccept();
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    setTitleName("Collection Center");
    getCollection();
  }, []);
  return (
    <main>
      <div className="main__container">
        <div className="cardss p-0 py-1">
          <span className="d-flex align-items-center py-3 px-2">
            <b>Scheduled Drop Off </b>({collData.length})
          </span>
          <Table columns={columns} dataSource={collData} size="middle" />
        </div>
      </div>
      <AcceptModal
        showAccept={showAccept}
        handleCloseAccept={handleCloseAccept}
        cancelSchedule={cancelSchedule}
        codes={codes}
        loading={loading}
      />
    </main>
  );
};

export default ScheduledDropOff;
