import React, { useState } from "react";
import "./navbar.css";
import navLogo from "../../../../../assets/images/favv.png";
import message from "../../../../../assets/images/messages.png";
import notification from "../../../../../assets/images/notification.png";
import logout from "../../../../../assets/images/logout.png";
import { useHistory, useLocation } from "react-router-dom";
import LogoutModal from "./modal/logout";
import { HiMenuAlt2 } from "react-icons/hi";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import axios from "axios";
import { toast } from "react-toastify";

const Navbar = ({ openSidebar, titleName }) => {
  const center = localStorage.getItem("center");
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [showLogout, setShowLogout] = useState(false);
  const handleCloseLogout = () => setShowLogout(false);
  const handleShowLogout = () => setShowLogout(true);

  const logoutMethod = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/logout`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data);
        localStorage.removeItem("token");
        localStorage.clear();

        history.push("/aggregation");
        setLoading(false);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  return (
    <nav className="navbar">
      <div className="nav_icon" onClick={() => openSidebar()}>
        <div
          style={{
            backgroundColor: "#c4da00",
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
          }}
        >
          <HiMenuAlt2 style={{ color: "#fff" }} />
        </div>
      </div>
      <p>{titleName}</p>
      <div className="form-group inps">
        <input
          type="email"
          className="form-control inputStyle"
          placeholder="Search Household/Agent"
        />
      </div>
      <div className="menuI">
        <ul>
          <li>
            <div className="d-flex justify-content-center align-items-center">
              <div style={{ width: "15px" }}>
                <img
                  src={navLogo}
                  alt="nav logo"
                  style={{ width: "100%", height: "23px" }}
                />
              </div>
              <p className="mb-0 ml-2">{center}</p>
            </div>
          </li>
          <li
            className={`py-1 ${location.pathname === "/dashboard/message"
                ? "active-menu_link"
                : "sidebar__link"
              }`}
            style={{ borderRadius: "5px" }}
          >
            <a href="/dashboard/message">
              <img src={message} alt="nav logo" /> &nbsp; Messages
            </a>
          </li>
          <li
            className={`py-1 ${location.pathname === "/dashboard/notification"
                ? "active-menu_link"
                : "sidebar__link"
              }`}
            style={{ borderRadius: "5px" }}
          >
            <a href="/dashboard/notification">
              <img src={notification} alt="nav logo" /> &nbsp; Notifications
            </a>
          </li>
          <li
            onClick={() => {
              handleShowLogout();
              // localStorage.removeItem("token");
              // history.push("/aggregation");
            }}
          >
            <img src={logout} alt="nav logo" /> &nbsp; Log Out
          </li>
        </ul>
      </div>

      <LogoutModal
        handleCloseLogout={handleCloseLogout}
        showLogout={showLogout}
        logoutMethod={logoutMethod}
        loading={loading}
      />
    </nav>
  );
};

export default Navbar;
