import React, { useState, useEffect } from "react";
import "./index.css";
import newLogo from "../../../../assets/images/newLogo.png";
import OtpInput from "react-otp-input";
import axios from "axios";
import { toast } from "react-toastify";
import { switchEndpoint } from "../../../../redux/actions/endpoints";

const AggregationOTP = (props) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    const em = localStorage.getItem("email");
    setEmail(em);
  }, []);

  const handleReset = (e) => {
    setLoading(true);
    let payload = {
      otp: otp,
      email: email,
    };
    console.log(payload);
    axios
      .post(`${switchEndpoint}/auth/confirm-password-otp-email`, payload)
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        localStorage.setItem("code", otp);
        props.history.push("/aggregation/reset");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };
  return (
    <div>
      <div className="row noMarginPadding">
        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-8 col-xs-12 PaddingLeft-10">
          <div className="container pl-4 pt-4 PaddingLeft-10">
            <div style={{ width: "150px" }}>
              <img src={newLogo} alt="logo" style={{ width: "100%" }} />
            </div>
            <div className="w-50 ml-5 mt-5 containerWidth">
              <h5 className="headerText headerh5">Aggregation Center</h5>
              <p className="loginStyle mb-0">Enter OTP</p>
              <p className="pin">Enter the 6 digit PIN sent to your email.</p>

              <div>
                <OtpInput
                  value={otp}
                  onChange={(otp) => setOtp(otp)}
                  numInputs={6}
                  className="text-center otp-inputs"
                />
              </div>

              <div className="validateOtp">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block mt-4"
                  onClick={handleReset}
                  disabled={loading}
                >
                  Validate OTP
                </button>
              </div>

              <div className="resend">
                <a
                  href="/aggregation/forgotPassword"
                  style={{
                    color: "#231F20",
                    fontSize: "13px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  Didn’t receive the code? Resend OTP &nbsp;
                  <span style={{ color: "#c4da00" }}>&#62;</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-4 col-md-4 rightImg"></div>
      </div>
    </div>
  );
};

export default AggregationOTP;
