import React from "react";
import { Modal } from "react-bootstrap";
import Avatar from "../../../../../../../../assets/images/avatarr.png";

const FundModal = ({ handleCloseFund, showFund, handleShowAuthenticate }) => {
  return (
    <div>
      <Modal
        show={showFund}
        onHide={handleCloseFund}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <h5 className="sec-headers mb-3">Fund Collection Center</h5>

              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="imgAvatarHolder ml-0">
                    <img src={Avatar} alt="image" />
                  </div>
                  <div className="ml-2">
                    <p
                      className="mb-0"
                      style={{ fontSize: "14px", fontWeight: "500" }}
                    >
                      Collection Center: Lekki
                    </p>
                  </div>
                </div>

                <div className="mr-4">
                Wallet Balance: <span style={{ fontWeight: 'bold' }}>N1,200.23</span>
                </div>
              </div>
              <div className="d-flex">
                <div></div>
                <div className="ml-2">
                  <p className="mb-0 ml-5" style={{ fontSize: "12px" }}>
                    090 6489 2838
                  </p>
                </div>
              </div>
            </div>
            <div className="w-50">
              <div className="mb-3 mt-4">
                <label className="mb-1 form-label">Wallet Amount</label>
                <input
                  type="text"
                  className="form-control form-control-lg inputStyle w-75"
                  placeholder="0.00"
                  //   value={dropOff}
                  //   onChange={(e) => setDropOff(e.target.value)}
                />
              </div>

              <div className="mt-4 mb-5 w-50">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={() => {
                    handleCloseFund();
                    handleShowAuthenticate();
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FundModal;
