import React, { Component } from "react";
import InactivityTimer from "./inactivityTimer";

const WithInactivityTimer = (WrappedComponent) => {
  return class extends Component {
    handleLogout = () => {
      // Implement your logout logic here
    };

    render() {
      return (
        <div>
          <InactivityTimer onIdle={this.handleLogout} />
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  };
};

export default WithInactivityTimer;
