export function getPricing(data, materialName, weight, type) {
  if (data?.hasOwnProperty(materialName)) {
    for (let item of data[materialName]) {
      let minKg = parseFloat(item.min_kg);
      let maxKg = parseFloat(item.max_kg);
      weight = parseFloat(weight);
      if (weight >= minKg && weight <= maxKg && item.type === type) {
        return item.price_per_kg;
      }
    }
  }
  return null;
}
