import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Avatar from "../../../../../../../../assets/images/avatarr.png";

const FundModal = ({ handleCloseFund, showFund, handleShowAuthenticate }) => {
  const [selectedMethod, setSelectedMethod] = useState("");
  const driversDetails = JSON.parse(localStorage.getItem("driver"));
  const [amount, setAmount] = useState("");

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (/^[0-9]*$/.test(value)) {
      setAmount(value);
      localStorage.setItem("amount", value);
    }
  };

  return (
    <div>
      <Modal
        show={showFund}
        onHide={() => {
          handleCloseFund();
          setAmount("");
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <h5 className="sec-headers mb-3">Fund Driver</h5>

              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="imgAvatarHolder ml-0">
                    <img src={Avatar} alt="image" />
                  </div>
                  <div className="ml-2">
                    <p
                      className="mb-0"
                      style={{ fontSize: "14px", fontWeight: "500" }}
                    >
                      {driversDetails?.name}
                    </p>
                  </div>
                </div>

                <div>
                  <div className="mr-4">
                    Wallet Balance:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {driversDetails?.account.account_balance === null
                        ? `₦0.00`
                        : `₦${driversDetails?.account.account_balance}`}
                    </span>
                  </div>
                  <div className="mr-4">
                    Cash Balance:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {driversDetails?.cash_balance === null
                        ? `₦0.00`
                        : `₦${driversDetails?.cash_balance}`}
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div></div>
                <div className="ml-2">
                  <p className="mb-0 ml-5" style={{ fontSize: "12px" }}>
                    {driversDetails?.phone}
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="mb-3 mt-4 mr-3">
                <label className="mb-1 form-label">Amount</label>
                <input
                  type="text"
                  className="form-control form-control-lg inputStyle"
                  placeholder="0.00"
                  value={amount}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group  mr-3">
                <label for="exampleFormControlSelect1">
                  Select Payment Method
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  onChange={(e) => {
                    setSelectedMethod(e.target.value);
                    // console.log(e.target.value);
                    localStorage.setItem("paymentMethod", e.target.value);
                  }}
                  value={selectedMethod}
                >
                  <option value="">Select</option>
                  <option value="wallet">Switch Wallet</option>
                  <option value="cash">Cash</option>
                </select>
              </div>
              <div className="mt-4 mb-5 w-25">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={() => {
                    if (!amount) {
                      toast.error("Enter amount", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        progress: undefined,
                        theme: "light",
                      });
                    } else if (!selectedMethod) {
                      toast.error("Select payment method", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        progress: undefined,
                        theme: "light",
                      });
                    } else {
                      localStorage.setItem("amount", amount);
                      handleCloseFund();
                      handleShowAuthenticate();
                      setAmount("");
                    }
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FundModal;
