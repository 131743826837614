import React from "react";
import Modal from "react-bootstrap/Modal";
import SuccessImg from "../../../../../../../../assets/images/success.png";

const SuccessfulModal = ({
  handleCloseSuccess,
  showSuccess,
  getWalletBalance,
}) => {
  const driversDetails = JSON.parse(localStorage.getItem("levels"));
  const amt = localStorage.getItem("amount");
  return (
    <div>
      <Modal
        show={showSuccess}
        onHide={() => {
          handleCloseSuccess();
          getWalletBalance();
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <div className="text-center mt-3 mb-5">
                <div className="successImg mb-4">
                  <img src={SuccessImg} alt="success image" />
                </div>
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                    Transaction Successful
                  </p>
                  <p style={{ fontSize: "12px" }}>
                    You sent {`₦${amt}`} to Switch Driver:{" "}
                    {driversDetails?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SuccessfulModal;
