import React, { useState, useEffect } from "react";
import "./index.css";
import { Spinner } from "react-bootstrap";
import DriversNavigation from "../components/Navbar/navbar";
import Result from "./Results";
import avatar from "../../../../assets/images/avatarr.png";
import axios from "axios";
import { switchEndpoint } from "../../../../redux/actions/endpoints";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import InitiateModal from "./modals/initiate";
import FloatRequestModal from "./modals/floatRequest";
import FloatSuccessfulModal from "./modals/floatSuccess";
import { useSnackbar } from "notistack";
import Pusher from "pusher-js";

const CustomSnackbarContent = ({ data, history }) => {
  console.log(history);
  return (
    <div
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      // onClick={() => history.push("/dashboard/notification")}
    >
      <span style={{ marginRight: "10px" }}>🚀</span>
      <div>
        <strong>Alert !</strong>
        <p>{data}</p>
      </div>
    </div>
  );
};

const DriversDashboard = () => {
  const [pricing, setPricing] = useState({});
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [dropOff, setDropOff] = useState("");
  const [checked1, setChecked1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingU, setLoadingU] = useState(false);
  const [groupedDate, setGroupedDate] = useState({});
  const [pickUp, setPickUp] = useState([]);
  const othersObj = {};
  const [walletBal, setWalletBal] = useState([]);

  const [showFloat, setShowFloat] = useState(false);
  const handleCloseFloat = () => setShowFloat(false);
  const handleShowFloat = () => setShowFloat(true);

  const [showFloatSuccess, setShowFloatSuccess] = useState(false);
  const handleCloseFloatSuccess = () => setShowFloatSuccess(false);
  const handleShowFloatSuccess = () => setShowFloatSuccess(true);

  const getAllPricing = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/all-waste-categories`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        //  console.log("waste categories ==>", response.data);
        setPricing(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    getAllPricing();
  }, []);

  const fetchBalance = () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/driver/fetch-balance`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data;
        // console.log(result);
        setWalletBal(result);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setIsLoading(false);
      });
  };

  const getSinglePickUp = (code) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/driver/single-pickup/${code}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data);
        localStorage.setItem(
          "pickUps",
          JSON.stringify(response.data.pickup_details)
        );
        localStorage.setItem("methods", response.data.payment_method);
        const result = response.data;
        // console.log(result.status);
        localStorage.setItem("initiate", JSON.stringify(result));
        if (result.status === "pickup_started") {
          history.push({
            pathname: "/drivers/calculate",
            state: { pricingObject: pricing },
          });
          // history.push("/drivers/calculate");
        } else if (result.status === "initiated") {
          history.push("/drivers/awaitConfirmation");
        } else if (result.status === "accepted") {
          localStorage.setItem("pickUpDetails", JSON.stringify(response.data));
          localStorage.setItem("reference", JSON.stringify(response.data));
          history.push("/drivers/review");
        } else {
          history.push("/drivers/initiate");
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  const getAllPickUps = () => {
    setChecked1(true);
    setLoadingU(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/driver/all-pickups`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data.data);
        setPickUp(response.data.data);
        const result = response.data.data;
        result.forEach((element) => {
          const date_type = element.date_type;
          if (date_type in othersObj) {
            othersObj[date_type] = [...othersObj[date_type], element];
          } else {
            othersObj[date_type] = [element];
          }
        });
        setGroupedDate(othersObj);
        setLoadingU(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoadingU(false);
      });
  };

  const getAgentPickUps = () => {
    setChecked1(false);
    setLoadingU(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/driver/agent-pickups`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data.data);
        setPickUp(response.data.data);
        const result = response.data.data;
        result.forEach((element) => {
          const date_type = element.date_type;
          if (date_type in othersObj) {
            othersObj[date_type] = [...othersObj[date_type], element];
          } else {
            othersObj[date_type] = [element];
          }
        });
        setGroupedDate(othersObj);
        setLoadingU(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoadingU(false);
      });
  };

  const getHouseholdPickUps = () => {
    setChecked1(false);
    setLoadingU(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/driver/household-pickups`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data.data);
        setPickUp(response.data.data);
        const result = response.data.data;
        result.forEach((element) => {
          const date_type = element.date_type;
          if (date_type in othersObj) {
            othersObj[date_type] = [...othersObj[date_type], element];
          } else {
            othersObj[date_type] = [element];
          }
        });
        setGroupedDate(othersObj);
        setLoadingU(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoadingU(false);
      });
  };
  const driverInfo = JSON.parse(localStorage.getItem("user"));
  // console.log(driverInfo?.customer?.user_id);

  // Sound notification function
  const playSound = () => {
    const audio = new Audio("/notification.mp3"); // Path to your sound file
    audio.play().catch((e) => console.error("Error playing sound:", e));
  };

  useEffect(() => {
    var pusher = new Pusher("4a2216be62f989b542473016ba7fc057", {
      cluster: "mt1",
      wsHost: "websocket.monicredit.com",
      wsPort: 443,
    });

    var channel = pusher.subscribe(`driver-${driverInfo?.customer?.user_id}`);

    const handleSendNotification = (data) => {
      const message = data?.message || "Hello, You have new notification !";
      playSound();
      enqueueSnackbar(
        <CustomSnackbarContent data={message} history={history} />,
        {
          // variant: "info",
          style: { backgroundColor: "#231F20", color: "white" },
          autoHideDuration: 10000, // 10000 milliseconds = 10 seconds
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
      // console.log(data);
    };

    channel.bind("driver-notification", handleSendNotification);

    // Cleanup
    return () => {
      channel.unbind("driver-notification", handleSendNotification);
      channel.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setChecked1(true);
    getAllPricing();
    fetchBalance();
    getAllPickUps();
  }, []);
  return (
    <div className="mx-2">
      <div>
        <DriversNavigation />
        <div>
          <div className="cardsss my-4">
            <div className="card_inners">
              <h5
                className="card_inners_amount mb-2"
                style={{
                  fontSize: "16px",
                  paddingTop: "0",
                  marginTop: "0",
                }}
              >
                Quick Pick Up
              </h5>
              <div
                style={{
                  border: "1px solid #e3e3e3",
                  borderRadius: "10px",
                  padding: "5px",
                }}
              >
                <div className="d-flex align-items-center justify-content-between mt-0">
                  <input
                    type="email"
                    className="form-control inputStyle"
                    placeholder="Enter pick up code"
                    style={{ border: "0" }}
                    value={dropOff}
                    onChange={(e) => setDropOff(e.target.value)}
                  />
                  <div
                    className="buttonLike ml-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      getSinglePickUp(dropOff);
                    }}
                  >
                    {loading ? (
                      <span
                        style={{
                          color: "white",
                          marginLeft: "2px",
                          marginTop: "1px",
                        }}
                        className="text-center"
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          margin: "auto",
                        }}
                      >
                        &#62;
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="cardz">
              <p className="mb-0">Wallet Balance</p>
              {isLoading ? (
                <span
                  style={{
                    color: "000",
                    marginLeft: "2px",
                    marginTop: "1px",
                  }}
                  className="text-center py-2"
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </span>
              ) : (
                <h4 className="mb-0" style={{ fontWeight: "400" }}>
                  {`₦${walletBal[0]?.wallet_balance}`}
                </h4>
              )}

              <p className="mt-3 mb-0">Cash Balance</p>
              {isLoading ? (
                <span
                  style={{
                    color: "000",
                    marginLeft: "2px",
                    marginTop: "1px",
                  }}
                  className="text-center py-2"
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </span>
              ) : (
                <h4 className="mb-0" style={{ fontWeight: "400" }}>
                  {`₦${walletBal[0]?.cash_balance}`}
                </h4>
              )}

              <div className="pt-3 w-25 adjW2">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={handleShowFloat}
                  style={{ fontWeight: "400" }}
                >
                  Request For Float
                </button>
              </div>
            </div>
          </div>

          <div>
            <div className="cardsss my-4">
              <div className="spaceTop">
                <h5
                  className="card_inners_amount mb-2"
                  style={{
                    fontSize: "16px",
                    paddingTop: "0",
                    marginTop: "0",
                  }}
                >
                  Assigned Pick Ups ({pickUp.length})
                </h5>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                    checked={checked1 ? true : false}
                    onClick={getAllPickUps}
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    All
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="option2"
                    onClick={getHouseholdPickUps}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Household
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    value="option3"
                    onClick={getAgentPickUps}
                  />
                  <label class="form-check-label" for="inlineRadio3">
                    Agent
                  </label>
                </div>
              </div>
              <div>
                {pickUp.length === 0 ? (
                  <p className="my-3">No pick up available</p>
                ) : (
                  <div className="mt-3">
                    {loadingU ? (
                      <div className="card_inners_amount mt-5 text-center">
                        <span>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                    ) : (
                      <div>
                        {!Object.keys(groupedDate).length ? (
                          ""
                        ) : (
                          <div>
                            {Object.keys(groupedDate).length &&
                              Object.keys(groupedDate).map((req, index) => {
                                return (
                                  <Result
                                    req={req}
                                    data={groupedDate[req]}
                                    index={index}
                                    getSinglePickUp={getSinglePickUp}
                                    avatar={avatar}
                                  />
                                );
                              })}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatRequestModal
        showFloat={showFloat}
        handleCloseFloat={handleCloseFloat}
        handleShowFloatSuccess={handleShowFloatSuccess}
      />

      <FloatSuccessfulModal
        showFloatSuccess={showFloatSuccess}
        handleCloseFloatSuccess={handleCloseFloatSuccess}
      />
    </div>
  );
};

export default DriversDashboard;
