import axios from "axios";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Avatar from "../../../../../../../../assets/images/avatarr.png";
import { switchEndpoint } from "../../../../../../../../redux/actions/endpoints";
import "./index.css";

const AwaitingConfirmationModal = ({
  showAwait,
  handleCloseAwait,
  dropOffData,
  handleShowPaymentR,
}) => {
  const [isWaiting, setIsWaiting] = useState(true);
  const methods = localStorage.getItem("paymentMethod");
  // console.log(localStorage.getItem("paymentMethod"));
  useEffect(() => {
    setTimeout(() => {
      setIsWaiting(false);
    }, "1000");
  }, []);

  if (!isWaiting) {
    handleCloseAwait();
    // handleShowPaymentR();
  }
  return (
    <div>
      <Modal
        show={showAwait}
        onHide={handleCloseAwait}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <h5 className="sec-headers mb-3">Waiting for Confirmations</h5>
              <div className="d-flex justify-content-between align-items-center">
                <p className="ftsz-regular" style={{ fontSize: "14px" }}>
                  Household: {dropOffData?.agent?.name}
                </p>
                <div className="d-flex align-items-center ">
                  <div>
                    <p
                      className="mb-0"
                      style={{ fontSize: "14px", color: "#898989" }}
                    >
                      Drop off Code
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#404040",
                        fontWeight: "700",
                      }}
                    >
                      {dropOffData?.code}
                    </p>
                  </div>
                  <div className="imgAvatarHolder ml-3">
                    <img src={Avatar} alt="image" />
                  </div>
                </div>
              </div>
              <div>
                <div className="reqBg d-flex pl-4 w-50">
                  <p className="mb-0 mr-3">Clean Sorted</p>
                  <div className="d-flex">
                    <p className="fontBold">1.292kg</p>
                    <span className="mx-3" style={{ color: "#c4da00" }}>
                      &#8644;
                    </span>
                    <p className="fontBold">₦5,234.00</p>
                  </div>
                </div>
              </div>

              <div>
                <div className="reqBg d-flex pl-4 w-50">
                  <p className="mb-0 mr-3">Clean Unsorted</p>
                  <div className="d-flex">
                    <p className="fontBold">1.292kg</p>
                    <span className="mx-3" style={{ color: "#c4da00" }}>
                      &#8644;
                    </span>
                    <p className="fontBold">₦5,234.00</p>
                  </div>
                </div>
              </div>

              <div>
                <div className="reqBg d-flex pl-4 w-50">
                  <p className="mb-0 mr-3">Mixed Plastics</p>
                  <div className="d-flex">
                    <p className="fontBold">1.292kg</p>
                    <span className="mx-3" style={{ color: "#c4da00" }}>
                      &#8644;
                    </span>
                    <p className="fontBold">₦5,234.00</p>
                  </div>
                </div>
              </div>

              <div className="mb-3 mr-3 w-50">
                <label className="mb-1 form-label">Deductions</label>
                <input
                  type="text"
                  className="form-control form-control-lg inputStyle bg-dark text-white"
                  placeholder="-N0.00"
                  disabled
                />
              </div>

              <div>
                <div className="totalBg d-flex pl-4 w-50">
                  <p className="mb-0 mr-3">Sum Total</p>
                  <div className="d-flex">
                    <p className="fontBold mb-0">5.482kg</p>
                    <span className="mx-3" style={{ color: "#c4da00" }}>
                      &#8644;
                    </span>
                    <p className="fontBold mb-0">₦22,320.00</p>
                  </div>
                </div>
              </div>

              <div className="my-4">
                <p>Payment Method: {methods}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AwaitingConfirmationModal;
