import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import Avatar from "../../../../../assets/images/avatarr.png";

const FilterModal = ({
  handleCloseFilter,
  showFilter,
  start,
  end,
  setStart,
  setEnd,
  filterNotification,
  loading,
}) => {
  return (
    <div>
      <Modal
        show={showFilter}
        onHide={handleCloseFilter}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-1">
            <div className="mb-2">
              <h5 className="sec-headers mb-3">Filter by Date</h5>
            </div>
            <div className="d-flex justify-content-center">
              <div className="d-flex">
                <div className="mb-3 mt-4 pr-1">
                  <label className="mb-1 form-label">Start Date</label>
                  <input
                    type="date"
                    className="form-control form-control-lg inputStyle"
                    // placeholder="0.00"
                    value={start}
                    onChange={(e) => setStart(e.target.value)}
                  />
                </div>

                <div className="mb-3 mt-4">
                  <label className="mb-1 form-label">End Date</label>
                  <input
                    type="date"
                    className="form-control form-control-lg inputStyle"
                    // placeholder="0.00"
                    value={end}
                    onChange={(e) => setEnd(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 mb-5 w-25 m-auto py-4">
              <button
                type="button"
                className="btn loginBtn btn-sm btn-block"
                disabled={loading}
                onClick={() => {
                  filterNotification();
                }}
              >
                {loading ? (
                  <div className="text-center">
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                ) : (
                  "Filter"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FilterModal;
