import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const EntryPort = () => {
  useEffect(() => {
    localStorage.clear();
  }, [])
  const history = useHistory();
  return (
    <div className="mdd">
      <div className="d-flex align-items-center buttons">
        <div>
          <button
            type="button"
            className="btn loginBtn btn-sm btn-block"
            onClick={() => {
              history.push("/aggregation");
            }}
          >
            Aggregation Center
          </button>
        </div>

        <div className="ml-3 noMarginPadding mtop">
          <button
            type="button"
            className="btn loginBtn btn-sm btn-block"
            onClick={() => {
              history.push("/collections");
            }}
          >
            Collection Centers
          </button>
        </div>
      </div>
    </div>
  );
};

export default EntryPort;
