import axios from "axios";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";

const FloatRequestModal = ({
  showFloat,
  handleCloseFloat,
  handleShowFloatSuccess,
}) => {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (/^[0-9]*$/.test(value)) {
      setAmount(value);
      localStorage.setItem("amount", value);
    }
  };

  const makeRequest = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      amount: amount,
    };
    axios
      .post(`${switchEndpoint}/driver/request-float`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data;
        localStorage.setItem("amt", amount);
        handleCloseFloat();
        handleShowFloatSuccess();
        setAmount("");
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };
  return (
    <div>
      <Modal
        show={showFloat}
        onHide={() => {
          handleCloseFloat();
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left">
            <h5>Request for Float</h5>
            <div className="my-4">
              <label className="mb-1 form-label">Amount</label>
              <input
                type="text"
                className="form-control form-control-lg inputStyle"
                placeholder="Enter amount"
                value={amount}
                onChange={handleInputChange}
              />
            </div>

            <div className="pt-4 pb-5">
              <button
                type="button"
                className="btn loginBtn btn-sm btn-block"
                onClick={makeRequest}
                disabled={loading}
              >
                {loading ? (
                  <span
                    style={{
                      color: "white",
                      marginLeft: "2px",
                      marginTop: "1px",
                    }}
                    className="text-center"
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </span>
                ) : (
                  "Make Request"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FloatRequestModal;
