import React from "react";
import Modal from "react-bootstrap/Modal";
import newLogo from "../../../../../../assets/images/newLogo.png";
import { Spinner } from "react-bootstrap";

const LogoutModal = ({
  showLogout,
  handleCloseLogout,
  logoutMethod,
  loading,
}) => {
  return (
    <div>
      <Modal
        show={showLogout}
        onHide={handleCloseLogout}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="mb-3">
              <img
                src={newLogo}
                alt="switch logo"
                style={{ width: "30%", textAlign: "center" }}
              />
            </div>
            <div className="mb-5">
              <h6 className="sec-header text-body mb-1 px-sm-5 mx-sm-5">
                Are you sure you want to Log out?
              </h6>
            </div>
            <div className="row px-4 mb-5">
              <div className="col-6">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={handleCloseLogout}
                >
                  Cancel
                </button>
              </div>

              <div className="col-6">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={() => {
                    logoutMethod();
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </span>
                  ) : (
                    "Log Out"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LogoutModal;
