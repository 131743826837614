import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Avatar from "../../../../../../assets/images/avatarr.png";

const EnterCodeModal = ({ showCode, handleCloseCode, handleShowComplete }) => {
  const [dropOff, setDropOff] = useState("");

  return (
    <div>
      <Modal
        show={showCode}
        onHide={handleCloseCode}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <h5 className="sec-headers mb-3">Enter Drop Off Code</h5>

              <div className="d-flex align-items-center">
                <div className="imgAvatarHolder ml-0">
                  <img src={Avatar} alt="image" />
                </div>
                <div className="ml-2">
                  <p
                    className="mb-0"
                    style={{ fontSize: "14px", fontWeight: "500" }}
                  >
                    Switch Collection Center
                  </p>
                  <p className="mb-0" style={{ fontSize: "12px" }}>
                    6/8 Ozumba Mbadiwe Street (Opp 1004 Flats), Victoria I...
                  </p>
                </div>
              </div>
            </div>
            <div className="w-50">
              <div className="mb-3 mt-4">
                <label className="mb-1 form-label">Drop Off Code</label>
                <input
                  type="text"
                  className="form-control form-control-lg inputStyle"
                  placeholder="Enter drop off code"
                  value={dropOff}
                  onChange={(e) => setDropOff(e.target.value)}
                />
              </div>

              <div className="mt-4 mb-5 w-25">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={() => {
                    handleCloseCode();
                    handleShowComplete();
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EnterCodeModal;
