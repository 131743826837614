import React, { useState, useEffect } from "react";
import DriversNavigation from "../components/Navbar/navbar";
import "./index.css";
import { MdKeyboardArrowUp } from "react-icons/md";
import { RiArrowDownSLine } from "react-icons/ri";
import { CiCalendar } from "react-icons/ci";
import moment from "moment";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { switchEndpoint } from "../../../../redux/actions/endpoints";
import Resultss from "./Result";
import FilterModal from "./modal/filter";

const DriversNotification = () => {
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => setShowFilter(false);
  const handleShowFilter = () => setShowFilter(true);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [groupedDate, setGroupedDate] = useState({});
  const othersObj = {};
  const [info, setInfo] = useState([]);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  // console.log(currentDate);

  const getNotification = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let payload = {
      start_date: "",
      end_date: "",
    };
    axios
      .post(`${switchEndpoint}/driver/notifications`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log("this is the notification endpoint >>>>>", response.data);
        const results = response.data;
        setInfo(results);
        results.forEach((element) => {
          let dateFormated = moment(element.created_at).format("DD/MM/YYYY");
          const created_at = dateFormated;
          if (created_at in othersObj) {
            othersObj[created_at] = [...othersObj[created_at], element];
          } else {
            othersObj[created_at] = [element];
          }
        });
        // console.log(othersObj)
        setGroupedDate(othersObj);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const filterNotification = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let payload = {
      start_date: start,
      end_date: end,
    };
    // console.log(payload)
    axios
      .post(`${switchEndpoint}/driver/notifications`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log("this is the notification endpoint >>>>>", response.data);
        const results = response.data;
        setInfo(results);
        results.forEach((element) => {
          let dateFormated = moment(element.created_at).format("DD/MM/YYYY");
          const created_at = dateFormated;
          if (created_at in othersObj) {
            othersObj[created_at] = [...othersObj[created_at], element];
          } else {
            othersObj[created_at] = [element];
          }
        });
        // console.log(othersObj)
        setGroupedDate(othersObj);
        setLoading(false);
        handleCloseFilter();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <div>
      <DriversNavigation />
      <div className="wrapper">
        <h4 className="title">Notifications</h4>
        <div className="px-4">
          <div className="d-flex justify-content-between align-items-center py-3">
            <div></div>

            <div>
              <p
                style={{
                  color: "#231F20",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
                onClick={handleShowFilter}
              >
                Filter By Date
                <span style={{ color: "#c4da00", marginLeft: "5px" }}>
                  &#62;
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="mt-3">
          {loading ? (
            <div className="card_inners_amount mt-4 text-center">
              <span>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </span>
            </div>
          ) : (
            <div>
              {!Object.keys(groupedDate).length ? (
                ""
              ) : (
                <div>
                  {Object.keys(groupedDate).length &&
                    Object.keys(groupedDate).map((req, index) => {
                      return (
                        <Resultss
                          req={req}
                          data={groupedDate[req]}
                          index={index}
                        />
                      );
                    })}
                </div>
              )}
            </div>
          )}
        </div>

        {/* <div className="mt-4">
          <div className="d-flex justify-content-center mb-3">
            <p className="notificaationDate">Today</p>
          </div>
          <div className="d-flex justify-content-start">
            <div>
              <MdKeyboardArrowUp
                size={24}
                color="#fff"
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </div>
            <div className="ml-3">
              <p className="mb-0 notificationText">
                Trf Nwachukwu Kenechuwkwu to OBANI CLINICS Registration-058
              </p>
              <p className="mb-0 mt-1 notificationAmount">-N5,000</p>
            </div>
          </div>
          <hr style={{ background: "#F8FA82" }} />
        </div>

        <div>
          <div className="d-flex justify-content-start">
            <div>
              <CiCalendar
                size={24}
                color="#fff"
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#C4DA00",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </div>
            <div className="ml-3">
              <p className="mb-0 notificationText">
                Your scheduled drop off date is today.
              </p>
            </div>
          </div>
          <hr style={{ background: "#F8FA82" }} />
        </div>
        <div>
          <div className="d-flex justify-content-start">
            <div>
              <RiArrowDownSLine
                size={24}
                color="#fff"
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#C4DA00",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </div>
            <div className="ml-3">
              <p className="mb-0 notificationText">
                Switch~Anthony Ekezie payment to you.
              </p>
              <p className="mb-0 mt-1 notificationAmount">N5,000</p>
            </div>
          </div>
          <hr style={{ background: "#F8FA82" }} />
        </div> */}
      </div>
      <FilterModal
        showFilter={showFilter}
        handleCloseFilter={handleCloseFilter}
        start={start}
        end={end}
        setStart={setStart}
        setEnd={setEnd}
        filterNotification={filterNotification}
        loading={loading}
      />
    </div>
  );
};

export default DriversNotification;
