import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import avatar from "../../../../../assets/images/avatarr.png";
import phone from "../../../../../assets/images/phone.png";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import DriversNavigation from "../../components/Navbar/navbar";

const StartTransactionQuick = (props) => {
  const [pricing, setPricing] = useState({});
  const [loading, setLoading] = useState(false);
  const [pick, setPick] = useState("");

  const getAllPricing = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/all-waste-categories`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        //  console.log("waste categories ==>", response.data);
        setPricing(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    getAllPricing();
  }, []);

  const initiateDetails = JSON.parse(localStorage.getItem("initiate"));
  // console.log(initiateDetails);

  const validate = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/driver/single-pickup/${initiateDetails?.code}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data;
        // console.log(result);
        // props.history.push("/drivers/calculate");
        props.history.push({
          pathname: "/drivers/calculate",
          state: { pricingObject: pricing },
        });
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <DriversNavigation />
      <div className="mx-3">
        <div
          className="d-flex align-items-center my-3"
          style={{ width: "300px" }}
        >
          <img src={avatar} alt={avatar} style={{ width: "20%" }} />
          <div>
            <p
              className="mb-1 ml-3"
              style={{ fontSize: "14px", fontWeight: "400" }}
            >
              Household: {initiateDetails?.owner?.name}
            </p>
            <div className="d-flex align-items-center ml-3 mt-2">
              <div style={{ width: "30px" }}>
                <img src={phone} alt="phone" style={{ width: "100%" }} />
              </div>

              <p
                className="mb-0 ml-2"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                {initiateDetails?.owner?.phone}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <p>{initiateDetails?.owner?.address}</p>
        </div>
      </div>

      <div className="w-75 ml-5 mt-5 containerWidth">
        <div className="btnBottomDivss">
          <button
            type="button"
            className="btn greenBtn btn-sm btn-block"
            onClick={validate}
            disabled={loading}
          >
            {loading ? (
              <span
                style={{
                  color: "white",
                  marginLeft: "2px",
                  marginTop: "1px",
                }}
                className="text-center"
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </span>
            ) : (
              "Start Transaction"
            )}
          </button>
          <button
            type="button"
            className="btn whiteBtn btn-sm btn-block mt-2"
            //   onClick={validate}
            disabled={loading}
          >
            Get Map Directions
          </button>
        </div>
      </div>
    </div>
  );
};

export default StartTransactionQuick;
