import React, { useState } from "react";
import "./index.css";
import newLogo from "../../../../assets/images/newLogo.png";
import { toast } from "react-toastify";
import * as EmailValidator from "email-validator";
import axios from "axios";
import { switchEndpoint } from "../../../../redux/actions/endpoints";

const DriversFPW = (props) => {
  const [loading, setLoading] = useState(false);
  const [resetData, setResetData] = useState({
    phone: "",
  });

  const handleLoginForm =
    (name) =>
    ({ target }) => {
      setResetData({
        ...resetData,
        [name]: target.value,
      });
    };

  const handleReset = () => {
    console.log(resetData);
    let payload = {
      phone: resetData.phone,
    };
    localStorage.setItem("phone", resetData.phone);

    setLoading(true);
    axios
      .post(`${switchEndpoint}/driver/auth/forgot-password`, payload)
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        // localStorage.setItem("token", response.data.token);
        props.history.push("/drivers/otp");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error(error.response.data.errors, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const validate = () => {
    if (!resetData.phone) {
      toast.error("Phone number not provided !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    handleReset();
  };
  return (
    <div>
      <div className="row noMarginPadding">
        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-8 col-xs-12 PaddingLeft-10">
          <div className="container pl-4 pt-4 PaddingLeft-10">
            <div style={{ width: "150px" }}>
              <img src={newLogo} alt="logo" style={{ width: "100%" }} />
            </div>
            <div className="w-75 ml-5 mt-5 containerWidth">
              <h5 className="headerText headerh5 newH5">Switch Driver</h5>
              <p className="loginStyle mb-0" style={{ fontSize: "18px" }}>Forgot Password</p>
              <div className="form-group">
                <label className="label-control mb-0 labelStyle">
                  Phone Number
                </label>
                <input
                  type="phone"
                  className="form-control inputStyle"
                  placeholder="Enter phone number"
                  value={resetData.phone}
                  onChange={handleLoginForm("phone")}
                />
              </div>

              <div className="btnBottomDiv">
                <button
                  type="button"
                  className="btn passwordBtn btn-sm btn-block mt-4 px-2"
                  onClick={validate}
                  // onClick={() => {
                  //   props.history.push("/drivers/otp");
                  // }}
                  disabled={loading}
                >
                  Send OTP
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-4 col-md-4 rightImg"></div>
      </div>
    </div>
  );
};

export default DriversFPW;
