import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Space, Table, Tag } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import calendar from "../../../../../assets/images/calendar.png";
import axios from "axios";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import { toast } from "react-toastify";
import moment from "moment";
import { useHistory } from "react-router-dom";

const AggregationCenter = ({ setTitleName }) => {
  const history = useHistory();
  const [dataTab, setDataTab] = useState([]);
  const [latest, setLatest] = useState({});
  const [loading, setLoading] = useState(false);
  const [aggData, setAggData] = useState({
    weight: "",
    date: "",
  });

  const handleAggForm =
    (name) =>
    ({ target }) => {
      setAggData({
        ...aggData,
        [name]: target.value,
      });
    };

  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      key: "id",
      // sorter: false,
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      sorter: true,
      render: (weight) => <p className="mb-0">{`${weight}kg`}</p>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: () => <p className="mb-0">Drop Off</p>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => (
        <p className="mb-0">{moment(date).format("DD/MM/YYYY")}</p>
      ),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (time) => (
        <p className="mb-0">{moment(time, "HH:mm:ss").format("hh:mm")}</p>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const capitalized = status.charAt(0).toUpperCase() + status.slice(1);
        return (
          <p className="mb-0" style={{ color: "#c4da00", fontSize: "13px" }}>
            {capitalized}
          </p>
        );
      },
    },

    {
      title: " ",
      key: "action",
      render: () => <BsThreeDotsVertical style={{ cursor: "pointer" }} />,
    },
  ];

  const todayy = new Date(); // get today's date
  const tomorrow = new Date(todayy);
  tomorrow.setDate(todayy.getDate() + 1); // Add 1 to today's date and set it to tomorrow
  console.log("Tomorrow is", tomorrow.toISOString().split("T")[0]);
  const newTomorrow = tomorrow.toISOString().split("T")[0];

  const today = new Date().toISOString().split("T")[0];

  // console.log(new Date() + 1);

  const getAggregations = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/collection-center/collection-dropoffs/history`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data);
        setDataTab(response.data);
        // const result = response.data;
      })
      .catch((error) => {
        toast.error(error.response, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const getAggregation = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/collection-center/collection-dropoffs`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setLatest(response.data);
        // const result = response.data;
        // console.log(result);
      })
      .catch((error) => {
        toast.error(error.response, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const createDropOff = () => {
    if (!aggData.weight) {
      toast.error("Weight not provided !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!aggData.date) {
      toast.error("Date not provided !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    setLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      weight: aggData.weight,
      dropoff_date: moment(aggData.date).format("YYYY/MM/DD"),
    };
    axios
      .post(
        `${switchEndpoint}/collection-center/collection-dropoffs/create`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log(response.data);
        getAggregation();
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setAggData({
          weight: "",
          date: "",
        });
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    setTitleName("Aggregation Center");
    getAggregations();
    getAggregation();
  }, []);
  return (
    <main>
      <div className="main__container">
        <div>
          <div>
            <div className="main__cards4">
              <div className="cards4">
                <div className="card_inners">
                  <div className="d-flex justify-content-between align-items-centers blockWay">
                    <h5
                      className="card_inners_amount mb-3"
                      style={{
                        fontSize: "16px",
                        paddingTop: "0",
                        marginTop: "0",
                      }}
                    >
                      Drop Off at Aggregation Center
                    </h5>
                    <div className="fpw">
                      <p style={{ color: "#231F20" }}>
                        Message Aggregation Center &nbsp;
                        <span style={{ color: "#c4da00" }}>&#62;</span>
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center blockWay">
                    <div className="form-group w33">
                      <label className="mb-0">Weight</label>
                      <input
                        type="number"
                        className="form-control inputStyle mt-1"
                        placeholder="Enter weight in kg"
                        value={aggData.weight}
                        onChange={handleAggForm("weight")}
                      />
                    </div>

                    <div className="form-group w33 applyMl">
                      <label className="mb-0">Drop Off Date</label>
                      <input
                        type="date"
                        className="form-control inputStyle mt-1"
                        placeholder="Select Drop Off Date"
                        // onFocus={(e) => (e.target.type = "date")}
                        // onBlur={(e) => (e.target.type = "text")}
                        min={newTomorrow}
                        value={aggData.date}
                        onChange={handleAggForm("date")}
                      />
                    </div>

                    <div className="w33 applyMl">
                      <button
                        type="button"
                        className="btn loginBtn btn-sm btn-block gen"
                        onClick={createDropOff}
                        disabled={loading}
                      >
                        {loading ? "Generating ..." : "Generate Drop Off Code"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div className="main__cards4">
              <div className="cards4">
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5
                      className="card_inners_amount mb-3"
                      style={{
                        fontSize: "16px",
                        paddingTop: "0",
                        marginTop: "0",
                      }}
                    >
                      Scheduled Drop Off
                    </h5>

                    <p
                      onClick={() => {
                        history.push("/dashboards/aggregationScheduled");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      View All{" "}
                      <span style={{ color: "#c4da00", marginLeft: "5px" }}>
                        &#62;
                      </span>
                    </p>
                  </div>

                  <div className="card_inners w-50 makeFull">
                    {Object.keys(latest).length === 0 && (
                      <p className="mb-0 text-muted py-2">
                        No Scheduled Request
                      </p>
                    )}
                    {Object.keys(latest).length !== 0 && (
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="form-group w33">
                          <h5
                            style={{ color: "#c4da00" }}
                          >{`${latest.weight_in_kg}kg`}</h5>
                        </div>

                        <div className="form-group w33">
                          <div className="d-flex">
                            <div>
                              <img src={calendar} alt={calendar} />
                            </div>
                            <div className="">
                              <p
                                className="mb-0 ml-2"
                                style={{ fontSize: "13px", color: "#898989" }}
                              >
                                Drop Off Date
                              </p>
                              <p
                                className="ml-2"
                                style={{
                                  fontSize: "14px",
                                  color: "#404040",
                                  fontWeight: "700",
                                }}
                              >
                                {latest.date_type}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="form-group w33">
                          <div className="d-flex">
                            <div>
                              <p
                                className="mb-0 ml-0"
                                style={{ fontSize: "13px", color: "#898989" }}
                              >
                                Drop Off Code
                              </p>
                              <p
                                className="ml-0"
                                style={{
                                  fontSize: "14px",
                                  color: "#404040",
                                  fontWeight: "700",
                                }}
                              >
                                {latest.code}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cardss p-0 py-1  px-2">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <span className="d-flex align-items-center py-3 px-2">
                <b>History</b>({dataTab.length})
              </span>
            </div>
            <div
              className="fpw mr-2"
              //   onClick={() => history.push("/dashboard/collections")}
            >
              <p style={{ color: "#231F20", fontSize: "13px" }}>
                Filter By
                <span style={{ color: "#c4da00", marginLeft: "5px" }}>
                  &#62;
                </span>
              </p>
            </div>
          </div>

          <Table
            columns={columns}
            dataSource={dataTab}
            size="middle"
            pagination={false}
          />
        </div>
      </div>
    </main>
  );
};

export default AggregationCenter;
