import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Avatar from "../../../../../../assets/images/avatarr.png";
import "../index.css";

const CompleteModal = ({
  showComplete,
  handleCloseComplete,
  handleShowAuthenticate,
  droppData,
  dropOfff,
  setDropOfff,
}) => {
  const [cal, seCal] = useState(0);

  const calc = (val) => {
    let conv = val;
    let calcc = conv / 1000;
    seCal(calcc);
  };

  useEffect(() => {
    calc(dropOfff);
  }, [dropOfff]);

  return (
    <div>
      <Modal
        show={showComplete}
        onHide={() => {
          handleCloseComplete();
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <h5 className="sec-headers mb-3">Enter Drop Off Code complete</h5>

              <div className="d-flex align-items-center">
                <div className="imgAvatarHolder ml-0">
                  <img src={Avatar} alt="image" />
                </div>
                <div className="ml-2">
                  <p
                    className="mb-0"
                    style={{ fontSize: "14px", fontWeight: "500" }}
                  >
                    {droppData?.aggregation_center?.name}
                  </p>
                  <p className="mb-0" style={{ fontSize: "12px" }}>
                    {droppData?.aggregation_center?.address}
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="d-flex align-items-center w-75 makeFull">
                <div className="mb-3 mt-4 w-60">
                  <label className="mb-1 form-label">Weight</label>
                  <input
                    type="text"
                    className="form-control form-control-lg inputStyle"
                    placeholder="Enter Weight in KG"
                    value={dropOfff}
                    onChange={(e) => setDropOfff(e.target.value)}
                  />
                </div>
                <div className="ml-4">
                  <p className="mb-1" style={{ fontSize: "13px" }}>
                    Collection Center Weight
                  </p>
                  <div className="pt-1" style={{ backgroundColor: "#F8FFF8" }}>
                    <p
                      style={{ fontSize: "13px", padding: "10px 10px" }}
                      className="ml-2"
                    >
                      {`${cal} tonnes`}
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-4 mb-5 w-25 adjW">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={() => {
                    handleCloseComplete();
                    handleShowAuthenticate();
                  }}
                >
                  Complete Drop Off
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CompleteModal;
