import { Table } from "antd";
import React, { useState } from "react";
import AuthenticateModal from "./modals/authenticate";
import FundModal from "./modals/fund";
import SuccessfulModal from "./modals/successful";

const FundCenter = ({ switchToTransaction, switchToFundDriver }) => {
  const [showFund, setShowFund] = useState(false);
  const handleCloseFund = () => setShowFund(false);
  const handleShowFund = () => setShowFund(true);

  const [showAuthenticate, setShowAuthenticate] = useState(false);
  const handleCloseAuthenticate = () => setShowAuthenticate(false);
  const handleShowAuthenticate = () => setShowAuthenticate(true);

  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      key: "id",
      sorter: false,
      //   render: (text) => <a>{text}</a>,
    },
    {
      title: "Collection Center",
      dataIndex: "collectionCenter",
      key: "collectionCenter",
      //   render: (text) => <a>{text}</a>,
    },
    {
      title: "Wallet Balance",
      dataIndex: "walletBalance",
      key: "walletBalance",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
    },
  ];

  const data = [
    {
      key: "1",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "2",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "3",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "4",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "5",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "6",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "7",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "8",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "9",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "10",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "11",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "12",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "13",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "14",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "15",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "16",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
    {
      key: "17",
      collectionCenter: "Collection Center: Lekki",
      walletBalance: "N295,035.24",
      action: (
        <p
          className="mb-0"
          style={{
            backgroundColor: "#c4da00",
            boxShadow: "none !important",
            width: "max-content",
            padding: "5px 10px",
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleShowFund}
        >
          Fund Wallet
        </p>
      ),
    },
  ];
  return (
    <div>
      <div className="requestss">
        <div className="cardss p-0 py-1 px-2">
          <div className="buttonHolders py-3">
            <button className="unstyled def" onClick={switchToTransaction}>
              Transactions
            </button>
            {/* <button className="styled def ml-2" style={{ color: "#fff" }}>
              Fund Collection Center
            </button> */}
            <button className="unstyled def ml-2" onClick={switchToFundDriver}>
              Fund Driver
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex mb-3">
              <b>Fund Collection Center </b>
              <div>
                <div className="form-check form-check-inline ml-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                    // checked
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    Low to High
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="option1"
                  />
                  <label className="form-check-label" for="inlineRadio2">
                    High to Low
                  </label>
                </div>
              </div>
            </div>
            <div className="fpw mr-2">
              <p style={{ color: "#231F20", fontSize: "13px" }} className="">
                Filter By
                <span style={{ color: "#c4da00", marginLeft: "5px" }}>
                  &#62;
                </span>
              </p>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={data}
            size="middle"
            pagination={true}
          />
        </div>
      </div>

      <FundModal
        handleCloseFund={handleCloseFund}
        showFund={showFund}
        handleShowAuthenticate={handleShowAuthenticate}
      />
      <SuccessfulModal
        handleCloseSuccess={handleCloseSuccess}
        showSuccess={showSuccess}
      />
      <AuthenticateModal
        handleCloseAuthenticate={handleCloseAuthenticate}
        showAuthenticate={showAuthenticate}
        handleShowSuccess={handleShowSuccess}
      />
    </div>
  );
};

export default FundCenter;
