import React from "react";
import Modal from "react-bootstrap/Modal";
import { Table } from "antd";

const EomTable = ({ showEom, handleCloseEom }) => {
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            // render: (id) => {
            //     return <p
            //     // className={styles.normalText}
            //     >{id}</p>;
            // },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",

        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
        },
        {
            title: "Payment",
            dataIndex: "payment",
            key: "payment",
        },
        {
            title: "Total Due",
            dataIndex: "due",
            key: "due",
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Time",
            dataIndex: "time",
            key: "time",
        },
    ];

    const data = [];
    return (
        <div>
            <Modal
                show={showEom}
                onHide={handleCloseEom}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div style={{ overflowX: "scroll", maxWidth: "1300px" }}>
                        <Table
                            dataSource={data}
                            columns={columns}
                            scroll={{ x: "max-content" }}
                            pagination={true}
                        // onChange={handleTableChange}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default EomTable;
