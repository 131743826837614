import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import avatar from "../../../../../assets/images/avatarr.png";
import phone from "../../../../../assets/images/phone.png";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import DriversNavigation from "../../components/Navbar/navbar";
import InitiateModal from "../modals/initiate";
import { Spinner } from "react-bootstrap";

const InitiatePickUp = (props) => {
  const [loading, setLoading] = useState(false);
  const initiateDetails = JSON.parse(localStorage.getItem("initiate"));

  const [showInitiate, setShowInitiate] = useState(false);
  const handleCloseInitiate = () => setShowInitiate(false);
  const handleShowInitiate = () => setShowInitiate(true);

  const openGoogleMapsWithLocation = () => {
    // Check if the geolocation is available in the user's browser.
    if ("geolocation" in navigator) {
      // Get the user's current location using the geolocation API.
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Replace these with the latitude and longitude of your destination.
          const destinationLatitude = initiateDetails?.owner?.latitude;
          const destinationLongitude = initiateDetails?.owner?.longitude;

          // Generate the Google Maps URL with the user's current location as the origin and the specified destination.
          const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destinationLatitude},${destinationLongitude}`;
          // const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${destinationLatitude},${destinationLongitude}`;

          // Open the link in a new tab/window.
          window.open(googleMapsUrl, "_blank");
        },
        (error) => {
          console.error("Error getting the user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  };

  const initiatePickUp = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/driver/initiate-pickup/${initiateDetails?.code}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        handleShowInitiate();
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <DriversNavigation />

      <div className="mx-3">
        <div
          className="d-flex align-items-center my-3"
          style={{ width: "300px" }}
        >
          <img src={avatar} alt={avatar} style={{ width: "20%" }} />
          <div>
            <p
              className="mb-1 ml-3"
              style={{ fontSize: "14px", fontWeight: "400" }}
            >
              Household: {initiateDetails?.owner?.name}
            </p>
            <div className="d-flex align-items-center ml-3 mt-2">
              <div style={{ width: "30px" }}>
                <img src={phone} alt="phone" style={{ width: "100%" }} />
              </div>

              <p
                className="mb-0 ml-2"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                {initiateDetails?.owner?.phone}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <p>Address: {initiateDetails?.owner?.address}</p>
        </div>
      </div>
      <div className="w-75 ml-5 mt-5 containerWidth">
        <div className="btnBottomDivss">
          <button
            type="button"
            className="btn greenBtn btn-sm btn-block"
            onClick={() => {
              initiatePickUp();
            }}
            disabled={loading}
          >
            {loading ? (
              <span
                style={{
                  color: "white",
                  marginLeft: "2px",
                  marginTop: "1px",
                }}
                className="text-center"
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </span>
            ) : (
              "Initiate Pick Up"
            )}
          </button>
          <button
            type="button"
            className="btn whiteBtn btn-sm btn-block mt-2"
            // onClick={() => {
            //   props.history.push("/drivers/direction");
            // }}
            onClick={openGoogleMapsWithLocation}
            disabled={loading}
          >
            Get Map Directions
          </button>
        </div>
      </div>

      <InitiateModal
        showInitiate={showInitiate}
        handleCloseInitiate={handleCloseInitiate}
      />
    </div>
  );
};

export default InitiatePickUp;
