import React from "react";
import Modal from "react-bootstrap/Modal";
import SuccessImg from "../../../../../../../../assets/images/success.png";
import "./index.css";

const PaymentSuccessfulModal = ({ showPaymentS, handleClosePaymentS }) => {
  const reference = JSON.parse(localStorage.getItem("reference"));
  return (
    <div>
      <Modal
        show={showPaymentS}
        onHide={() => {
          handleClosePaymentS();
          window.location.reload();
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <div className="text-center mt-3 mb-5">
                <div className="successImg mb-4">
                  <img src={SuccessImg} alt="success image" />
                </div>
                <div>
                  <p>Transaction Successful</p>
                  <p style={{ fontSize: "12px" }}>
                    You sent {`${reference?.payment_details?.amount}`} to{" "}
                    {reference?.owner?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentSuccessfulModal;
