//PRICE FOR DROP OFF
export const DROP_OFF_CLEAN_SORTED_BELOW_100 = 175;
export const DROP_OFF_CLEAN_SORTED_ABOVE_100 = 205;
export const DROP_OFF_CLEAN_SORTED_ABOVE_200 = 225;

export const DROP_OFF_CLEAN_UNSORTED_BELOW_100 = 155;
export const DROP_OFF_CLEAN_UNSORTED_ABOVE_100 = 185;
export const DROP_OFF_CLEAN_UNSORTED_ABOVE_200 = 205;

export const DROP_OFF_MIXED_PLASTIC_BELOW_100 = 85;
export const DROP_OFF_MIXED_PLASTIC_ABOVE_100 = 105;
export const DROP_OFF_MIXED_PLASTIC_ABOVE_200 = 105;

export const DROP_OFF_CANS_BELOW_100 = 205;
export const DROP_OFF_CANS_ABOVE_100 = 255;
export const DROP_OFF_CANS_ABOVE_200 = 255;

export const DROP_OFF_HARD_PACKAGING_BELOW_100 = 150;
export const DROP_OFF_HARD_PACKAGING_ABOVE_100 = 150;
export const DROP_OFF_HARD_PACKAGING_ABOVE_200 = 160;

export const DROP_OFF_PURE_WATER_BELOW_100 = 90;
export const DROP_OFF_PURE_WATER_ABOVE_100 = 90;
export const DROP_OFF_PURE_WATER_ABOVE_200 = 100;

export const DROP_OFF_SINGLE_COLOR_BELOW_100 = 70;
export const DROP_OFF_SINGLE_COLOR_ABOVE_100 = 140;
export const DROP_OFF_SINGLE_COLOR_ABOVE_200 = 140;

export const DROP_OFF_HDPE_BELOW_100 = 130;
export const DROP_OFF_HDPE_ABOVE_100 = 150;
export const DROP_OFF_HDPE_ABOVE_200 = 150;

export const DROP_OFF_PP_BELOW_100 = 130;
export const DROP_OFF_PP_ABOVE_100 = 150;
export const DROP_OFF_PP_ABOVE_200 = 150;

export const DROP_OFF_PACKAGING_BAGS_BELOW_100 = 80;
export const DROP_OFF_PACKAGING_BAGS_ABOVE_100 = 100;
export const DROP_OFF_PACKAGING_BAGS_ABOVE_200 = 110;

//PRICE FOR PICK UP
export const PICK_UP_CLEAN_SORTED_BELOW_100 = 155;
export const PICK_UP_CLEAN_SORTED_ABOVE_100 = 185;
export const PICK_UP_CLEAN_SORTED_ABOVE_200 = 205;

export const PICK_UP_CLEAN_UNSORTED_BELOW_100 = 135;
export const PICK_UP_CLEAN_UNSORTED_ABOVE_100 = 165;
export const PICK_UP_CLEAN_UNSORTED_ABOVE_200 = 185;

export const PICK_UP_MIXED_BELOW_100 = 85;
export const PICK_UP_MIXED_ABOVE_100 = 105;
export const PICK_UP_MIXED_ABOVE_200 = 105;

export const PICK_UP_HARD_PACKAGING_BELOW_100 = 130;
export const PICK_UP_HARD_PACKAGING_ABOVE_100 = 130;
export const PICK_UP_HARD_PACKAGING_ABOVE_200 = 140;

export const PICK_UP_PURE_WATER_BELOW_100 = 60;
export const PICK_UP_PURE_WATER_ABOVE_100 = 70;
export const PICK_UP_PURE_WATER_ABOVE_200 = 80;

export const PICK_UP_SINGLE_COLOR_BELOW_100 = 70;
export const PICK_UP_SINGLE_COLOR_ABOVE_100 = 100;
export const PICK_UP_SINGLE_COLOR_ABOVE_200 = 100;

export const PICK_UP_HDPE_BELOW_100 = 130;
export const PICK_UP_HDPE_ABOVE_100 = 130;
export const PICK_UP_HDPE_ABOVE_200 = 130;

export const PICK_UP_PP_BELOW_100 = 130;
export const PICK_UP_PP_ABOVE_100 = 130;
export const PICK_UP_PP_ABOVE_200 = 130;

export const PICK_UP_MIXED_CAPS_BELOW_100 = 60;
export const PICK_UP_MIXED_CAPS_ABOVE_100 = 90;
export const PICK_UP_MIXED_CAPS_ABOVE_200 = 90;

export const PICK_UP_PACKAGING_BAGS_BELOW_100 = 70;
export const PICK_UP_PACKAGING_BAGS_ABOVE_100 = 80;
export const PICK_UP_PACKAGING_BAGS_ABOVE_200 = 90;

export const PICK_UP_MIXED_PLASTICS_BELOW_100 = 75;
export const PICK_UP_MIXED_PLASTICS_ABOVE_100 = 95;
export const PICK_UP_MIXED_PLASTICS_ABOVE_200 = 95;

export const PICK_UP_CANS_BELOW_100 = 185;
export const PICK_UP_CANS_ABOVE_100 = 235;
export const PICK_UP_CANS_ABOVE_200 = 235;

export const DROP_OFF_MIXED_PLASTIC = 120;

export const DROP_OFF_MIXED_CAPS = 130;
