import React from "react";
import PubnubMessages from "./messagesAfresh/pubnub";

const MessagesComponents = () => {
  return (
    <main>
      <PubnubMessages />
    </main>
  );
};

export default MessagesComponents;
