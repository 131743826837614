import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Marker,
  InfoWindow,
} from "react-google-maps";

function Map() {
  //   const calledDriver = JSON.parse(localStorage.getItem("driversProp"));
  //   const [longitude, setLongitude] = useState(0);
  //   const [latitude, setLatitude] = useState(0);

  const [selectedDriver, setSelectedDriver] = useState(null);
  const locs = [
    {
      id: 1,
      name: "Cicero Kohler",
      latitude: 6.56721,
      longitude: 3.36727,
      about: "This is driver 1",
      address: "12, Shokunbi Street, Mushin",
    },
  ];

  //   useEffect(() => {
  //     // console.log(Array.isArray(calledDriver));
  //     if (selectedDriver) {
  //       setLatitude(selectedDriver.latitude);
  //       setLongitude(selectedDriver.longitude);
  //     }
  //   }, [selectedDriver]);
  return (
    <div>
      <GoogleMap
        zoom={17}
        center={{ lat: locs[0]?.latitude, lng: locs[0]?.longitude }}
      >
        <Marker
          position={{
            lat: locs[0]?.latitude,
            lng: locs[0]?.longitude,
          }}
          icon={{
            scaledSize: new window.google.maps.Size(40, 40),
            url: require("./carMarkerActive.png"),
          }}
          onClick={() => {
            const locc = locs[0];
            setSelectedDriver(locc);
          }}
        />

        {selectedDriver && (
          <InfoWindow
            position={{
              lat: locs[0]?.latitude,
              lng: locs[0]?.longitude,
            }}
            onCloseClick={() => setSelectedDriver(null)}
          >
            <div>
              <h6>{selectedDriver.name}</h6>
              <p>{selectedDriver.address}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(Map));

const MapsUI = () => {
  // console.log("This is the props", props.driver)
  // localStorage.setItem("driversProp", JSON.stringify(props.driver));
  return (
    <div className="mobileMappss" style={{ width: "45vw", height: "100vh" }}>
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `calc(100vh - 60px)` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

export default MapsUI;
