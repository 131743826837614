import React from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import green from "../../../../../assets/images/green.png";

const InitiateModal = ({ showInitiate, handleCloseInitiate }) => {
  const initiateDetails = JSON.parse(localStorage.getItem("initiate"));
  const history = useHistory();
  return (
    <div>
      <Modal
        show={showInitiate}
        onHide={() => {
          handleCloseInitiate();
          history.push("/drivers/start");
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center pb-2">
            <div className="py-3">
              <img src={green} alt="green" />
            </div>

            <div className="py-4">
              <p className="" style={{ fontWeight: "600", fontSize: "16px" }}>
                Pick Up Initiated
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "13px",
                  color: "#898989",
                }}
              >
                {initiateDetails?.owner?.name} has been notified that you are nearby.
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InitiateModal;
