import { SET_WALLET_TYPE } from "../types";
const INITIAL_STATE = {
  display: "transactions",
};

const WalletReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_WALLET_TYPE:
      return {
        ...state,
        display: action.payload,
      };

    default:
      return state;
  }
};

export default WalletReducer;