import { Table } from "antd";
import React, { useState } from "react";
import AuthenticateModal from "./modals/authenticate";
import FundModal from "./modals/fund";
import SuccessfulModal from "./modals/successful";

const FloatRequest = ({
  switchToFundCenter,
  switchToFundDriver,
  switchToTransaction,
  float,
  getFloatRequests
}) => {
  const [showFund, setShowFund] = useState(false);
  const handleCloseFund = () => setShowFund(false);
  const handleShowFund = () => setShowFund(true);

  const [showAuthenticate, setShowAuthenticate] = useState(false);
  const handleCloseAuthenticate = () => setShowAuthenticate(false);
  const handleShowAuthenticate = () => setShowAuthenticate(true);

  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      key: "id",
      sorter: false,
      render: (id, record, index) => {
        ++index;
        return index;
      },
    },
    {
      title: "Driver",
      dataIndex: "driver",
      key: "driver",
      //   render: (text) => <a>{text}</a>,
    },
    {
      title: "Wallet Balance",
      dataIndex: "driver_balance",
      key: "driver_balance",
      render: (bal) => {
        return <p className="mb-0">{`₦${bal}`}</p>;
      },
    },
    {
      title: "Requested Amount",
      dataIndex: "requested_amount",
      key: "requested_amount",
      render: (amt) => {
        return <p className="mb-0">{`₦${amt}`}</p>;
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "",
      dataIndex: "",
      key: "action",
      render: (action) => {
        return (
          <div className="d-flex">
            <p
              className="mb-0"
              style={{
                backgroundColor: "#c4da00",
                boxShadow: "none !important",
                width: "max-content",
                padding: "5px 10px",
                textAlign: "center",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                // console.log(action);
                localStorage.setItem("req", JSON.stringify(action));
                localStorage.setItem("reqID", action.request_id);
                localStorage.setItem("status", "accept");
                handleShowAuthenticate();
              }}
            >
              Approve
            </p>
            <p
              className="mb-0 ml-3"
              style={{
                backgroundColor: "#EB5757",
                boxShadow: "none !important",
                width: "max-content",
                padding: "5px 10px",
                textAlign: "center",
                borderRadius: "5px",
                cursor: "pointer",
                color: "#fff",
              }}
              onClick={() => {
                // console.log(action);
                localStorage.setItem("reqID", action.request_id);
                localStorage.setItem("status", "reject");
                handleShowAuthenticate();
              }}
            >
              Decline
            </p>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="requestss">
        <div className="cardss p-0 py-1 px-2">
          <div className="buttonHolders py-3">
            <button className="unstyled def" onClick={switchToTransaction}>
              Transactions
            </button>
            <button className="unstyled def ml-2" onClick={switchToFundCenter}>
              Fund Collection Center
            </button>
            <button className="unstyled def ml-2" onClick={switchToFundDriver}>
              Fund Driver
            </button>
            <button className="styled def" style={{ color: "#fff" }}>
              Float Requests
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex mb-3">
              <b>Float Requests </b>
            </div>
            <div className="fpw mr-2">
              <p style={{ color: "#231F20", fontSize: "13px" }} className="">
                Filter By
                <span style={{ color: "#c4da00", marginLeft: "5px" }}>
                  &#62;
                </span>
              </p>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={float}
            size="middle"
            pagination={true}
          />
        </div>
      </div>

      <FundModal
        handleCloseFund={handleCloseFund}
        showFund={showFund}
        handleShowAuthenticate={handleShowAuthenticate}
      />
      <SuccessfulModal
        handleCloseSuccess={handleCloseSuccess}
        showSuccess={showSuccess}
      />
      <AuthenticateModal
        handleCloseAuthenticate={handleCloseAuthenticate}
        showAuthenticate={showAuthenticate}
        handleShowSuccess={handleShowSuccess}
        getFloatRequests={getFloatRequests}
      />
    </div>
  );
};

export default FloatRequest;
