import axios from "axios";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Avatar from "../../../../../../../../assets/images/avatarr.png";
import { switchEndpoint } from "../../../../../../../../redux/actions/endpoints";
import "./index.css";
import capitalize from "../../../../../../../../capitalize";

const PaymentRequestModal = ({
  showPaymentR,
  handleClosePaymentR,
  dropOffData,
  handleShowAuthenticate,
}) => {
  const reference = JSON.parse(localStorage.getItem("reference"));
  return (
    <div>
      <Modal
        show={showPaymentR}
        onHide={handleClosePaymentR}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <h5 className="sec-headers mb-3">Payment Request Confirmed</h5>
              <div className="d-flex justify-content-between align-items-center">
                <p className="ftsz-regular" style={{ fontSize: "14px" }}>
                  {capitalize(reference?.owner?.roles[0])}:{" "}
                  {reference?.owner?.name}
                </p>
                <div className="d-flex align-items-center ">
                  <div>
                    <p
                      className="mb-0"
                      style={{ fontSize: "14px", color: "#898989" }}
                    >
                      Drop off Code
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#404040",
                        fontWeight: "700",
                      }}
                    >
                      {reference?.code}
                    </p>
                  </div>
                  <div className="imgAvatarHolder ml-3">
                    <img src={Avatar} alt="image" />
                  </div>
                </div>
              </div>

              <div className="text-center mt-3 mb-5">
                <div>
                  <p>You are to pay</p>
                  <h1 className="fontBold">{`₦${reference?.dropoff_details?.total_price}`}</h1>
                  <p>To {reference?.owner?.name}</p>
                  <p>
                    Payment Method:{" "}
                    {reference?.payment_method === "cash"
                      ? "Cash"
                      : "Switch Transfer"}
                  </p>
                </div>

                <div className="mt-1 mb-5 w-btn m-auto pt-3">
                  <button
                    type="button"
                    className="btn loginBtn btn-sm btn-block d-flex justify-content-center align-items-center"
                    onClick={() => {
                      handleClosePaymentR();
                      handleShowAuthenticate();
                    }}
                  // disabled={loading}
                  >
                    Make Payment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentRequestModal;
