import React from "react";
import DriversNavigation from "../../components/Navbar/navbar";
import MapWrapper from "../map/mapWrapper";

const MapDirection = () => {
  return (
    <div>
      <DriversNavigation />
      <div>
        <MapWrapper />
      </div>
    </div>
  );
};

export default MapDirection;
