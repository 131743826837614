import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Marker,
  InfoWindow,
} from "react-google-maps";

function Map() {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);

  const activeDriver = JSON.parse(localStorage.getItem("driversProppp"));
  // console.log(activeDriver);
  const locs = [
    {
      id: 1,
      name: "Cicero Kohler",
      latitude: 6.56721,
      longitude: 3.36727,
      about: "This is driver 1",
      address: "12, Shokunbi Street, Mushin",
    },
    {
      id: 2,
      name: "Deven Lubowitz",
      latitude: 6.56921,
      longitude: 3.39727,
      about: "This is driver 1",
      address: "3, Palm Avenue Street, Mushin",
    },
    {
      id: 3,
      name: "Agustin Berge",
      latitude: 6.56721,
      longitude: 3.16727,
      about: "This is driver 1",
      address: "14, Palm Groove Estate, Ilupeju",
    },
    {
      id: 4,
      name: "Tracey Moore",
      latitude: 6.26721,
      longitude: 3.36727,
      about: "This is driver 1",
      address: "31, Labinjo Street, Idi oro",
    },
    {
      id: 5,
      name: "Dean Ambrose",
      latitude: 6.66721,
      longitude: 3.56727,
      about: "This is driver 1",
      address: "4, Akobi Cresent, Surulere",
    },
    {
      id: 6,
      name: "Elijah Stevens",
      latitude: 6.66721,
      longitude: 3.30727,
      about: "This is driver 1",
      address: "11, Bode Thomas, Surulere",
    },
    {
      id: 7,
      name: "Julius Berger",
      latitude: 6.59721,
      longitude: 3.33727,
      about: "This is driver 1",
      address: "39, Adeola Odeku, Victoria Island",
    },
  ];

  useEffect(() => {
    // console.log(Array.isArray(calledDriver));
    if (selectedDriver) {
      setLatitude(selectedDriver.latitude);
      setLongitude(selectedDriver.longitude);
    }
  }, [selectedDriver]);
  return (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: locs[0].latitude, lng: locs[0].longitude }}
    >
      {activeDriver?.map((location) => (
        <Marker
          key={location.id}
          position={{ lat: location.latitude, lng: location.longitude }}
          icon={{
            scaledSize: new window.google.maps.Size(40, 40),
            url: require("./carMarkerActive.png"),
          }}
          onClick={() => {
            setSelectedDriver(location);
          }}
        />
      ))}

      {selectedDriver && (
        <InfoWindow
          position={{
            lat: latitude,
            lng: longitude,
          }}
          onCloseClick={() => setSelectedDriver(null)}
        >
          <div>
            <h6>{selectedDriver.name}</h6>
            <p>{selectedDriver.address}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(Map));

const MapsUI = () => {
  return (
    <div className="mobileMapp" style={{ width: "30vw", height: "40vh" }}>
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
        // googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBR6dHhYRuqDgdZp2ayAMyEnQVY7JY7kSA`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `85vh` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

export default MapsUI;

//AIzaSyBR6dHhYRuqDgdZp2ayAMyEnQVY7JY7kSA
