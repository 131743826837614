import React from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import newLogo from "../../assets/images/newLogo.png";

const FinalModal = ({
  showFinal,
  handleCloseFinal,
  handleLogout,
  pathName,
}) => {
  // console.log(pathName, "pathname");
  const history = useHistory();
  return (
    <div>
      <Modal
        show={showFinal}
        onHide={() => {
          handleCloseFinal();
          handleLogout();
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="mb-3">
              <img
                src={newLogo}
                alt="switch logo"
                style={{ width: "30%", textAlign: "center" }}
              />
            </div>
            <div className="mb-2">
              <h4 className="sec-header text-body mb-3 px-sm-5 mx-sm-5">
                You have been inactive
              </h4>
              <p className="ftsz-regular">Your account has been logged out.</p>
            </div>

            <div className="mx-5 py-4">
              <button
                type="button"
                className="btn loginBtn btn-sm btn-block"
                onClick={() => {
                  if (pathName === "/dashboard") {
                    history.push("/aggregation");
                    localStorage.clear();
                  }
                  if (pathName === "/dashboards") {
                    history.push("/collections");
                    localStorage.clear();
                  }
                }}
              >
                Login
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FinalModal;
