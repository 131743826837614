import React, { useEffect, useState } from "react";
import "./index.css";
import newLogo from "../../../../assets/images/newLogo.png";
import { Form, Input } from "antd";
import axios from "axios";
import { switchEndpoint } from "../../../../redux/actions/endpoints";
import { toast } from "react-toastify";

const SuperAdminLogin = (props) => {
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  // const [loginData, setLoginData] = useState({
  //   email: "gloryayo92@gmail.com",
  //   password: "julipels",
  // });

  // console.log(envs)

  const handleLoginForm =
    (name) =>
    ({ target }) => {
      setLoginData({
        ...loginData,
        [name]: target.value,
      });
    };

  let payload = {
    email: loginData.email,
    password: loginData.password,
  };

  // const handleLogin = () => {
  //   setLoading(true);
  //   axios
  //     .post(`${switchEndpoint}/auth/login-email`, payload)
  //     .then((response) => {
  //       if (response.data.customer.roles[0] === "aggregation_center") {
  //         setLoading(false);
  //         toast.success(response.data.message, {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           pauseOnHover: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //         localStorage.setItem("token", response.data.token);
  //         localStorage.setItem(
  //           "centerInfo",
  //           JSON.stringify(response.data.customer)
  //         );
  //         localStorage.setItem(
  //           "center",
  //           response.data.customer.aggregation_center.name
  //         );
  //         props.history.push("/dashboard");
  //       } else {
  //         setLoading(false);
  //         toast.error("Unauthorized", {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           pauseOnHover: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //       toast.error(error.response.data.message, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         pauseOnHover: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //     });
  // };

  // const validate = () => {
  //   if (!loginData.email) {
  //     toast.error("Email not provided !", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       pauseOnHover: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     return;
  //   }
  //   if (!loginData.password) {
  //     toast.error("Password not provided !", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       pauseOnHover: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     return;
  //   }
  //   handleLogin();
  // };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div>
      <div className="row noMarginPadding">
        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-8 col-xs-12 PaddingLeft-10">
          <div className="container pl-4 pt-4 PaddingLeft-10">
            <div style={{ width: "150px" }}>
              <img src={newLogo} alt="logo" style={{ width: "100%" }} />
            </div>
            <div className="w-75 ml-5 mt-5 containerWidth">
              <h5 className="headerText headerh5">Switch Admin</h5>
              <p className="loginStyle mb-0">Login</p>
              <div className="form-group">
                <label className="label-control mb-0 labelStyle">
                  Email Address
                </label>
                <input
                  type="email"
                  className="form-control inputStyle"
                  placeholder="Enter email address"
                  // no-border
                  value={loginData.email}
                  onChange={handleLoginForm("email")}
                />
              </div>
              <div>
                <label className="label-control mb-0 labelStyle">
                  Enter Password
                </label>
                <Form>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    value={loginData.password}
                    onChange={handleLoginForm("password")}
                  >
                    <Input.Password placeholder="*************" />
                  </Form.Item>
                </Form>
              </div>

              <div className="forgotPassword">
                <div></div>
                <div className="fpw">
                  <a
                    href="/admin/forgotPassword"
                    style={{ color: "#231F20", fontSize: "13px" }}
                  >
                    Forgot Password{" "}
                    <span style={{ color: "#c4da00" }}>&#62;</span>
                  </a>
                </div>
              </div>

              <button
                type="button"
                className="btn loginBtn btn-sm btn-block"
                // onClick={validate}
                onClick={() => {}}
                disabled={loading}
              >
                Login
              </button>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-4 col-md-4 rightImg"></div>
      </div>
    </div>
  );
};

export default SuperAdminLogin;
