import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import avatar from "../../../../../assets/images/avatarr.png";
import phone from "../../../../../assets/images/phone.png";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import DriversNavigation from "../../components/Navbar/navbar";
import MixedModal from "../modals/mixed";
import SortedeModal from "../modals/sorted";
import UnsortedeModal from "../modals/unsorted";
import {
  PICK_UP_CANS_BELOW_100,
  PICK_UP_CANS_ABOVE_100,
  PICK_UP_CLEAN_SORTED_ABOVE_100,
  PICK_UP_CLEAN_SORTED_BELOW_100,
  PICK_UP_CLEAN_UNSORTED_ABOVE_100,
  PICK_UP_CLEAN_UNSORTED_BELOW_100,
  PICK_UP_MIXED_PLASTICS_ABOVE_100,
  PICK_UP_MIXED_PLASTICS_BELOW_100,
  PICK_UP_PACKAGING_BAGS_BELOW_100,
  PICK_UP_PACKAGING_BAGS_ABOVE_100,
  PICK_UP_PP_BELOW_100,
  PICK_UP_PP_ABOVE_100,
  PICK_UP_HDPE_BELOW_100,
  PICK_UP_HDPE_ABOVE_100,
  PICK_UP_SINGLE_COLOR_BELOW_100,
  PICK_UP_SINGLE_COLOR_ABOVE_100,
  PICK_UP_PURE_WATER_BELOW_100,
  PICK_UP_PURE_WATER_ABOVE_100,
  PICK_UP_HARD_PACKAGING_BELOW_100,
  PICK_UP_HARD_PACKAGING_ABOVE_100,
  PICK_UP_CLEAN_SORTED_ABOVE_200,
  PICK_UP_CLEAN_UNSORTED_ABOVE_200,
  PICK_UP_MIXED_PLASTICS_ABOVE_200,
  PICK_UP_PURE_WATER_ABOVE_200,
  PICK_UP_SINGLE_COLOR_ABOVE_200,
  PICK_UP_HARD_PACKAGING_ABOVE_200,
  PICK_UP_HDPE_ABOVE_200,
  PICK_UP_PP_ABOVE_200,
  PICK_UP_PACKAGING_BAGS_ABOVE_200,
  PICK_UP_CANS_ABOVE_200,
} from "../../../../../prices";
import SingleColorModal from "../modals/singleColor";
import PureWaterModal from "../modals/pureWater";
import HdpeModal from "../modals/hdpe";
import HardPackagingModal from "../modals/hardPackaging";
import PPModal from "../modals/pp";
import PackagingBagModal from "../modals/packagingBag";
import CansModal from "../modals/cansModal";
import { getPricing } from "../../../../../pricingMethod";

const categories = [
  "Clean Sorted",
  "Clean Unsorted",
  "Pure Water Nylon",
  "Single color caps",
  "Hard packing bags (dry)",
  "Mixed Plastics",
  "HDPE plastics below 5 Litres",
  "PP Plastics",
  "Pure Water Packaging Bags",
  "Cans",
];

const CalculateTransaction = (props) => {
  const location = useLocation();
  // console.log(location);
  let pricing = location?.state?.pricingObject;
  const [selectedMethod, setSelectedMethod] = useState("");
  const initiateDetails = JSON.parse(localStorage.getItem("initiate"));
  const [loading, setLoading] = useState(0);

  const [cleanSortedKg, setCleanSortedKg] = useState("");
  const [cleanSortedAmount, setCleanSortedAmount] = useState(null);
  const [cleanSortedDeduction, setCleanSortedDeduction] = useState("");

  const [cleanUnsortedKg, setCleanUnsortedKg] = useState("");
  const [cleanUnsortedAmount, setCleanUnsortedAmount] = useState(null);
  const [cleanUnsortedDeduction, setCleanUnsortedDeduction] = useState("");

  const [mixedPlasticsKg, setMixedPlasticsKg] = useState("");
  const [mixedPlasticsAmount, setMixedPlasticsAmount] = useState(null);
  const [mixedPlasticsDeduction, setMixedPlasticsDeduction] = useState("");

  const [pureWaterKg, setPureWaterKg] = useState("");
  const [pureWaterAmount, setPureWaterAmount] = useState(null);
  const [pureWaterDeduction, setPureWaterDeduction] = useState("");

  const [singleColorKg, setSingleColorKg] = useState("");
  const [singleColorAmount, setSingleColorAmount] = useState(null);
  const [singleColorDeduction, setSingleColorDeduction] = useState("");

  const [hardPackagingKg, setHardPackagingKg] = useState("");
  const [hardPackagingAmount, setHardPackagingAmount] = useState(null);
  const [hardPackagingDeduction, setHardPackagingDeduction] = useState("");

  const [hdpeKg, setHdpeKg] = useState("");
  const [hdpeAmount, setHdpeAmount] = useState(null);
  const [hdpeDeduction, setHdpeDeduction] = useState("");

  const [ppKg, setPpKg] = useState("");
  const [ppAmount, setPpAmount] = useState(null);
  const [ppDeduction, setPpDeduction] = useState("");

  const [packagingBagKg, setPackagingBagKg] = useState("");
  const [packagingBagAmount, setPackagingBagAmount] = useState(null);
  const [packagingBagDeduction, setPackagingBagDeduction] = useState("");

  const [cansKg, setCansKg] = useState("");
  const [cansAmount, setCansAmount] = useState(null);
  const [cansDeduction, setCansDeduction] = useState("");

  const calculateSortedVal = (val) => {
    if (cleanSortedDeduction !== "") {
      let price =
        getPricing(pricing, "clean_sorted", cleanSortedKg, "pickup") *
        cleanSortedKg;
      const ded = val / 100;
      const df = ded * cleanSortedAmount;
      setDeduction1Val(df);
      setCleanSortedAmount(price - df);
    } else {
      let price =
        getPricing(pricing, "clean_sorted", cleanSortedKg, "pickup") *
        cleanSortedKg;
      const ded = 0 / 100;
      const df = ded * cleanSortedAmount;
      setDeduction1Val(df);
      setCleanSortedAmount(price - df);
    }
  };

  useEffect(() => {
    calculateSortedVal(cleanSortedDeduction);
  }, [cleanSortedDeduction]);

  const calculateUnsortedVal = (val) => {
    if (cleanUnsortedDeduction !== "") {
      let price =
        getPricing(pricing, "clean_unsorted", cleanUnsortedKg, "pickup") *
        cleanUnsortedKg;
      console.log(price);
      const ded = val / 100;
      const df = ded * cleanUnsortedAmount;
      setDeduction2Val(df);
      setCleanUnsortedAmount(price - df);
    } else {
      let price =
        getPricing(pricing, "clean_unsorted", cleanUnsortedKg, "pickup") *
        cleanUnsortedKg;
      const ded = 0 / 100;
      const df = ded * cleanUnsortedAmount;
      setDeduction2Val(df);
      setCleanUnsortedAmount(price - df);
    }
  };

  useEffect(() => {
    calculateUnsortedVal(cleanUnsortedDeduction);
  }, [cleanUnsortedDeduction]);

  const calculateMixedPlasticsVal = (val) => {
    if (mixedPlasticsDeduction !== "") {
      let price =
        getPricing(pricing, "mixed_plastics", mixedPlasticsKg, "pickup") *
        mixedPlasticsKg;
      const ded = val / 100;
      const df = ded * mixedPlasticsAmount;
      setDeduction3Val(df);
      setMixedPlasticsAmount(price - df);
    } else {
      let price =
        getPricing(pricing, "mixed_plastics", mixedPlasticsKg, "pickup") *
        mixedPlasticsKg;
      const ded = 0 / 100;
      const df = ded * mixedPlasticsAmount;
      setDeduction3Val(df);
      setMixedPlasticsAmount(price - df);
    }
  };

  useEffect(() => {
    calculateMixedPlasticsVal(mixedPlasticsDeduction);
  }, [mixedPlasticsDeduction]);

  const pureWaterVal = (val) => {
    if (pureWaterDeduction !== "") {
      let price =
        getPricing(pricing, "pure_water_sachet_dry", pureWaterKg, "pickup") *
        pureWaterKg;
      const ded = val / 100;
      const df = ded * pureWaterAmount;
      setDeduction4Val(df);
      setPureWaterAmount(price - df);
    } else {
      let price =
        getPricing(pricing, "pure_water_sachet_dry", pureWaterKg, "pickup") *
        pureWaterKg;
      const ded = 0 / 100;
      const df = ded * pureWaterAmount;
      setDeduction4Val(df);
      setPureWaterAmount(price - df);
    }
  };

  useEffect(() => {
    pureWaterVal(pureWaterDeduction);
  }, [pureWaterDeduction]);

  const singleColorVal = (val) => {
    if (singleColorDeduction !== "") {
      let price =
        getPricing(pricing, "single_colour_caps", singleColorKg, "pickup") *
        singleColorKg;
      const ded = val / 100;
      const df = ded * singleColorAmount;
      setDeduction5Val(df);
      setSingleColorAmount(price - df);
    } else {
      let price =
        getPricing(pricing, "single_colour_caps", singleColorKg, "pickup") *
        singleColorKg;
      const ded = 0 / 100;
      const df = ded * singleColorAmount;
      setDeduction5Val(df);
      setSingleColorAmount(price - df);
    }
  };

  useEffect(() => {
    singleColorVal(singleColorDeduction);
  }, [singleColorDeduction]);

  const hardPackagingVal = (val) => {
    if (hardPackagingDeduction !== "") {
      let price =
        getPricing(
          pricing,
          "hard_packing_bags_dry",
          hardPackagingKg,
          "pickup"
        ) * hardPackagingKg;
      const ded = val / 100;
      const df = ded * hardPackagingAmount;
      setDeduction6Val(df);
      setHardPackagingAmount(price - df);
    } else {
      let price =
        getPricing(
          pricing,
          "hard_packing_bags_dry",
          hardPackagingKg,
          "pickup"
        ) * hardPackagingKg;
      const ded = 0 / 100;
      const df = ded * hardPackagingAmount;
      setDeduction6Val(df);
      setHardPackagingAmount(price - df);
    }
  };

  useEffect(() => {
    hardPackagingVal(hardPackagingDeduction);
  }, [hardPackagingDeduction]);

  const hdpeVal = (val) => {
    if (hdpeDeduction !== "") {
      let price =
        getPricing(
          pricing,
          "hdpe_plastics_below_five_meters",
          hdpeKg,
          "pickup"
        ) * hdpeKg;
      const ded = val / 100;
      const df = ded * hdpeAmount;
      setDeduction7Val(df);
      setHdpeAmount(price - df);
    } else {
      let price =
        getPricing(
          pricing,
          "hdpe_plastics_below_five_meters",
          hdpeKg,
          "pickup"
        ) * hdpeKg;
      const ded = 0 / 100;
      const df = ded * hdpeAmount;
      setDeduction7Val(df);
      setHdpeAmount(price - df);
    }
  };

  useEffect(() => {
    hdpeVal(hdpeDeduction);
  }, [hdpeDeduction]);

  const ppVal = (val) => {
    if (ppDeduction !== "") {
      let price = getPricing(pricing, "pp_plastics", ppKg, "pickup") * ppKg;
      const ded = val / 100;
      const df = ded * ppAmount;
      setDeduction8Val(df);
      setPpAmount(price - df);
    } else {
      let price = getPricing(pricing, "pp_plastics", ppKg, "pickup") * ppKg;
      const ded = 0 / 100;
      const df = ded * ppAmount;
      setDeduction8Val(df);
      setPpAmount(price - df);
    }
  };

  useEffect(() => {
    ppVal(ppDeduction);
  }, [ppDeduction]);

  const packagingBagVal = (val) => {
    if (packagingBagDeduction !== "") {
      let price =
        getPricing(pricing, "packing_bags", packagingBagKg, "pickup") *
        packagingBagKg;
      const ded = val / 100;
      const df = ded * packagingBagAmount;
      setDeduction9Val(df);
      setPackagingBagAmount(price - df);
    } else {
      let price =
        getPricing(pricing, "packing_bags", packagingBagKg, "pickup") *
        packagingBagKg;
      const ded = 0 / 100;
      const df = ded * packagingBagAmount;
      setDeduction9Val(df);
      setPackagingBagAmount(price - df);
    }
  };

  useEffect(() => {
    packagingBagVal(packagingBagDeduction);
  }, [packagingBagDeduction]);

  const cansVal = (val) => {
    if (cansDeduction !== "") {
      let price = getPricing(pricing, "cans", cansKg, "pickup") * cansKg;
      const ded = val / 100;
      const df = ded * cansAmount;
      setDeduction10Val(df);
      setCansAmount(price - df);
    } else {
      let price = getPricing(pricing, "cans", cansKg, "pickup") * cansKg;
      const ded = 0 / 100;
      const df = ded * cansAmount;
      setDeduction10Val(df);
      setCansAmount(price - df);
    }
  };

  useEffect(() => {
    cansVal(cansDeduction);
  }, [cansDeduction]);

  // console.log(cleanSortedKg);

  useEffect(() => {
    setCleanSortedAmount(
      getPricing(pricing, "clean_sorted", cleanSortedKg, "pickup") *
        cleanSortedKg
    );
  }, [cleanSortedKg]);

  useEffect(() => {
    setCleanUnsortedAmount(
      getPricing(pricing, "clean_unsorted", cleanUnsortedKg, "pickup") *
        cleanUnsortedKg
    );
  }, [cleanUnsortedKg]);

  useEffect(() => {
    setMixedPlasticsAmount(
      getPricing(pricing, "mixed_plastics", mixedPlasticsKg, "pickup") *
        mixedPlasticsKg
    );
  }, [mixedPlasticsKg]);

  useEffect(() => {
    setPureWaterAmount(
      getPricing(pricing, "pure_water_sachet_dry", pureWaterKg, "pickup") *
        pureWaterKg
    );
  }, [pureWaterKg]);

  useEffect(() => {
    setSingleColorAmount(
      getPricing(pricing, "single_colour_caps", singleColorKg, "pickup") *
        singleColorKg
    );
  }, [singleColorKg]);

  useEffect(() => {
    setHardPackagingAmount(
      getPricing(pricing, "hard_packing_bags_dry", hardPackagingKg, "pickup") *
        hardPackagingKg
    );
  }, [hardPackagingKg]);

  useEffect(() => {
    setHdpeAmount(
      getPricing(pricing, "hdpe_plastics_below_five_meters", hdpeKg, "pickup") *
        hdpeKg
    );
  }, [hdpeKg]);

  useEffect(() => {
    setPpAmount(getPricing(pricing, "pp_plastics", ppKg, "pickup") * ppKg);
  }, [ppKg]);

  useEffect(() => {
    setPackagingBagAmount(
      getPricing(pricing, "packing_bags", packagingBagKg, "pickup") *
        packagingBagKg
    );
  }, [packagingBagKg]);

  useEffect(() => {
    setCansAmount(getPricing(pricing, "cans", cansKg, "pickup") * cansKg);
  }, [cansKg]);

  const handleInputChangeCS = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setCleanSortedDeduction(value);
    }
  };

  const handleInputChangeCU = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setCleanUnsortedDeduction(value);
    }
  };

  const handleInputChangeMx = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setMixedPlasticsDeduction(value);
    }
  };

  const handleInputChangePw = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setPureWaterDeduction(value);
    }
  };

  const handleInputChangeSC = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setSingleColorDeduction(value);
    }
  };

  const handleInputChangeHP = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setHardPackagingDeduction(value);
    }
  };

  const handleInputChangeHD = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setHdpeDeduction(value);
    }
  };

  const handleInputChangePP = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setPpDeduction(value);
    }
  };

  const handleInputChangePB = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setPackagingBagDeduction(value);
    }
  };

  const handleInputChangeCans = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setCansDeduction(value);
    }
  };

  const [form, setForm] = useState({
    sorted: "",
    unsorted: "",
    mixed: "",
    pureWater: "",
    singleColor: "",
    hardPackaging: "",
    hdpe: "",
    pp: "",
    packagingBag: "",
    cans: "",
    deductSorted: "",
    deductUnsorted: "",
    deductMixed: "",
    deductSinglePureWater: "",
    deductSingleColor: "",
    deductHardPackaging: "",
    deductHdpe: "",
    deductPp: "",
    deductPackagingBag: "",
    deductCans: "",
  });

  const handleFormChange =
    (name) =>
    ({ target }) => {
      if (/^(\d*(\.\d{0,2})?)?$/.test(target.value)) {
        setForm({
          ...form,
          [name]: target.value,
        });
      }
    };

  const [showSorted, setShowSorted] = useState(false);
  const handleCloseSorted = () => setShowSorted(false);
  const handleShowSorted = () => setShowSorted(true);

  const [showUnsorted, setShowUnsorted] = useState(false);
  const handleCloseUnsorted = () => setShowUnsorted(false);
  const handleShowUnsorted = () => setShowUnsorted(true);

  const [showMixed, setShowMixed] = useState(false);
  const handleCloseMixed = () => setShowMixed(false);
  const handleShowMixed = () => setShowMixed(true);

  const [showPureWater, setShowPureWater] = useState(false);
  const handleClosePureWater = () => setShowPureWater(false);
  const handleShowPureWater = () => setShowPureWater(true);

  const [showSingleColor, setShowSingleColor] = useState(false);
  const handleCloseSingleColor = () => setShowSingleColor(false);
  const handleShowSingleColor = () => setShowSingleColor(true);

  const [showHardPackaging, setShowHardPackaging] = useState(false);
  const handleCloseHardPackaging = () => setShowHardPackaging(false);
  const handleShowHardPackaging = () => setShowHardPackaging(true);

  const [showhdpe, setShowhdpe] = useState(false);
  const handleClosehdpe = () => setShowhdpe(false);
  const handleShowhdpe = () => setShowhdpe(true);

  const [showPp, setShowPp] = useState(false);
  const handleClosePp = () => setShowPp(false);
  const handleShowPp = () => setShowPp(true);

  const [showPackagingBag, setShowPackagingBag] = useState(false);
  const handleClosePackagingBag = () => setShowPackagingBag(false);
  const handleShowPackagingBag = () => setShowPackagingBag(true);

  const [showCans, setShowCans] = useState(false);
  const handleCloseCans = () => setShowCans(false);
  const handleShowCans = () => setShowCans(true);

  const [sortedValue, setSortedValue] = useState(0);
  const [unsortedValue, setUnsortedValue] = useState(0);
  const [mixedValue, setMixedValue] = useState(0);
  const [pureWaterValue, setPureWaterValue] = useState(0);
  const [singleColorValue, setSingleColorValue] = useState(0);
  const [hardPackagingValue, setHardPackagingValue] = useState(0);
  const [hdpeValue, setHdpeValue] = useState(0);
  const [ppValue, setPpValue] = useState(0);
  const [packagingBagValue, setPackagingBagValue] = useState(0);
  const [cansValue, setCansValue] = useState(0);
  const [finalValue, setFinalValue] = useState(0);
  const [deduction1Val, setDeduction1Val] = useState(0);
  const [deduction2Val, setDeduction2Val] = useState(0);
  const [deduction3Val, setDeduction3Val] = useState(0);
  const [deduction4Val, setDeduction4Val] = useState(0);
  const [deduction5Val, setDeduction5Val] = useState(0);
  const [deduction6Val, setDeduction6Val] = useState(0);
  const [deduction7Val, setDeduction7Val] = useState(0);
  const [deduction8Val, setDeduction8Val] = useState(0);
  const [deduction9Val, setDeduction9Val] = useState(0);
  const [deduction10Val, setDeduction10Val] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState("");

  // start of new implementation
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleCheckboxChange = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const handleInputClick = (event) => {
    // Prevent the click on the input from triggering the handleClickOutside event
    event.stopPropagation();
    setIsDropdownOpen((prev) => !prev);
  };

  const placeholderText = selectedCategories.length
    ? `${selectedCategories.join(", ")}`
    : "Select plastic categories";

  // Click outside handler
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //end of new implementation

  const calculateSorted = (val) => {
    let conv = val;
    if (form.sorted > 0 && form.sorted < 100) {
      let calc1 = conv * PICK_UP_CLEAN_SORTED_BELOW_100;
      const ded = (form.deductSorted / 100) * calc1;
      setDeduction1Val(ded);
      setSortedValue(calc1 - ded);
    }
    if (form.sorted >= 100 && form.sorted < 200) {
      let calc1 = conv * PICK_UP_CLEAN_SORTED_ABOVE_100;
      const ded = (form.deductSorted / 100) * calc1;
      setDeduction1Val(ded);
      setSortedValue(calc1 - ded);
    }
    if (form.sorted >= 200) {
      let calc1 = conv * PICK_UP_CLEAN_SORTED_ABOVE_200;
      const ded = (form.deductSorted / 100) * calc1;
      setDeduction1Val(ded);
      setSortedValue(calc1 - ded);
    }
  };

  useEffect(() => {
    calculateSorted(form.sorted || 0);
  }, [form.deductSorted, form.sorted]);

  const calculateUnsorted = (val) => {
    let conv = val;
    if (form.unsorted > 0 && form.unsorted < 100) {
      let calc2 = conv * PICK_UP_CLEAN_UNSORTED_BELOW_100;
      const ded = (form.deductUnsorted / 100) * calc2;
      setDeduction2Val(ded);
      setUnsortedValue(calc2 - ded);
    }
    if (form.unsorted >= 100 && form.unsorted < 200) {
      let calc2 = conv * PICK_UP_CLEAN_UNSORTED_ABOVE_100;
      const ded = (form.deductUnsorted / 100) * calc2;
      setDeduction2Val(ded);
      setUnsortedValue(calc2 - ded);
    }
    if (form.unsorted >= 200) {
      let calc2 = conv * PICK_UP_CLEAN_UNSORTED_ABOVE_200;
      const ded = (form.deductUnsorted / 100) * calc2;
      setDeduction2Val(ded);
      setUnsortedValue(calc2 - ded);
    }
  };

  useEffect(() => {
    calculateUnsorted(form.unsorted || 0);
  }, [form.deductUnsorted, form.unsorted]);

  const calculateMixed = (val) => {
    let conv = val;
    if (form.mixed > 0 && form.mixed < 100) {
      let calc3 = conv * PICK_UP_MIXED_PLASTICS_BELOW_100;
      const ded = (form.deductMixed / 100) * calc3;
      setDeduction3Val(ded);
      setMixedValue(calc3 - ded);
    }
    if (form.mixed >= 100 && form.mixed < 200) {
      let calc3 = conv * PICK_UP_MIXED_PLASTICS_ABOVE_100;
      const ded = (form.deductMixed / 100) * calc3;
      setDeduction3Val(ded);
      setMixedValue(calc3 - ded);
    }
    if (form.mixed >= 200) {
      let calc3 = conv * PICK_UP_MIXED_PLASTICS_ABOVE_200;
      const ded = (form.deductMixed / 100) * calc3;
      setDeduction3Val(ded);
      setMixedValue(calc3 - ded);
    }
  };

  useEffect(() => {
    calculateMixed(form.mixed || 0);
  }, [form.deductMixed, form.mixed]);

  const calculatePureWater = (val) => {
    let conv = val;
    if (form.pureWater > 0 && form.pureWater < 100) {
      var calc4 = conv * PICK_UP_PURE_WATER_BELOW_100;
      const ded = (form.deductSinglePureWater / 100) * calc4;
      setDeduction4Val(ded);
      setPureWaterValue(calc4 - ded);
    }
    if (form.pureWater >= 100 && form.pureWater < 200) {
      var calc4 = conv * PICK_UP_PURE_WATER_ABOVE_100;
      const ded = (form.deductSinglePureWater / 100) * calc4;
      setDeduction4Val(ded);
      setPureWaterValue(calc4 - ded);
    }
    if (form.pureWater >= 200) {
      var calc4 = conv * PICK_UP_PURE_WATER_ABOVE_200;
      const ded = (form.deductSinglePureWater / 100) * calc4;
      setDeduction4Val(ded);
      setPureWaterValue(calc4 - ded);
    }
  };

  useEffect(() => {
    calculatePureWater(form.pureWater || 0);
  }, [
    form.deductSinglePureWater,
    form.pureWater,
    form.deductSinglePureWater > 0,
  ]);

  const calculateSingleColor = (val) => {
    let conv = val;
    if (form.singleColor > 0 && form.singleColor < 100) {
      var calc5 = conv * PICK_UP_SINGLE_COLOR_BELOW_100;
      const ded = (form.deductSingleColor / 100) * calc5;
      setDeduction5Val(ded);
      setSingleColorValue(calc5 - ded);
    }
    if (form.singleColor >= 100 && form.singleColor < 200) {
      var calc5 = conv * PICK_UP_SINGLE_COLOR_ABOVE_100;
      const ded = (form.deductSingleColor / 100) * calc5;
      setDeduction5Val(ded);
      setSingleColorValue(calc5 - ded);
    }
    if (form.singleColor >= 200) {
      var calc5 = conv * PICK_UP_SINGLE_COLOR_ABOVE_200;
      const ded = (form.deductSingleColor / 100) * calc5;
      setDeduction5Val(ded);
      setSingleColorValue(calc5 - ded);
    }
  };

  useEffect(() => {
    calculateSingleColor(form.singleColor || 0);
  }, [form.deductSingleColor, form.singleColor, form.deductSingleColor > 0]);

  const calculateHardPackaging = (val) => {
    let conv = val;
    if (form.hardPackaging > 0 && form.hardPackaging < 100) {
      var calc6 = conv * PICK_UP_HARD_PACKAGING_BELOW_100;
      const ded = (form.deductHardPackaging / 100) * calc6;
      setDeduction6Val(ded);
      setHardPackagingValue(calc6 - ded);
    }
    if (form.hardPackaging >= 100 && form.hardPackaging < 200) {
      var calc6 = conv * PICK_UP_HARD_PACKAGING_ABOVE_100;
      const ded = (form.deductHardPackaging / 100) * calc6;
      setDeduction6Val(ded);
      setHardPackagingValue(calc6 - ded);
    }
    if (form.hardPackaging >= 200) {
      var calc6 = conv * PICK_UP_HARD_PACKAGING_ABOVE_200;
      const ded = (form.deductHardPackaging / 100) * calc6;
      setDeduction6Val(ded);
      setHardPackagingValue(calc6 - ded);
    }
  };

  useEffect(() => {
    calculateHardPackaging(form.hardPackaging || 0);
  }, [
    form.deductHardPackaging,
    form.hardPackaging,
    form.deductHardPackaging > 0,
  ]);

  const calculateHdpe = (val) => {
    let conv = val;
    if (form.hdpe > 0 && form.hdpe < 100) {
      var calc7 = conv * PICK_UP_HDPE_BELOW_100;
      const ded = (form.deductHdpe / 100) * calc7;
      setDeduction7Val(ded);
      setHdpeValue(calc7 - ded);
    }
    if (form.hdpe >= 100 && form.hdpe < 200) {
      var calc7 = conv * PICK_UP_HDPE_ABOVE_100;
      const ded = (form.deductHdpe / 100) * calc7;
      setDeduction7Val(ded);
      setHdpeValue(calc7 - ded);
    }
    if (form.hdpe >= 200) {
      var calc7 = conv * PICK_UP_HDPE_ABOVE_200;
      const ded = (form.deductHdpe / 100) * calc7;
      setDeduction7Val(ded);
      setHdpeValue(calc7 - ded);
    }
  };

  useEffect(() => {
    calculateHdpe(form.hdpe || 0);
  }, [form.deductHdpe, form.hdpe, form.deductHdpe > 0]);

  const calculatePp = (val) => {
    let conv = val;
    if (form.pp > 0 && form.pp < 100) {
      var calc8 = conv * PICK_UP_PP_BELOW_100;
      const ded = (form.deductPp / 100) * calc8;
      setDeduction8Val(ded);
      setPpValue(calc8 - ded);
    }
    if (form.pp >= 100 && form.pp < 200) {
      var calc8 = conv * PICK_UP_PP_ABOVE_100;
      const ded = (form.deductPp / 100) * calc8;
      setDeduction8Val(ded);
      setPpValue(calc8 - ded);
    }
    if (form.pp >= 200) {
      var calc8 = conv * PICK_UP_PP_ABOVE_200;
      const ded = (form.deductPp / 100) * calc8;
      setDeduction8Val(ded);
      setPpValue(calc8 - ded);
    }
  };

  useEffect(() => {
    calculatePp(form.pp || 0);
  }, [form.deductPp, form.pp, form.deductPp > 0]);

  const calculatePackagingBag = (val) => {
    let conv = val;
    if (form.packagingBag > 0 && form.packagingBag < 100) {
      var calc9 = conv * PICK_UP_PACKAGING_BAGS_BELOW_100;
      const ded = (form.deductPackagingBag / 100) * calc9;
      setDeduction9Val(ded);
      setPackagingBagValue(calc9 - ded);
    }
    if (form.packagingBag >= 100 && form.packagingBag < 200) {
      var calc9 = conv * PICK_UP_PACKAGING_BAGS_ABOVE_100;
      const ded = (form.deductPackagingBag / 100) * calc9;
      setDeduction9Val(ded);
      setPackagingBagValue(calc9 - ded);
    }
    if (form.packagingBag >= 200) {
      var calc9 = conv * PICK_UP_PACKAGING_BAGS_ABOVE_200;
      const ded = (form.deductPackagingBag / 100) * calc9;
      setDeduction9Val(ded);
      setPackagingBagValue(calc9 - ded);
    }
  };

  useEffect(() => {
    calculatePackagingBag(form.packagingBag || 0);
  }, [form.deductPackagingBag, form.packagingBag, form.deductPackagingBag > 0]);

  const calculateCans = (val) => {
    if (form.cans > 0 && form.cans < 100) {
      let conv = val;
      var calc10 = conv * PICK_UP_CANS_BELOW_100;
      const ded = (form.deductCans / 100) * calc10;
      setDeduction10Val(ded);
      setCansValue(calc10 - ded);
    }
    if (form.cans >= 100 && form.cans < 200) {
      let conv = val;
      var calc10 = conv * PICK_UP_CANS_ABOVE_100;
      const ded = (form.deductCans / 100) * calc10;
      setDeduction10Val(ded);
      setCansValue(calc10 - ded);
    }
    if (form.cans >= 200) {
      let conv = val;
      var calc10 = conv * PICK_UP_CANS_ABOVE_200;
      const ded = (form.deductCans / 100) * calc10;
      setDeduction10Val(ded);
      setCansValue(calc10 - ded);
    }
  };

  useEffect(() => {
    calculateCans(form.cans || 0);
  }, [form.deductCans, form.cans, form.deductCans > 0]);

  const final = (
    val1,
    val2,
    val3,
    val4,
    val5,
    val6,
    val7,
    val8,
    val9,
    val10
  ) => {
    const total =
      Number(val1) +
      Number(val2) +
      Number(val3) +
      Number(val4) +
      Number(val5) +
      Number(val6) +
      Number(val7) +
      Number(val8) +
      Number(val9) +
      Number(val10);
    const dd = Math.round((total + Number.EPSILON) * 100) / 100;
    setFinalValue(dd);
  };

  const finalDeduction = (
    val1,
    val2,
    val3,
    val4,
    val5,
    val6,
    val7,
    val8,
    val9,
    val10
  ) => {
    const total =
      Number(val1) +
      Number(val2) +
      Number(val3) +
      Number(val4) +
      Number(val5) +
      Number(val6) +
      Number(val7) +
      Number(val8) +
      Number(val9) +
      Number(val10);
    const dd = Math.round((total + Number.EPSILON) * 100) / 100;
    setTotalDeduction(dd);
  };

  useEffect(() => {
    finalDeduction(
      deduction1Val,
      deduction2Val,
      deduction3Val,
      deduction4Val,
      deduction5Val,
      deduction6Val,
      deduction7Val,
      deduction8Val,
      deduction9Val,
      deduction10Val
    );
  }, [
    deduction1Val,
    deduction2Val,
    deduction3Val,
    deduction4Val,
    deduction5Val,
    deduction6Val,
    deduction7Val,
    deduction8Val,
    deduction9Val,
    deduction10Val,
  ]);

  useEffect(() => {
    final(
      cleanSortedAmount,
      cleanUnsortedAmount,
      mixedPlasticsAmount,
      pureWaterAmount,
      singleColorAmount,
      hardPackagingAmount,
      hdpeAmount,
      ppAmount,
      packagingBagAmount,
      cansAmount
    );
  }, [
    cleanSortedAmount,
    cleanUnsortedAmount,
    mixedPlasticsAmount,
    pureWaterAmount,
    singleColorAmount,
    hardPackagingAmount,
    hdpeAmount,
    ppAmount,
    packagingBagAmount,
    cansAmount,
  ]);

  const initiatePickUp = () => {
    if (selectedMethod === "") {
      toast.error("Kindly select a payment method", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else {
      setLoading(true);
      const token = localStorage.getItem("token");
      // const id = localStorage.getItem("id");
      let waste_category = [];

      if (cleanSortedKg) {
        waste_category.push({
          name: "clean_sorted",
          kg: Number(cleanSortedKg),
          deduction: cleanSortedDeduction ? Number(cleanSortedDeduction) : 0,
        });
      }

      if (cleanUnsortedKg) {
        waste_category.push({
          name: "clean_unsorted",
          kg: Number(cleanUnsortedKg),
          deduction: cleanUnsortedDeduction
            ? Number(cleanUnsortedDeduction)
            : 0,
        });
      }

      if (mixedPlasticsKg) {
        waste_category.push({
          name: "mixed_plastics",
          kg: Number(mixedPlasticsKg),
          deduction: mixedPlasticsDeduction
            ? Number(mixedPlasticsDeduction)
            : 0,
        });
      }

      if (pureWaterKg) {
        waste_category.push({
          name: "pure_water_sachet_dry",
          kg: Number(pureWaterKg),
          deduction: pureWaterDeduction ? Number(pureWaterDeduction) : 0,
        });
      }

      if (packagingBagKg) {
        waste_category.push({
          name: "packing_bags",
          kg: Number(packagingBagKg),
          deduction: packagingBagDeduction ? Number(packagingBagDeduction) : 0,
        });
      }

      if (singleColorKg) {
        waste_category.push({
          name: "single_colour_caps",
          kg: Number(singleColorKg),
          deduction: singleColorDeduction ? Number(singleColorDeduction) : 0,
        });
      }

      if (ppKg) {
        waste_category.push({
          name: "pp_plastics",
          kg: Number(ppKg),
          deduction: ppDeduction ? Number(ppDeduction) : 0,
        });
      }

      if (hardPackagingKg) {
        waste_category.push({
          name: "hard_packing_bags_dry",
          kg: Number(hardPackagingKg),
          deduction: hardPackagingDeduction
            ? Number(hardPackagingDeduction)
            : 0,
        });
      }

      if (hdpeKg) {
        waste_category.push({
          name: "hdpe_plastics_below_five_meters",
          kg: Number(hdpeKg),
          deduction: hdpeDeduction ? Number(hdpeDeduction) : 0,
        });
      }

      if (cansKg) {
        waste_category.push({
          name: "cans",
          kg: Number(cansKg),
          deduction: cansDeduction ? Number(cansDeduction) : 0,
        });
      }

      const payload = {
        waste_category: waste_category,
        pickup_id: initiateDetails?.id,
        payment_method: selectedMethod,
        request_type: "pickup",
      };
      axios
        .post(`${switchEndpoint}/driver/start-pickup-transaction`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          // console.log(response.data);
          localStorage.setItem("pickUp", JSON.stringify(response.data));
          setLoading(false);
          props.history.push("/drivers/awaitConfirmation");
          setForm({
            sorted: "",
            unsorted: "",
            mixed: "",
            pureWater: "",
            singleColor: "",
            hardPackaging: "",
            hdpe: "",
            pp: "",
            cans: "",
            deductSorted: "",
            deductUnsorted: "",
            deductMixed: "",
            deductSinglePureWater: "",
            deductSingleColor: "",
            deductHardPackaging: "",
            deductHdpe: "",
            deductPp: "",
            deductCans: "",
          });
          setSortedValue(0);
          setUnsortedValue(0);
          setMixedValue(0);
          setPureWaterValue(0);
          setSingleColorValue(0);
          setHardPackagingValue(0);
          setHdpeValue(0);
          setPpValue(0);
          setPackagingBagValue(0);
          setCansValue(0);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <DriversNavigation />
      <div className="mx-3">
        <div
          className="d-flex align-items-center my-3"
          style={{ width: "300px" }}
        >
          <img src={avatar} alt={avatar} style={{ width: "20%" }} />
          <div>
            <p
              className="mb-1 ml-3"
              style={{ fontSize: "14px", fontWeight: "400" }}
            >
              Household: {initiateDetails?.owner?.name}
            </p>
            <div className="d-flex align-items-center ml-3 mt-1">
              <div style={{ width: "30px" }}>
                <img src={phone} alt="phone" style={{ width: "100%" }} />
              </div>

              <p
                className="mb-0 ml-2"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                {initiateDetails?.owner?.phone}
              </p>
            </div>
            <div className="ml-2 mt-1">
              <p
                className="mb-0 ml-2"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#898989",
                }}
              >
                Pick Up Code
              </p>
              <p
                className="mb-0 ml-2"
                style={{ fontSize: "14px", fontWeight: "600" }}
              >
                {initiateDetails?.code}
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="form-group">
            <label for="exampleFormControlSelect1">Select Payment Method</label>
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              onChange={(e) => {
                setSelectedMethod(e.target.value);
                // console.log(e.target.value);

                localStorage.setItem("paymentMethods", e.target.value);
              }}
              value={selectedMethod}
            >
              <option value="">Select</option>
              <option value="transfer">Transfer To Switch Wallet</option>
              <option value="cash">Cash</option>
            </select>
          </div>
        </div>

        <div>
          <h6 className="py-3">Used Plastic Categories</h6>

          <div className="category-selector mb-3">
            <input
              type="text"
              className="form-control form-control-lg inputStyle"
              placeholder={placeholderText}
              onClick={handleInputClick}
              readOnly
            />

            {isDropdownOpen && (
              <div
                ref={dropdownRef}
                className="dropdown mt-2 w-100"
                onClick={(event) => event.stopPropagation()}
              >
                {categories.map((category, index) => (
                  <div key={category} className="dropdown-item">
                    <label className=" d-flex">
                      <input
                        type="checkbox"
                        id={`checkbox-${index}`} // Unique ID for each checkbox
                        checked={selectedCategories.includes(category)}
                        onChange={() => handleCheckboxChange(category)}
                      />
                      <p className="mb-0 pl-2">{category}</p>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>

          {selectedCategories.includes("Clean Sorted") && (
            <div className="cardss">
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-0"
                  style={{ fontWeight: "500", fontSize: "16px" }}
                >
                  Clean Sorted
                </p>
                <div>
                  <div className="">
                    <a href="#" style={{ color: "#231F20", fontSize: "14px" }}>
                      View Category Info
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="mb-3 mt-2">
                  <label className="mb-1 form-label">Clean Sorted</label>
                  <input
                    type="text"
                    className="form-control form-control-lg inputStyle"
                    placeholder="Enter weight in KG"
                    value={cleanSortedKg}
                    onChange={(e) => setCleanSortedKg(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-1 form-label">Amount in Naira</label>
                  <div
                    style={{ backgroundColor: "#F8FFF8", border: "none" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <input
                      type="text"
                      className="form-control form-control-lg inputStyle"
                      placeholder="N0.00"
                      style={{ backgroundColor: "#F8FFF8", border: "none" }}
                      value={`₦${
                        cleanSortedAmount === null ? 0 : cleanSortedAmount
                      }`}
                      disabled
                    />
                    {form.deductSorted ? (
                      <p className="mb-0 pr-3">{`-${form.deductSorted}%`}</p>
                    ) : (
                      <p className="mb-0 pr-3">{form.deductSorted}</p>
                    )}
                  </div>
                </div>
                <div className="text-center">
                  <div className="" onClick={handleShowSorted}>
                    <p style={{ color: "#231F20", fontSize: "14px" }}>
                      Noticed Impurities? Reduce price
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedCategories.includes("Clean Unsorted") && (
            <div className="cardss mt-4">
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-0"
                  style={{ fontWeight: "500", fontSize: "16px" }}
                >
                  Clean Unsorted
                </p>
                <div>
                  <div className="">
                    <a href="#" style={{ color: "#231F20", fontSize: "14px" }}>
                      View Category Info
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="mb-3 mt-2">
                  <label className="mb-1 form-label">Clean Unsorted</label>
                  <input
                    type="text"
                    className="form-control form-control-lg inputStyle"
                    placeholder="Enter weight in KG"
                    value={cleanUnsortedKg}
                    onChange={(e) => setCleanUnsortedKg(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-1 form-label">Amount in Naira</label>
                  <div
                    style={{ backgroundColor: "#F8FFF8", border: "none" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <input
                      type="text"
                      className="form-control form-control-lg inputStyle"
                      placeholder="N0.00"
                      style={{ backgroundColor: "#F8FFF8", border: "none" }}
                      value={`₦${
                        cleanUnsortedAmount === null ? 0 : cleanUnsortedAmount
                      }`}
                      disabled
                    />
                    {form.deductUnsorted ? (
                      <p className="mb-0 pr-3">{`-${form.deductUnsorted}%`}</p>
                    ) : (
                      <p className="mb-0 pr-3">{form.deductUnsorted}</p>
                    )}
                  </div>
                </div>
                <div className="text-center" onClick={handleShowUnsorted}>
                  <div className="">
                    <p style={{ color: "#231F20", fontSize: "14px" }}>
                      Noticed Impurities? Reduce price
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedCategories.includes("Mixed Plastics") && (
            <div className="cardss mt-4 mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-0"
                  style={{ fontWeight: "500", fontSize: "16px" }}
                >
                  Mixed Plastics
                </p>
                <div>
                  <div className="">
                    <a href="#" style={{ color: "#231F20", fontSize: "14px" }}>
                      View Category Info
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="mb-3 mt-2">
                  <label className="mb-1 form-label">Mixed Plastics</label>
                  <input
                    type="text"
                    className="form-control form-control-lg inputStyle"
                    placeholder="Enter weight in KG"
                    value={mixedPlasticsKg}
                    onChange={(e) => setMixedPlasticsKg(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-1 form-label">Amount in Naira</label>
                  <div
                    style={{ backgroundColor: "#F8FFF8", border: "none" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <input
                      type="text"
                      className="form-control form-control-lg inputStyle"
                      placeholder="N0.00"
                      style={{ backgroundColor: "#F8FFF8", border: "none" }}
                      value={`₦${
                        mixedPlasticsAmount === null ? 0 : mixedPlasticsAmount
                      }`}
                      disabled
                    />
                    {form.deductMixed ? (
                      <p className="mb-0 pr-3">{`-${form.deductMixed}%`}</p>
                    ) : (
                      <p className="mb-0 pr-3">{form.deductMixed}</p>
                    )}
                  </div>
                </div>
                <div className="text-center" onClick={handleShowMixed}>
                  <div className="">
                    <p style={{ color: "#231F20", fontSize: "14px" }}>
                      Noticed Impurities? Reduce price
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedCategories.includes("Pure Water Nylon") && (
            <div className="cardss mt-4 mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-0"
                  style={{ fontWeight: "500", fontSize: "16px" }}
                >
                  Pure Water Nylon
                </p>
                <div>
                  <div className="">
                    <a href="#" style={{ color: "#231F20", fontSize: "14px" }}>
                      View Category Info
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="mb-3 mt-2">
                  <label className="mb-1 form-label">Pure Water Nylon</label>
                  <input
                    type="text"
                    className="form-control form-control-lg inputStyle"
                    placeholder="Enter weight in KG"
                    value={pureWaterKg}
                    onChange={(e) => setPureWaterKg(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-1 form-label">Amount in Naira</label>
                  <div
                    style={{ backgroundColor: "#F8FFF8", border: "none" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <input
                      type="text"
                      className="form-control form-control-lg inputStyle"
                      placeholder="N0.00"
                      style={{ backgroundColor: "#F8FFF8", border: "none" }}
                      value={`₦${
                        pureWaterAmount === null ? 0 : pureWaterAmount
                      }`}
                      disabled
                    />
                    {form.deductSinglePureWater ? (
                      <p className="mb-0 pr-3">{`-${form.deductSinglePureWater}%`}</p>
                    ) : (
                      <p className="mb-0 pr-3">{form.deductSinglePureWater}</p>
                    )}
                  </div>
                </div>
                <div className="text-center" onClick={handleShowPureWater}>
                  <div className="">
                    <p style={{ color: "#231F20", fontSize: "14px" }}>
                      Noticed Impurities? Reduce price
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedCategories.includes("Single color caps") && (
            <div className="cardss mt-4">
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-0"
                  style={{ fontWeight: "500", fontSize: "16px" }}
                >
                  Single color caps
                </p>
                <div>
                  <div className="">
                    <a href="#" style={{ color: "#231F20", fontSize: "14px" }}>
                      View Category Info
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="mb-3 mt-2">
                  <label className="mb-1 form-label"> Single color caps</label>
                  <input
                    type="text"
                    className="form-control form-control-lg inputStyle"
                    placeholder="Enter weight in KG"
                    value={singleColorKg}
                    onChange={(e) => setSingleColorKg(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-1 form-label">Amount in Naira</label>
                  <div
                    style={{ backgroundColor: "#F8FFF8", border: "none" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <input
                      type="text"
                      className="form-control form-control-lg inputStyle"
                      placeholder="N0.00"
                      style={{ backgroundColor: "#F8FFF8", border: "none" }}
                      value={`₦${
                        singleColorAmount === null ? 0 : singleColorAmount
                      }`}
                      disabled
                    />
                    {form.deductSingleColor ? (
                      <p className="mb-0 pr-3">{`-${form.deductSingleColor}%`}</p>
                    ) : (
                      <p className="mb-0 pr-3">{form.deductSingleColor}</p>
                    )}
                  </div>
                </div>
                <div className="text-center" onClick={handleShowSingleColor}>
                  <div className="">
                    <p style={{ color: "#231F20", fontSize: "14px" }}>
                      Noticed Impurities? Reduce price
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedCategories.includes("Hard packing bags (dry)") && (
            <div className="cardss mt-4 mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-0"
                  style={{ fontWeight: "500", fontSize: "16px" }}
                >
                  Hard packing bags (dry)
                </p>
                <div>
                  <div className="">
                    <a href="#" style={{ color: "#231F20", fontSize: "14px" }}>
                      View Category Info
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="mb-3 mt-2">
                  <label className="mb-1 form-label">
                    Hard packing bags (dry)
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg inputStyle"
                    placeholder="Enter weight in KG"
                    value={hardPackagingKg}
                    onChange={(e) => setHardPackagingKg(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-1 form-label">Amount in Naira</label>
                  <div
                    style={{ backgroundColor: "#F8FFF8", border: "none" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <input
                      type="text"
                      className="form-control form-control-lg inputStyle"
                      placeholder="N0.00"
                      style={{ backgroundColor: "#F8FFF8", border: "none" }}
                      value={`₦${
                        hardPackagingAmount === null ? 0 : hardPackagingAmount
                      }`}
                      disabled
                    />
                    {form.deductHardPackaging ? (
                      <p className="mb-0 pr-3">{`-${form.deductHardPackaging}%`}</p>
                    ) : (
                      <p className="mb-0 pr-3">{form.deductHardPackaging}</p>
                    )}
                  </div>
                </div>
                <div className="text-center" onClick={handleShowHardPackaging}>
                  <div className="">
                    <p style={{ color: "#231F20", fontSize: "14px" }}>
                      Noticed Impurities? Reduce price
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedCategories.includes("HDPE plastics below 5 Litres") && (
            <div className="cardss mt-4 mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-0"
                  style={{ fontWeight: "500", fontSize: "16px" }}
                >
                  HDPE plastics below 5 Litres
                </p>
                <div>
                  {/* <div className="">
                  <a href="#" style={{ color: "#231F20", fontSize: "14px" }}>
                    View Category Info
                    <span
                      style={{ color: "#c4da00", fontSize: "12px" }}
                      className="ml-1"
                    >
                      &#62;
                    </span>
                  </a>
                </div> */}
                </div>
              </div>
              <div>
                <div className="mb-3 mt-2">
                  <label className="mb-1 form-label">
                    HDPE plastics below 5 metres
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg inputStyle"
                    placeholder="Enter weight in KG"
                    value={hdpeKg}
                    onChange={(e) => setHdpeKg(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-1 form-label">Amount in Naira</label>
                  <div
                    style={{ backgroundColor: "#F8FFF8", border: "none" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <input
                      type="text"
                      className="form-control form-control-lg inputStyle"
                      placeholder="N0.00"
                      style={{ backgroundColor: "#F8FFF8", border: "none" }}
                      value={`₦${hdpeAmount === null ? 0 : hdpeAmount}`}
                      disabled
                    />
                    {form.deductHdpe ? (
                      <p className="mb-0 pr-3">{`-${form.deductHdpe}%`}</p>
                    ) : (
                      <p className="mb-0 pr-3">{form.deductHdpe}</p>
                    )}
                  </div>
                </div>
                <div className="text-center" onClick={handleShowhdpe}>
                  <div className="">
                    <p style={{ color: "#231F20", fontSize: "14px" }}>
                      Noticed Impurities? Reduce price
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedCategories.includes("PP Plastics") && (
            <div className="cardss mt-4 mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-0"
                  style={{ fontWeight: "500", fontSize: "16px" }}
                >
                  PP Plastics
                </p>
                <div>
                  <div className="">
                    <a href="#" style={{ color: "#231F20", fontSize: "14px" }}>
                      View Category Info
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="mb-3 mt-2">
                  <label className="mb-1 form-label">PP Plastics</label>
                  <input
                    type="text"
                    className="form-control form-control-lg inputStyle"
                    placeholder="Enter weight in KG"
                    value={ppKg}
                    onChange={(e) => setPpKg(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-1 form-label">Amount in Naira</label>
                  <div
                    style={{ backgroundColor: "#F8FFF8", border: "none" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <input
                      type="text"
                      className="form-control form-control-lg inputStyle"
                      placeholder="N0.00"
                      style={{ backgroundColor: "#F8FFF8", border: "none" }}
                      value={`₦${ppAmount === null ? 0 : ppAmount}`}
                      disabled
                    />
                    {form.deductPp ? (
                      <p className="mb-0 pr-3">{`-${form.deductPp}%`}</p>
                    ) : (
                      <p className="mb-0 pr-3">{form.deductPp}</p>
                    )}
                  </div>
                </div>
                <div className="text-center" onClick={handleShowPp}>
                  <div className="">
                    <p style={{ color: "#231F20", fontSize: "14px" }}>
                      Noticed Impurities? Reduce price
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedCategories.includes("Pure Water Packaging Bags") && (
            <div className="cardss mt-4 mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-0"
                  style={{ fontWeight: "500", fontSize: "16px" }}
                >
                  Pure Water Packaging Bags
                </p>
                <div>
                  <div className="">
                    <a href="#" style={{ color: "#231F20", fontSize: "14px" }}>
                      View Category Info
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="mb-3 mt-2">
                  <label className="mb-1 form-label">Packaging Bags</label>
                  <input
                    type="text"
                    className="form-control form-control-lg inputStyle"
                    placeholder="Enter weight in KG"
                    value={packagingBagKg}
                    onChange={(e) => setPackagingBagKg(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-1 form-label">Amount in Naira</label>
                  <div
                    style={{ backgroundColor: "#F8FFF8", border: "none" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <input
                      type="text"
                      className="form-control form-control-lg inputStyle"
                      placeholder="N0.00"
                      style={{ backgroundColor: "#F8FFF8", border: "none" }}
                      value={`₦${
                        packagingBagAmount === null ? 0 : packagingBagAmount
                      }`}
                      disabled
                    />
                    {form.deductPackagingBag ? (
                      <p className="mb-0 pr-3">{`-${form.deductPackagingBag}%`}</p>
                    ) : (
                      <p className="mb-0 pr-3">{form.deductPackagingBag}</p>
                    )}
                  </div>
                </div>
                <div className="text-center" onClick={handleShowPackagingBag}>
                  <div className="">
                    <p style={{ color: "#231F20", fontSize: "14px" }}>
                      Noticed Impurities? Reduce price
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedCategories.includes("Cans") && (
            <div className="cardss mt-4 mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-0"
                  style={{ fontWeight: "500", fontSize: "16px" }}
                >
                  Cans
                </p>
                <div>
                  <div className="">
                    <a href="#" style={{ color: "#231F20", fontSize: "14px" }}>
                      View Category Info
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="mb-3 mt-2">
                  <label className="mb-1 form-label">Cans</label>
                  <input
                    type="text"
                    className="form-control form-control-lg inputStyle"
                    placeholder="Enter weight in KG"
                    value={cansKg}
                    onChange={(e) => setCansKg(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-1 form-label">Amount in Naira</label>
                  <div
                    style={{ backgroundColor: "#F8FFF8", border: "none" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <input
                      type="text"
                      className="form-control form-control-lg inputStyle"
                      placeholder="N0.00"
                      style={{ backgroundColor: "#F8FFF8", border: "none" }}
                      value={`₦${cansAmount === null ? 0 : cansAmount}`}
                      disabled
                    />
                    {form.deductCans ? (
                      <p className="mb-0 pr-3">{`-${form.deductCans}%`}</p>
                    ) : (
                      <p className="mb-0 pr-3">{form.deductCans}</p>
                    )}
                  </div>
                </div>
                <div className="text-center" onClick={handleShowCans}>
                  <div className="">
                    <p style={{ color: "#231F20", fontSize: "14px" }}>
                      Noticed Impurities? Reduce price
                      <span
                        style={{ color: "#c4da00", fontSize: "12px" }}
                        className="ml-1"
                      >
                        &#62;
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="pb-2">
            <div className="mb-3">
              <label className="mb-1 form-label">Deductions</label>
              <input
                type="text"
                className="form-control form-control-lg inputStyle bg-dark text-white"
                placeholder="N0.00"
                value={`₦${
                  Math.round((totalDeduction + Number.EPSILON) * 100) / 100
                }`}
                disabled
              />
            </div>
          </div>

          <div className="pb-2">
            <div className="mb-3">
              <label className="mb-1 form-label">Sum Total</label>
              <input
                type="text"
                className="form-control form-control-lg inputStyle bg-dark text-white"
                placeholder="N0.00"
                value={`₦${
                  Math.round((finalValue + Number.EPSILON) * 100) / 100
                }`}
                disabled
              />
            </div>
          </div>
          <div className="pb-5">
            <button
              type="button"
              className="btn loginBtn btn-sm btn-block"
              onClick={initiatePickUp}
              disabled={loading || selectedCategories.length <= 0}
            >
              {loading ? (
                <span
                  style={{
                    color: "white",
                    marginLeft: "2px",
                    marginTop: "1px",
                  }}
                  className="text-center"
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </span>
              ) : (
                "Initiate Payment"
              )}
            </button>
          </div>
        </div>
      </div>

      <SortedeModal
        showSorted={showSorted}
        handleCloseSorted={handleCloseSorted}
        form={form}
        handleFormChange={handleFormChange}
        cleanSortedDeduction={cleanSortedDeduction}
        cleanSortedKg={cleanSortedKg}
        handleInputChangeCS={handleInputChangeCS}
      />

      <UnsortedeModal
        showUnsorted={showUnsorted}
        handleCloseUnsorted={handleCloseUnsorted}
        form={form}
        handleFormChange={handleFormChange}
        unsortedValue={unsortedValue}
        cleanUnsortedDeduction={cleanUnsortedDeduction}
        cleanUnsortedKg={cleanUnsortedKg}
        handleInputChangeCU={handleInputChangeCU}
      />

      <MixedModal
        showMixed={showMixed}
        handleCloseMixed={handleCloseMixed}
        form={form}
        handleFormChange={handleFormChange}
        mixedValue={mixedValue}
        mixedPlasticsDeduction={mixedPlasticsDeduction}
        mixedPlasticsKg={mixedPlasticsKg}
        handleInputChangeMx={handleInputChangeMx}
      />

      <SingleColorModal
        showSingleColor={showSingleColor}
        handleCloseSingleColor={handleCloseSingleColor}
        form={form}
        handleFormChange={handleFormChange}
        singleColorValue={singleColorValue}
        singleColorDeduction={singleColorDeduction}
        singleColorKg={singleColorKg}
        handleInputChangeSC={handleInputChangeSC}
      />

      <PureWaterModal
        showPureWater={showPureWater}
        handleClosePureWater={handleClosePureWater}
        pureWaterValue={pureWaterValue}
        form={form}
        handleFormChange={handleFormChange}
        pureWaterDeduction={pureWaterDeduction}
        pureWaterKg={pureWaterKg}
        handleInputChangePw={handleInputChangePw}
      />

      <HardPackagingModal
        form={form}
        handleFormChange={handleFormChange}
        hardPackagingValue={hardPackagingValue}
        showHardPackaging={showHardPackaging}
        handleCloseHardPackaging={handleCloseHardPackaging}
        hardPackagingDeduction={hardPackagingDeduction}
        hardPackagingKg={hardPackagingKg}
        handleInputChangeHP={handleInputChangeHP}
      />

      <HdpeModal
        showhdpe={showhdpe}
        handleClosehdpe={handleClosehdpe}
        form={form}
        handleFormChange={handleFormChange}
        hdpeValue={hdpeValue}
        hdpeDeduction={hdpeDeduction}
        hdpeKg={hdpeKg}
        handleInputChangeHD={handleInputChangeHD}
      />

      <PPModal
        form={form}
        handleFormChange={handleFormChange}
        ppValue={ppValue}
        showPp={showPp}
        handleClosePp={handleClosePp}
        ppDeduction={ppDeduction}
        ppKg={ppKg}
        handleInputChangePP={handleInputChangePP}
      />

      <PackagingBagModal
        form={form}
        handleFormChange={handleFormChange}
        packagingBagValue={packagingBagValue}
        showPackagingBag={showPackagingBag}
        handleClosePackagingBag={handleClosePackagingBag}
        packagingBagDeduction={packagingBagDeduction}
        packagingBagKg={packagingBagKg}
        handleInputChangePB={handleInputChangePB}
      />

      <CansModal
        form={form}
        handleFormChange={handleFormChange}
        cansValue={cansValue}
        showCans={showCans}
        handleCloseCans={handleCloseCans}
        cansDeduction={cansDeduction}
        cansKg={cansKg}
        handleInputChangeCans={handleInputChangeCans}
      />
    </div>
  );
};

export default CalculateTransaction;
