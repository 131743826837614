import React, { useEffect, useState } from "react";
import "../main.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getWeightByMonth } from "../../../../../../individualWeight";
import { getCurrentMonthName } from "../../../../../../getCurrentMonth";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Chart.js Bar Chart - Stacked",
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const Cash = ({
  stat,
  switchToCash,
  chart,
  switchToWeight,
  stats3,
  stats4,
}) => {
  const [value1, setValue1] = useState(0.0);
  const [value2, setValue2] = useState(0.0);
  const data = {
    labels,
    credits: {
      enabled: false,
    },
    datasets: [
      {
        label: "Household",
        data: stats3.map((stat) => stat.weight),
        backgroundColor: "#c4da00",
      },
      {
        label: "Agent",
        data: stats4.map((stat) => stat.weight),
        backgroundColor: "#404040",
      },
    ],
  };

  useEffect(() => {
    const currentMonthName = getCurrentMonthName();
    const desiredMonth = currentMonthName;
    const weightForMonth1 = getWeightByMonth(desiredMonth, stats3);
    if (weightForMonth1) {
      setValue1(Number(weightForMonth1));
    }

    const weightForMonth2 = getWeightByMonth(desiredMonth, stats4);
    if (weightForMonth2) {
      setValue2(Number(weightForMonth2));
    }

    // console.log("value1", value1);
    // console.log("value2", value2);
  }, []);

  // if (weightForMarch !== null) {
  //   console.log(`The weight for ${desiredMonth} is ${weightForMarch} kg.`);
  // } else {
  //   console.log(`Data for ${desiredMonth} not found.`);
  // }
  return (
    <div className="cardss">
      <div className="requestFlex">
        <div className="d-flex align-items-center">
          <div className="dropOff_image">
            <img src={stat} alt="stat" />
          </div>
          <h4 className="dropOff__title mt-1">Switch Stats</h4>
        </div>
        <div>
          <div className="buttonHolder">
            <button className="styled def" onClick={switchToCash}>
              Cash
            </button>
            <button className="unstyled def ml-2" onClick={switchToWeight}>
              Weight
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="mt-2 mb-4">
          <div className="d-flex ml-2">
            <div className="d-flex align-items-center">
              <div className="box green"></div>
              <p className="mb-0 ml-2">Household</p>
            </div>
            <div className="d-flex align-items-center ml-4">
              <div className="box dark"></div>
              <p className="mb-0 ml-2">Agent</p>
            </div>
          </div>
        </div>

        <div className="my-5 d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-center thisMonth">
            <p className="mb-0">This month : </p>
            <h6
              className="mb-0"
              style={{
                color: "#c4da00",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
            >
              {`₦${(value1 + value2).toFixed(2)}`}
            </h6>
          </div>
        </div>
        <div className="ml-3 pb-3 chartImg">
          {/* <img src={chart} alt="chart" /> */}
          <Bar options={options} data={data} />
        </div>
      </div>
    </div>
  );
};

export default Cash;
