import React from "react";
import Modal from "react-bootstrap/Modal";
import newLogo from "../../assets/images/newLogo.png";
import Clock from "./clock";

const WarningModal = ({
  showWarning,
  handleCloseWarning,
  timerDays,
  timerHours,
  timerMinutes,
  timerSeconds,
  handleStayLoggedIn,
  resetTimer,
}) => {
  return (
    <div>
      <Modal
        show={showWarning}
        onHide={() => {
          resetTimer();
          handleCloseWarning();
          handleStayLoggedIn();
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="mb-3">
              <img
                src={newLogo}
                alt="switch logo"
                style={{ width: "30%", textAlign: "center" }}
              />
            </div>
            <div className="mb-5">
              <h4 className="sec-header text-body mb-3 px-sm-5 mx-sm-5">
                You have been inactive
              </h4>
              <p className="ftsz-regular">
                Your account would be logged out in
              </p>

              <div>
                <Clock
                  timerDays={timerDays}
                  timerHours={timerHours}
                  timerMinutes={timerMinutes}
                  timerSeconds={timerSeconds}
                />
              </div>

              <div className="mx-5 py-4">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={handleStayLoggedIn}
                >
                  Stay Logged In
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WarningModal;
