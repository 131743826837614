import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./collection.css";
import MainArea from "./components/main/main";
import Navbar from "./components/navbar/navbar";
import PickUp from "./components/pickUp";
import Sidebar from "./components/sidebar/sidebar";
import Pick from "./components/pick";
import History from "./components/history";
import DriversCollections from "./components/drivers";
import AggregationCenter from "./components/aggregationCenter";
import SettingsCollections from "./components/settings";
import SupportCollections from "./components/support";
import WalletCollections from "./components/wallet";
import CollectionNotification from "./components/notification";
import ScheduledDropOff from "./components/aggregationCenter/scheduled";
// import MessagesComponent from "./components/messages";
import NavbarMobile from "./components/navbarMobile/navbar";
import MessagesComponents from "./components/messages/messagesComponents";
import WithInactivityTimer from "../../../inactivityTimer/withActivityTimer";
import { useSnackbar } from "notistack";
import Pusher from "pusher-js";

const CustomSnackbarContent = ({ data, history }) => {
  // console.log(history);
  return (
    <div
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      onClick={() => history.push("/dashboards/notification")}
    >
      <span style={{ marginRight: "10px" }}>🚀</span>
      <div>
        <strong>Alert !</strong>
        <p>{data}</p>
      </div>
    </div>
  );
};

const CollectionsDashboard = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [titleName, setTitleName] = useState("Dashboard");
  const [open, setOpen] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 500px)");
    setIsMobile(mediaQuery.matches);
    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const centerInfo = JSON.parse(localStorage.getItem("centerInfo"));

  // Sound notification function
  const playSound = () => {
    const audio = new Audio("/notification.mp3"); // Path to your sound file
    audio.play().catch((e) => console.error("Error playing sound:", e));
  };

  useEffect(() => {
    var pusher = new Pusher("4a2216be62f989b542473016ba7fc057", {
      cluster: "mt1",
      wsHost: "websocket.monicredit.com",
      wsPort: 443,
    });

    var channel = pusher.subscribe(
      `collection-center-${centerInfo?.collection_center?.id}`
    );

    const handleSendNotification = (data) => {
      const message = data?.message || "Hello, You have new notification !";
      playSound();
      enqueueSnackbar(
        <CustomSnackbarContent data={message} history={history} />,
        {
          // variant: "info",
          style: { backgroundColor: "#231F20", color: "white" },
          autoHideDuration: 10000, // 10000 milliseconds = 10 seconds
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
      // console.log(data);
      // window.location.reload();
    };

    channel.bind("collection-center-notification", handleSendNotification);

    // Cleanup
    return () => {
      channel.unbind("collection-center-notification", handleSendNotification);
      channel.unsubscribe();
    };
  }, []);

  return (
    <div className="containerss">
      {isMobile ? (
        <NavbarMobile
          sidebarOpen={sidebarOpen}
          openSidebar={openSidebar}
          titleName={titleName}
        />
      ) : (
        <Navbar
          sidebarOpen={sidebarOpen}
          openSidebar={openSidebar}
          titleName={titleName}
        />
      )}
      <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
      {/* <div> */}
      {location.pathname === "/dashboards" && <MainArea />}

      {location.pathname === "/dashboards/dropOff" && (
        <PickUp setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboards/pickUpRequest" && (
        <Pick setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboards/drivers" && (
        <DriversCollections setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboards/aggregationCenter" && (
        <AggregationCenter setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboards/aggregationScheduled" && (
        <ScheduledDropOff setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboards/settings" && (
        <SettingsCollections setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboards/support" && (
        <SupportCollections setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboards/wallet" && (
        <WalletCollections setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboards/history" && (
        <History setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboards/notification" && (
        <CollectionNotification setTitleName={setTitleName} />
      )}
      {location.pathname === "/dashboards/message" && (
        <MessagesComponents setTitleName={setTitleName} />
      )}
      {/* </div> */}
    </div>
  );
};

// export default CollectionsDashboard;
export default WithInactivityTimer(CollectionsDashboard);
