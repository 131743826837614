import React from "react";
import Modal from "react-bootstrap/Modal";
import navLogo from "../../../../../../assets/images/navLogo.png";

const AcceptModal = ({ showAccept, handleCloseAccept, cancelSchedule, codes, loading }) => {
  return (
    <div>
      <Modal
        show={showAccept}
        onHide={handleCloseAccept}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="mb-3">
              <img
                src={navLogo}
                alt="switch logo"
                style={{ width: "10%", textAlign: "center" }}
              />
            </div>
            <div className="mb-5">
              <h6 className="sec-header text-body mb-1 px-sm-5 mx-sm-5">
                Are you sure you want to cancel this Drop Off?
              </h6>
            </div>
            <div className="row px-4 mb-5">
              <div className="col-6">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={handleCloseAccept}
                >
                  Cancel
                </button>
              </div>

              <div className="col-6">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={() => {
                    cancelSchedule(codes);
                  }}
                  disabled={loading}
                >
                  {loading ? "Canceling ..." : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AcceptModal;
