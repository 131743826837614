import axios from "axios";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Avatar from "../../../../../../assets/images/avatarr.png";
import Phone from "../../../../../../assets/images/phone.png";
import { switchEndpoint } from "../../../../../../redux/actions/endpoints";
import "./index.css";

const DriversDetailsModal = ({ showDrop, handleCloseDrop }) => {
  const [stat, setStat] = useState([]);
  const [assigned, setAssigned] = useState([]);

  const drivers = JSON.parse(localStorage.getItem("activeDrivers"));

  const getDriversStat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/collection-center/drivers/pickups/${drivers?.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setAssigned(response.data);
      })
      .catch((error) => {
        if (drivers?.id) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
        }
        //  else {
        //   console.log(error.response.data.message);
        // }
      });
  };

  const getDriversStatistics = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/collection-center/drivers/pickups-today/${drivers?.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setStat(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    getDriversStat();
    getDriversStatistics();
  }, [drivers?.id]);

  return (
    <div>
      <Modal
        show={showDrop}
        onHide={handleCloseDrop}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-1">
            <div className="mb-2">
              <div className="d-flex justify-content-between align-items-center blockWay">
                <div className="d-flex align-items-center">
                  <div className="imgAvatarHolder ml-0">
                    <img src={Avatar} alt="image" />
                  </div>
                  <div className="ml-2">
                    <p
                      className="mb-0"
                      style={{ fontSize: "14px", fontWeight: "500" }}
                    >
                      {drivers?.name}
                    </p>
                    <p className="mb-0" style={{ fontSize: "12px" }}>
                      Plate No:{" "}
                      <span style={{ fontSize: "13px" }}>
                        {drivers?.plate_number}
                      </span>
                    </p>
                    <div className="d-flex align-items-center">
                      <div className="mr-3">
                        <p className="mb-0" style={{ fontSize: "12px" }}>
                          Wallet Balance:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {assigned?.user?.account?.account_balance === null
                              ? " ₦0.00"
                              : `₦${assigned?.user?.account?.account_balance}`}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="mb-0" style={{ fontSize: "12px" }}>
                          Cash Balance:{" "}
                          {assigned?.user?.cash_balance === null
                            ? " ₦0.00"
                            : `₦${assigned?.user?.cash_balance}`}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    padding: "7px 30px",
                    backgroundColor: "#404040",
                    color: "#c4da00",
                    borderRadius: "50px",
                  }}
                >
                  {drivers?.availability_status === "active" ? (
                    <p className="mb-0">Active</p>
                  ) : (
                    <p className="mb-0">Inactive</p>
                  )}
                </div>

                <div className="d-flex align-items-center mr-4">
                  <div className="d-flex align-items-center ">
                    <img src={Phone} alt="phone" />
                    <p
                      className="mb-0 ml-2"
                      style={{ fontSize: "14px", color: "#898989" }}
                    >
                      {drivers?.phone}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="width40"> */}
            <div className="d-flex mt-5">
              <div className="width60">
                {assigned?.pickups?.map((req) => {
                  return (
                    <div key={req?.id}>
                      <div className="reqBg">
                        <div className="d-flex align-items-center justify-content-lg-between">
                          <div>
                            <div className="d-flex align-items-center">
                              <img src={Avatar} alt="avatar" />
                              <p className="mb-0 ml-2">{req?.owner?.name}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="d-flex">
                              <p
                                className="mb-0 mr-5"
                                style={{ fontSize: "13px" }}
                              >
                                {req?.owner.address}
                              </p>
                            </div>
                            <div className="ml-2">
                              <div
                                className="buttonLike"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  //   handleShowDrop();
                                }}
                              >
                                <span
                                  style={{
                                    color: "#fff",
                                    textAlign: "center",
                                    margin: "auto",
                                  }}
                                >
                                  &#62;
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <br /> */}

                      <hr className="py-1 m-0" style={{ color: "#000" }} />
                    </div>
                  );
                })}
              </div>
              <div className="ml-5">
                <div>
                  <h4
                    className=""
                    style={{ fontSize: "15px", fontWeight: "600" }}
                  >
                    Completed Pick Ups Today
                  </h4>
                  <h2
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      color: "#c4da00",
                    }}
                  >
                    {`${stat?.completed_pickups_driver} / ${stat?.today_pickups_driver} Pick Ups`}
                  </h2>
                </div>

                <div className="my-4">
                  <h4
                    className=""
                    style={{ fontSize: "15px", fontWeight: "600" }}
                  >
                    Waste Collected Today
                  </h4>
                  <h2
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      color: "#c4da00",
                    }}
                  >
                    {`${stat?.waste_collected_today}kg`}
                  </h2>
                </div>

                <div>
                  <h4
                    className=""
                    style={{ fontSize: "15px", fontWeight: "600" }}
                  >
                    Payments Made Today
                  </h4>
                  <h2
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      color: "#c4da00",
                    }}
                  >
                    {`₦${stat?.payments_today}`}
                  </h2>
                </div>

                <div className="mt-5">
                  <button
                    type="button"
                    className="btn loginBtn btn-sm btn-block"
                    onClick={() => {
                      localStorage.setItem(
                        "driversProp",
                        JSON.stringify(drivers)
                      );
                      handleCloseDrop();
                    }}
                  >
                    Track on Map
                  </button>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DriversDetailsModal;
