import React from "react";

const Result = ({
  req,
  index,
  data,
  avatar,
  weigh,
  handleShowComplete,
  getSingleDropOff,
}) => {
  // console.log("dataaaaa" ,data);
  return (
    <div key={index}>
      <h6 className="py-2">{req}</h6>
      {data.map((datum, index) => {
        return (
          <div key={index}>
            <h6 className="py-1">{/* {req.date_type} */}</h6>
            <div className="reqBg">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <div className="d-flex align-items-center ">
                    <img src={avatar} alt={avatar} />
                    <p className="mb-0 ml-2">{datum?.owner?.name}</p>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <div>
                    <img src={weigh} alt="weight" />
                  </div>
                  <div>
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "bold",
                        color: "#c4da00",
                        marginLeft: "5px",
                      }}
                    >
                      {`${(datum?.tonnes).toFixed(3)}tonnes`}
                    </p>
                  </div>
                </div>

                <div className="addShow">
                  <p className="mb-0 mx-3 text-capitalize">
                    {datum?.owner?.address}
                  </p>
                </div>

                <div className="d-flex align-items-center">
                  <div className="addShow">
                    <p className="mb-0 mx-3 text-capitalize">
                      {datum?.owner?.phone}
                    </p>
                  </div>
                  <div className="ml-4">
                    <div
                      className="buttonLike cursor-pointer"
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          margin: "auto",
                        }}
                        onClick={() => {
                          getSingleDropOff(datum?.code);
                          // handleShowComplete();
                        }}
                      >
                        &#62;
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          // <div key={index}>
          //   <div className="reqBg">
          //     <div className="d-flex align-items-center justify-content-lg-between">
          //       <div>
          //         <div className="d-flex align-items-center">
          //           <img src={avatar} alt={avatar} />
          //           <p className="mb-0 ml-2">{datum?.owner?.name}</p>
          //         </div>
          //       </div>
          //       <div className="d-flex align-items-center">
          //         <div className="d-flex">
          //           <div>
          //             {/* <img src={calendar} alt={calendar} /> */}
          //           </div>
          //           <p className="mb-0 ml-2" style={{ fontSize: "13px" }}>
          //             {datum.date_type}
          //           </p>
          //         </div>
          //         <div className="ml-2">
          //           <div
          //             className="buttonLike"
          //             style={{ cursor: "pointer" }}
          //             // onClick={() => getDropOff(datum.code)}
          //           >
          //             <span
          //               style={{
          //                 color: "#fff",
          //                 textAlign: "center",
          //                 margin: "auto",
          //               }}
          //             >
          //               &#62;
          //             </span>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </div>
        );
      })}
    </div>
  );
};

export default Result;
