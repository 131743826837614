import React from "react";
import Modal from "react-bootstrap/Modal";
import Avatar from "../../../../../../../../assets/images/avatarr.png";
import "./index.css";
import Phone from "../../../../../../../../assets/images/phone.png";
import moment from "moment";
import { Spinner } from "react-bootstrap";

const HistoryModalPickUp = ({ showDropP, handleCloseDropP, loading }) => {
  const details = JSON.parse(localStorage.getItem("detailsPickUps"));
  // console.log("hi", details)
  return (
    <div>
      <Modal
        show={showDropP}
        onHide={() => {
          handleCloseDropP();
          // localStorage.removeItem("detailsDropOffs");
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="card_inners_amount my-4 text-center">
              <span>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </span>
            </div>
          ) : (
            <div className="text-left ml-3">
              <div className="mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="imgAvatarHolder ml-0">
                      <img src={Avatar} alt="imagee" />
                    </div>
                    <div className="ml-2">
                      <p
                        className="mb-0"
                        style={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        {details?.owner?.name}
                      </p>
                      <p className="mb-0" style={{ fontSize: "12px" }}>
                        {details?.owner?.address}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex mr-3">
                    <div className="form-group">
                      <div className="mr-3">
                        <p
                          className="mb-0 ml-2"
                          style={{ fontSize: "13px", color: "#898989" }}
                        >
                          Date Completed
                        </p>
                        <p
                          className="ml-2"
                          style={{
                            fontSize: "14px",
                            color: "#404040",
                            fontWeight: "700",
                          }}
                        >
                          {moment(details?.date_completed).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="d-flex">
                        <div>
                          <p
                            className="mb-0 ml-0"
                            style={{ fontSize: "13px", color: "#898989" }}
                          >
                            Time
                          </p>
                          <p
                            className="ml-0"
                            style={{
                              fontSize: "14px",
                              color: "#404040",
                              fontWeight: "700",
                            }}
                          >
                            {moment(details?.time_completed, "HH:mm:ss").format(
                              "hh:mm A"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-between align-items-center pt-2">
                    <div>
                      <div className="d-flex">
                        <div
                          style={{
                            padding: "7px 10px",
                            backgroundColor: "#404040",
                            color: "#c4da00",
                            borderRadius: "50px",
                            height: "fit-content",
                          }}
                        >
                          <p className="mb-0">Completed</p>
                        </div>

                        <div>
                          <div className="form-group">
                            <div className="ml-3">
                              <p
                                className="mb-0 ml-2"
                                style={{ fontSize: "13px", color: "#898989" }}
                              >
                                Dop Off Code
                              </p>
                              <p
                                className="ml-2"
                                style={{
                                  fontSize: "14px",
                                  color: "#404040",
                                  fontWeight: "700",
                                }}
                              >
                                {details?.code}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mr-4">
                      <div className="d-flex align-items-center ">
                        <img src={Phone} alt="phone" />
                        <p
                          className="mb-0 ml-2"
                          style={{ fontSize: "14px", color: "#898989" }}
                        >
                          {details?.owner?.phone}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flexs">
                  <div>
                    {details?.pickup_details?.clean_sorted?.KG > 0 && (
                      <div>
                        <div className="reqBg d-flex pl-4 w-50">
                          <p className="mb-0 mr-3">Clean Sorted</p>
                          <div className="d-flex">
                            <p className="fontBold">{`${details?.pickup_details?.clean_sorted?.KG}KG`}</p>
                            <span className="mx-3" style={{ color: "#c4da00" }}>
                              &#8644;
                            </span>
                            <p className="fontBold">{`₦${(details?.pickup_details?.clean_sorted?.amount).toFixed(
                              2
                            )}`}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {details?.pickup_details?.clean_unsorted?.KG > 0 && (
                      <div>
                        <div className="reqBg d-flex pl-4 w-50">
                          <p className="mb-0 mr-3">Clean Unsorted</p>
                          <div className="d-flex">
                            <p className="fontBold">{`${details?.pickup_details?.clean_unsorted?.KG}KG`}</p>
                            <span className="mx-3" style={{ color: "#c4da00" }}>
                              &#8644;
                            </span>
                            <p className="fontBold">{`₦${(details?.pickup_details?.clean_unsorted?.amount).toFixed(
                              2
                            )}`}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {details?.pickup_details?.mixed_coloured_caps?.KG > 0 && (
                      <div>
                        <div className="reqBg d-flex pl-4 w-50">
                          <p className="mb-0 mr-3">Mixed Color Caps</p>
                          <div className="d-flex">
                            <p className="fontBold">{`${details?.pickup_details?.mixed_coloured_caps?.KG}KG`}</p>
                            <span className="mx-3" style={{ color: "#c4da00" }}>
                              &#8644;
                            </span>
                            <p className="fontBold">{`₦${(details?.pickup_details?.mixed_coloured_caps?.amount).toFixed(
                              2
                            )}`}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {details?.pickup_details?.pure_water_sachet_dry?.KG > 0 && (
                      <div>
                        <div className="reqBg d-flex pl-4 w-50">
                          <p className="mb-0 mr-3">Pure Water Nylon</p>
                          <div className="d-flex">
                            <p className="fontBold">{`${details?.pickup_details?.pure_water_sachet_dry?.KG}KG`}</p>
                            <span className="mx-3" style={{ color: "#c4da00" }}>
                              &#8644;
                            </span>
                            <p className="fontBold">{`₦${(details?.pickup_details?.pure_water_sachet_dry?.amount).toFixed(
                              2
                            )}`}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {details?.pickup_details?.single_colour_caps?.KG > 0 && (
                      <div>
                        <div className="reqBg d-flex pl-4 w-50">
                          <p className="mb-0 mr-3">Single color caps</p>
                          <div className="d-flex">
                            <p className="fontBold">{`${details?.pickup_details?.single_colour_caps?.KG}KG`}</p>
                            <span className="mx-3" style={{ color: "#c4da00" }}>
                              &#8644;
                            </span>
                            <p className="fontBold">{`₦${(details?.pickup_details?.single_colour_caps?.amount).toFixed(
                              2
                            )}`}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {details?.pickup_details?.hard_packing_bags_dry?.KG > 0 && (
                      <div>
                        <div className="reqBg d-flex pl-4 w-50">
                          <p className="mb-0 mr-3">Hard packing bags (dry)</p>
                          <div className="d-flex">
                            <p className="fontBold">{`${details?.pickup_details?.hard_packing_bags_dry?.KG}KG`}</p>
                            <span className="mx-3" style={{ color: "#c4da00" }}>
                              &#8644;
                            </span>
                            <p className="fontBold">{`₦${(details?.pickup_details?.hard_packing_bags_dry?.amount).toFixed(
                              2
                            )}`}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {details?.pickup_details?.hdpe_plastics_below_five_meters
                      ?.KG > 0 && (
                      <div>
                        <div className="reqBg d-flex pl-4 w-50">
                          <p className="mb-0 mr-3">
                            {" "}
                            HDPE plastics below 5 metres
                          </p>
                          <div className="d-flex">
                            <p className="fontBold">{`${details?.pickup_details?.hdpe_plastics_below_five_meters?.KG}KG`}</p>
                            <span className="mx-3" style={{ color: "#c4da00" }}>
                              &#8644;
                            </span>
                            <p className="fontBold">{`₦${(details?.pickup_details?.hdpe_plastics_below_five_meters?.amount).toFixed(
                              2
                            )}`}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {details?.pickup_details?.pp_plastics?.KG > 0 && (
                      <div>
                        <div className="reqBg d-flex pl-4 w-50">
                          <p className="mb-0 mr-3">PP Plastics</p>
                          <div className="d-flex">
                            <p className="fontBold">{`${details?.pickup_details?.pp_plastics?.KG}KG`}</p>
                            <span className="mx-3" style={{ color: "#c4da00" }}>
                              &#8644;
                            </span>
                            <p className="fontBold">{`₦${(details?.pickup_details?.pp_plastics?.amount).toFixed(
                              2
                            )}`}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {details?.pickup_details?.packing_bags?.KG > 0 && (
                      <div>
                        <div className="reqBg d-flex pl-4 w-50">
                          <p className="mb-0 mr-3">Packaging Bags</p>
                          <div className="d-flex">
                            <p className="fontBold">{`${details?.pickup_details?.packing_bags?.KG}KG`}</p>
                            <span className="mx-3" style={{ color: "#c4da00" }}>
                              &#8644;
                            </span>
                            <p className="fontBold">{`₦${(details?.pickup_details?.packing_bags?.amount).toFixed(
                              2
                            )}`}</p>
                          </div>
                        </div>
                      </div>
                    )}

                    {details?.pickup_details?.cans?.KG > 0 && (
                      <div>
                        <div className="reqBg d-flex pl-4 w-50">
                          <p className="mb-0 mr-3">Packaging Bags</p>
                          <div className="d-flex">
                            <p className="fontBold">{`${details?.pickup_details?.cans?.KG}KG`}</p>
                            <span className="mx-3" style={{ color: "#c4da00" }}>
                              &#8644;
                            </span>
                            <p className="fontBold">{`₦${(details?.pickup_details?.cans?.amount).toFixed(
                              2
                            )}`}</p>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="mb-3 mr-3 w-50">
                      <label className="mb-1 form-label">Deductions</label>
                      <input
                        type="text"
                        className="form-control form-control-lg inputStyle bg-dark text-white"
                        // placeholder="-N0.00"
                        value={`₦${details?.pickup_details?.total_deduction}`}
                        disabled
                      />
                    </div>

                    <div>
                      <div className="totalBg d-flex pl-4 w-50">
                        <p className="mb-0 mr-3">Sum Total</p>
                        <div className="d-flex">
                          <p className="fontBold mb-0">{`${details?.pickup_details?.total_kg}KG`}</p>
                          <span className="mx-3" style={{ color: "#c4da00" }}>
                            &#8644;
                          </span>
                          <p className="fontBold mb-0">{`₦${details?.pickup_details?.total_price}`}</p>
                        </div>
                      </div>
                    </div>

                    <div className="my-4">
                      <p>
                        Payment Method:{" "}
                        {details?.payment_method === "transfer"
                          ? "Switch Transfer"
                          : "Cash"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HistoryModalPickUp;
