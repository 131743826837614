import React from "react";

const Result = ({ req, index, data }) => {
  // console.log("dataaaaa" ,data);
  return (
    <div key={index}>
      <h6 className="py-2 pb-0 " style={{ fontSize: "14px" }}>
        {req}
      </h6>
      {data.map((datum, index) => {
        return (
          <div key={index}>
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-around align-items-center mb-1">
                  {datum?.transaction_type === "Credit" ? (
                    <div
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                        backgroundColor: "#C4DA00",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        &#8593;
                      </span>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                        backgroundColor: "#404040",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        &#8595;
                      </span>
                    </div>
                  )}
                  <div>
                    <p className="mb-0 ml-3">{datum?.description}</p>
                    <p
                      className="mb-0 ml-3 mt-2"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >{`₦${datum?.amount}`}</p>
                  </div>
                </div>
              </div>
              <hr style={{ background: "#F8FA82" }} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Result;
