import React from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import green from "../../../../../assets/images/success.png";

const FloatSuccessfulModal = ({
  showFloatSuccess,
  handleCloseFloatSuccess,
}) => {
  const amt = localStorage.getItem("amt");
  return (
    <div>
      <Modal
        show={showFloatSuccess}
        onHide={() => {
          handleCloseFloatSuccess();
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="py-3">
              <img src={green} alt="green" style={{ width: "60%" }} />
            </div>

            <div className="pb-4">
              <p
                className="mt-1 mb-3"
                style={{ fontWeight: "500", fontSize: "18px" }}
              >
                {`Your request for ₦${amt} has been sent to your Center.`}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FloatSuccessfulModal;
