import React, { useState, useRef, useEffect } from "react";
import IdleTimer from "react-idle-timer";
import WarningModal from "./modals/warningModal";
import FinalModal from "./modals/finalModal";

const InactivityTimer = ({ onIdle }) => {
  const idleTimerRef = useRef(null);

  const [pathName, setPathName] = useState("");

  const [showWarning, setShowWarning] = useState(false);
  const handleCloseWarning = () => setShowWarning(false);
  const handleShowWarning = () => setShowWarning(true);

  const [showFinal, setShowFinal] = useState(false);
  const handleCloseFinal = () => setShowFinal(false);
  const handleShowFinal = () => setShowFinal(true);

  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();

  let interval;

  const handleIdle = () => {
    handleShowWarning();
  };

  const handleActive = () => {
    idleTimerRef.current.reset(); // Reset the idle timer
  };

  const handleLogout = () => {
    setShowWarning(false);
    onIdle(); // Perform logout or any other action
  };

  const handleStayLoggedIn = () => {
    idleTimerRef.current.reset(); // Reset the idle timer
    setShowWarning(false);
    clearInterval(interval);
  };

  const startTimer = () => {
    clearInterval(interval); // Clear any existing interval

    const afterSpecifiedMinutes = new Date(new Date().getTime() + 30 * 60000);

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = afterSpecifiedMinutes - now;
      const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
      const seconds = Math.floor((distance % (60 * 1000)) / 1000);

      if (distance <= 0) {
        clearInterval(interval);
        setShowWarning(false);
        handleCloseWarning();
        localStorage.removeItem("token");
        localStorage.removeItem("expireTime");
        handleShowFinal();
      } else if (!showWarning) {
        clearInterval(interval);
      } else if (showWarning && distance > 0) {
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  const resetTimer = () => {
    clearInterval(interval);
    setTimerMinutes();
    setTimerSeconds();
    setShowWarning(false); // Assuming you want to hide the warning after resetting
  };

  const getUrl = () => {
    const currentURL = window.location.pathname;
    // console.log(currentURL);
    setPathName(currentURL);
  };

  useEffect(() => {
    getUrl();
  }, []);

  useEffect(() => {
    if (showWarning) {
      startTimer();
    } else {
      resetTimer();
    }
    return () => {
      clearInterval(interval);
    };
  }, [showWarning]);

  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        // timeout={1000 * 5} // 5 seconds
        timeout={1000 * 60 * 30} // 1 minutes in milliseconds
        onIdle={handleIdle}
        onActive={handleActive}
      />
      <WarningModal
        showWarning={showWarning}
        handleCloseWarning={handleCloseWarning}
        startTimer={startTimer}
        timerMinutes={timerMinutes}
        timerSeconds={timerSeconds}
        handleStayLoggedIn={handleStayLoggedIn}
        setTimerMinutes={setTimerMinutes}
        setTimerSeconds={setTimerSeconds}
        interval={interval}
        resetTimer={resetTimer}
      />
      <FinalModal
        showFinal={showFinal}
        handleCloseFinal={handleCloseFinal}
        handleLogout={handleLogout}
        pathName={pathName}
      />
    </div>
  );
};

export default InactivityTimer;
