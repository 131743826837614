import React, { useState } from "react";
import "./navbar.css";
import navLogo from "../../../../../assets/images/navLogo.png";
import message from "../../../../../assets/images/messages.png";
import notification from "../../../../../assets/images/notification.png";
import logout from "../../../../../assets/images/logout.png";
import { useHistory, useLocation } from "react-router-dom";
import LogoutModal from "./modal/logout";
import { HiMenuAlt2, HiOutlineMenuAlt3 } from "react-icons/hi";
import { GrFormClose } from "react-icons/gr";
import axios from "axios";
import { toast } from "react-toastify";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";

const NavbarMobile = ({ openSidebar, titleName }) => {
  const center = localStorage.getItem("center");
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [showLogout, setShowLogout] = useState(false);
  const handleCloseLogout = () => setShowLogout(false);
  const handleShowLogout = () => setShowLogout(true);

  const [toggle, setToggle] = useState(false);

  const logoutMethod = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/logout`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data);
        localStorage.removeItem("token");
        history.push("/aggregation");
        setLoading(false);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  return (
    <nav>
      <div className="navbar d-flex justify-content-between align-items-center">
        <div className="nav_icon" onClick={() => openSidebar()}>
          <div
            style={{
              backgroundColor: "#c4da00",
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
          >
            <HiMenuAlt2 style={{ color: "#fff" }} />
          </div>
        </div>
        <p className="mb-0">{titleName}</p>
        <div>
          <div
            style={{
              backgroundColor: "#c4da00",
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
            onClick={() => setToggle(!toggle)}
          >
            {toggle ? (
              <GrFormClose style={{ color: "#fff" }} />
            ) : (
              <HiOutlineMenuAlt3 style={{ color: "#fff" }} />
            )}
          </div>
        </div>
      </div>
      {toggle && (
        <div
          style={{
            position: "absolute",
            top: "20",
            right: "0",
            minWidth: "200px",
            background: "white",
            border: "1px solid #c4da00",
            boxShadow: "5px 5px 13px #ededed, -5px 13px #fff",
            zIndex: "999",
          }}
          className="mx-4 my-2 py-3 px-2"
        >
          <div className="form-group">
            {/* <input
              type="email"
              className="form-control inputStyle"
              placeholder="Search Household/Agent"
            /> */}

            <div className="menuIc">
              <ul className="text-right">
                <li
                  className={`py-1 text-right ${
                    location.pathname === "/dashboard/message"
                      ? "active-menu_link"
                      : "sidebar__link"
                  }`}
                  style={{ borderRadius: "5px" }}
                >
                  <a href="/dashboard/message">
                    <img src={message} alt="nav logo" /> &nbsp; Messages
                  </a>
                </li>
                <li
                  className={`py-1 ${
                    location.pathname === "/dashboard/notification"
                      ? "active-menu_link"
                      : "sidebar__link"
                  }`}
                  style={{ borderRadius: "5px" }}
                >
                  <a href="/dashboard/notification">
                    <img src={notification} alt="nav logo" /> &nbsp;
                    Notifications
                  </a>
                </li>
                <li
                  className="py-1 text-right"
                  style={{ marginLeft: "25px", listStyle: "none" }}
                  onClick={() => {
                    handleShowLogout();
                    // localStorage.removeItem("token");
                    // history.push("/aggregation");
                  }}
                >
                  <img src={logout} alt="nav logo" /> &nbsp; Log Out
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <LogoutModal
        handleCloseLogout={handleCloseLogout}
        showLogout={showLogout}
        logoutMethod={logoutMethod}
        loading={loading}
      />
    </nav>
  );
};

export default NavbarMobile;
