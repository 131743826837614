import axios from "axios";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { switchEndpoint } from "../../../../../../../../redux/actions/endpoints";
import "./index.css";

const UnregisteredHouseholdModal = ({
  showHousehold,
  handleCloseHousehold,
  handleShowCode,
}) => {
  const [unregistered, setUunregistered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (/^[a-zA-Z-]*$/.test(value)) {
      setFirstName(value);
    }
  };

  const handleInputChange2 = (event) => {
    const value = event.target.value;
    if (/^[a-zA-Z-]*$/.test(value)) {
      setLastName(value);
    }
  };

  const handleInputChange3 = (event) => {
    const value = event.target.value;
    if (/^[0-9]*$/.test(value)) {
      setPhone(value);
    }
  };

  const validate = () => {
    if (!firstName) {
      toast.error("First Name not provided !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!lastName) {
      toast.error("Last Name not provided !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!phone) {
      toast.error("Phone Number not provided !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    createUnregistered();
  };

  const createUnregistered = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let payload = {
      first_name: firstName,
      last_name: lastName,
      phone: phone,
    };
    axios
      .post(
        `${switchEndpoint}/aggregation-center/create-dropoff-unregistered`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        // console.log(response.data.data);
        setLoading(false);
        localStorage.setItem("names", response.data.data.owner?.name);
        localStorage.setItem("reference", JSON.stringify(response.data.data));
        localStorage.setItem("id", response.data.data.id);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        handleCloseHousehold();
        setFirstName("");
        setLastName("");
        setPhone("");
        handleShowCode();
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };
  return (
    <div>
      <Modal
        show={showHousehold}
        onHide={() => {
          setFirstName("");
          setLastName("");
          setPhone("");
          handleCloseHousehold();
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <h6 className="sec-headers mb-3">Unregistered Household</h6>
              <p className="ftsz-regular" style={{ fontSize: "14px" }}>
                Generate a drop off code for an unregistered customer by
                inputting their details.
              </p>
            </div>
            <div className="w-50">
              <div className="mb-3">
                <label className="mb-1 form-label">
                  Customer’s Phone Number
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg inputStyle"
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={handleInputChange3}
                />
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div>
                    <label className="mb-1 form-label">
                      Customer’s First Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg inputStyle"
                      placeholder="Enter first name"
                      value={firstName}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div>
                    <label className="mb-1 form-label">
                      Customer’s Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg inputStyle"
                      placeholder="Enter last name"
                      value={lastName}
                      onChange={handleInputChange2}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={() => {
                    validate();
                  }}
                  disabled={loading}
                >
                  {loading ? "Sending" : "Send Drop Off Code"}
                </button>
              </div>
              <div className="mt-3 mb-5">
                <button
                  type="button"
                  className="btn loginBtn2 btn-sm btn-block"
                  onClick={() => {
                    setFirstName("");
                    setLastName("");
                    setPhone("");
                    handleCloseHousehold();
                  }}
                >
                  Skip
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UnregisteredHouseholdModal;
