import { SET_STAT_TYPE } from "../types";

export const setStat = (payload) => ({
    type: SET_STAT_TYPE,
    payload,
});

const exportedObject = {
    setStat,
}

export default exportedObject;