import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Input } from "antd";
import "./index.css";
import axios from "axios";
import { toast } from "react-toastify";
import { switchEndpoint } from "../../../../../../../../redux/actions/endpoints";
import { Spinner } from "react-bootstrap";

const AuthenticateModal2 = ({
  showAuthenticate2,
  handleCloseAuthenticate2,
  handleShowPaymentS,
  handleShowPaymentF,
  getHouseholdReq,
  getAgentReq,
}) => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const reference = JSON.parse(localStorage.getItem("reference"));
  // console.log(reference);

  const getRef = (code) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/single-dropoff/${code}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data);

        axios
          .post(
            `${switchEndpoint}/aggregation-center/start-dropoff-transfer`,
            {
              reference: response?.data?.data?.payment_details?.reference,
              password: password,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            handleCloseAuthenticate2();
            handleShowPaymentS();
            setLoading(false);
          })
          .catch((error) => {
            handleShowPaymentF();
            setLoading(false);
            // toast.error(error.response.data.message, {
            //   position: "top-right",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   pauseOnHover: true,
            //   progress: undefined,
            //   theme: "light",
            // });
          });

        localStorage.setItem("reference", JSON.stringify(response.data.data));
      })
      .catch((error) => {
        if (reference) {
          console.log(error);
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        } else {
          console.log(error);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <div>
      <Modal
        show={showAuthenticate2}
        onHide={() => {
          handleCloseAuthenticate2();
          getHouseholdReq();
          getAgentReq();
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <div className="text-center mt-3 mb-5">
                <h5 className="sec-headers mb-3">Authenticate Transaction</h5>

                <div className="text-left w-50 m-auto">
                  <label className="label-control mb-0 labelStyle">
                    Enter Password
                  </label>
                  <Form>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    >
                      <Input.Password placeholder="*************" />
                    </Form.Item>
                  </Form>
                </div>

                <div className="mt-1 mb-5 w-btn m-auto pt-3">
                  <button
                    type="button"
                    className="btn loginBtn btn-sm btn-block d-flex justify-content-center align-items-center"
                    onClick={() => getRef(reference?.code)}
                    disabled={loading}
                  >
                    {loading ? (
                      <span>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </span>
                    ) : (
                      "Authenticate Payment"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AuthenticateModal2;
