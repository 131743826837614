import React from "react";
import "./sidebar.css";
import { useLocation } from "react-router-dom";
import newLogo from "../../../../../assets/images/newLogo.png";
import dashboard from "../../../../../assets/images/Vector.png";
import dropoff from "../../../../../assets/images/location.png";
import pickup from "../../../../../assets/images/pickup.png";
import history from "../../../../../assets/images/history.png";
import drivers from "../../../../../assets/images/drivers.png";
import collection from "../../../../../assets/images/collection.png";
import wallet from "../../../../../assets/images/wallet.png";
import support from "../../../../../assets/images/support.png";
import settings from "../../../../../assets/images/settings.png";
import { FaTimes } from "react-icons/fa";

const Sidebar = ({ sidebarOpen, closeSidebar }) => {
  const location = useLocation();
  return (
    <div className={sidebarOpen ? "sidebar_responsive" : ""} id="sidebar">
      <div className="sidebar__title">
        <div className="sidebar__img">
          <img src={newLogo} alt="logo" />
        </div>
        <div
          style={{
            backgroundColor: "#c4da00",
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
          }}
          className="nav_icon nav_icons"
        >
          <FaTimes
            id="sidebarIcon"
            onClick={() => closeSidebar()}
            style={{ color: "#fff" }}
          />
        </div>
      </div>

      <div className="sidebar__menu">
        <div
          className={`${
            location.pathname === "/dashboard"
              ? "active-menu_link"
              : "sidebar__link"
          }`}
        >
          <div className="icon__img">
            <img src={dashboard} />
          </div>

          <a href="/dashboard">Dashboard</a>
        </div>

        <div
          className={`${
            location.pathname === "/dashboard/dropOff"
              ? "active-menu_link"
              : "sidebar__link"
          }`}
        >
          <div className="icon__img">
            <img src={dropoff} alt="drop" />
          </div>
          <a href="/dashboard/dropOff">Drop Off Requests</a>
        </div>

        <div
          className={`${
            location.pathname === "/dashboard/pickUp"
              ? "active-menu_link"
              : "sidebar__link"
          }`}
        >
          <div className="icon__img">
            <img src={pickup} alt="pick" />
          </div>
          <a href="/dashboard/pickUp">Pick Up Requests</a>
        </div>

        <div
          className={`${
            location.pathname === "/dashboard/history"
              ? "active-menu_link"
              : "sidebar__link"
          }`}
        >
          <div className="icon__img">
            <img src={history} alt="history" />
          </div>
          <a href="/dashboard/history">History</a>
        </div>

        <div
          className={`${
            location.pathname === "/dashboard/drivers"
              ? "active-menu_link"
              : "sidebar__link"
          }`}
        >
          <div className="icon__img">
            <img src={drivers} alt="driver" />
          </div>
          <a href="/dashboard/drivers">Drivers</a>
        </div>

        <div
          className={`${
            location.pathname === "/dashboard/collectionCenter" ||
            location.pathname === "/dashboard/collections"
              ? "active-menu_link"
              : "sidebar__link"
          }`}
        >
          <div className="icon__img">
            <img src={collection} alt="collection" />
          </div>
          <a href="/dashboard/collectionCenter">Collection Center</a>
        </div>

        <div
          className={`${
            location.pathname === "/dashboard/wallet"
              ? "active-menu_link"
              : "sidebar__link"
          }`}
        >
          <div className="icon__img">
            <img src={wallet} alt="wallet" />
          </div>
          <a href="/dashboard/wallet">Wallet</a>
        </div>

        <div
          className={`${
            location.pathname === "/dashboard/support"
              ? "active-menu_link"
              : "sidebar__link"
          }`}
        >
          <div className="icon__img">
            <img src={support} alt="support" />
          </div>
          <a href="/dashboard/support">Support</a>
        </div>

        <div
          className={`${
            location.pathname === "/dashboard/settings"
              ? "active-menu_link"
              : "sidebar__link"
          }`}
        >
          <div className="icon__img">
            <img src={settings} alt="settings" />
          </div>
          <a href="/dashboard/settings">Settings</a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
