import React from "react";
import Modal from "react-bootstrap/Modal";
import SuccessImg from "../../../../../../../../assets/images/success.png";

const SuccessfulModal = ({ handleCloseSuccess, showSuccess }) => {
  const request = JSON.parse(localStorage.getItem("req"));
  const status = localStorage.getItem("status");
  return (
    <div>
      <Modal
        show={showSuccess}
        onHide={() => {
          handleCloseSuccess();
          localStorage.removeItem("req");
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <div className="text-center mt-3 mb-5">
                <div className="successImg mb-4">
                  <img src={SuccessImg} alt="success image" />
                </div>
                {status === "accept" && (
                  <div>
                    <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                      Transaction Successful
                    </p>
                    <p style={{ fontSize: "12px" }}>
                      {`You sent ₦${request?.requested_amount} to Driver: ${request?.driver}`}
                    </p>
                  </div>
                )}

                {status === "reject" && (
                  <div>
                    <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                      Transaction Rejected
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SuccessfulModal;
