import React, { useEffect, useState } from "react";
import "./index.css";
import { Table } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import axios from "axios";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import { toast } from "react-toastify";
import moment from "moment";
import HistoryModal from "./components/modals/dropOffCode.js";
import HistoryModalPickUp from "./components/modals/dropOffCode.js/pickUpModal.js";

const History = ({ setTitleName }) => {
  const [dataHistory, setDataHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showDrop, setShowDrop] = useState(false);
  const handleCloseDrop = () => setShowDrop(false);
  const handleShowDrop = () => setShowDrop(true);

  const [showDropP, setShowDropP] = useState(false);
  const handleCloseDropP = () => setShowDropP(false);
  const handleShowDropP = () => setShowDropP(true);

  const [pickUpHistory, setPickUpHistory] = useState([]);
  const [dropOffHistory, setDropOffHistory] = useState([]);
  const [selected, setSelected] = useState("all");

  const getPickUp = (code) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/collection-center/single-pickup/${code}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const res = response.data.data;
        // console.log(res)
        localStorage.setItem("detailsDropOffss", JSON.stringify(res));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const getDropOff = (code) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/collection-center/single-dropoff/${code}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data.data);
        const res = response.data.data;
        // console.log(res)
        localStorage.setItem("detailsDropOffss", JSON.stringify(res));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const columns = [
    {
      title: "SN",
      dataIndex: "id",
      key: "id",
      // sorter: true,
      //   render: (text) => <a>{text}</a>,

      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
    },
    {
      title: "Name",
      dataIndex: "",
      key: "name",
      render: (users) => {
        return users?.user?.name;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "age",
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      // render: (payment) => {
      //   <p className="mb-0">{`₦${payment.payment}`}</p>;
      // },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => (
        <p className="mb-0">{moment(date).format("DD/MM/YYYY")}</p>
      ),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (time) => (
        <p className="mb-0">{moment(time, "HH:mm:ss").format("HH:mm")}</p>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "paymentMethod",
      render: (payment) => (
        <p className="mb-0">{payment === "transfer" ? "Transfer" : "Cash"}</p>
      ),
    },
    {
      title: " ",
      key: "action",
      render: (data) => (
        <BsThreeDotsVertical
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (data.type === "Pickup") {
              getPickUp(data.code);
              handleShowDropP();
            } else {
              getDropOff(data.code);
              handleShowDrop();
            }
          }}
        />
      ),
    },
  ];

  const getHistory = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/collection-center/history`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data.data);
        let result = response.data.data;
        let pick = result.filter((x) => x.type === "Pickup");
        let drop = result.filter((x) => x.type === "Dropoff");
        setPickUpHistory(pick);
        setDropOffHistory(drop);

        if (selected === "all") {
          setDataHistory(result);
        } else if (selected === "drop") {
          setDataHistory(drop);
        } else if (selected === "pick") {
          setDataHistory(pick);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    getHistory();
  }, [selected]);

  useEffect(() => {
    setTitleName("History");
  }, []);

  return (
    <main>
      <div className="main__container">
        <div className="cardss p-0 py-1">
          <span className="d-flex align-items-center py-3 px-2">
            <b>History</b>({dataHistory.length})
            <div className="ml-3">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="option1"
                  // checked
                  onClick={() => setSelected("all")}
                />
                <label class="form-check-label" for="inlineRadio1">
                  All
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="option2"
                  onClick={() => setSelected("drop")}
                />
                <label class="form-check-label" for="inlineRadio2">
                  Drop Off
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio3"
                  value="option3"
                  onClick={() => setSelected("pick")}
                />
                <label class="form-check-label" for="inlineRadio3">
                  Pick Up
                </label>
              </div>
            </div>
          </span>
          <Table columns={columns} dataSource={dataHistory} size="middle" />
        </div>
      </div>

      <HistoryModal
        showDrop={showDrop}
        handleCloseDrop={handleCloseDrop}
        loading={loading}
      />

      <HistoryModalPickUp
        showDropP={showDropP}
        handleCloseDropP={handleCloseDropP}
        loading={loading}
      />
    </main>
  );
};

export default History;
