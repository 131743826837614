import React from "react";
import Modal from "react-bootstrap/Modal";
import Avatar from "../../../../../../../../assets/images/avatarr.png";
import "./index.css";
import phone from "../../../../../../../../assets/images/phone.png";
import capitalize from "../../../../../../../../capitalize";

const PickUpModal = ({ showDrop, handleCloseDrop }) => {
  const req = JSON.parse(localStorage.getItem("pickUpDetails"));
  // console.log(req);
  return (
    <div>
      <Modal
        show={showDrop}
        onHide={handleCloseDrop}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <h5 className="sec-headers mb-3 fw-bolder">{`${capitalize(req?.owner?.roles[0])}'s details`}</h5>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="imgAvatarHolder ml-3">
                    <img src={Avatar} alt="imagee" />
                  </div>
                  <p
                    className="ftsz-regular mb-0 ml-2"
                    style={{ fontSize: "14px" }}
                  >
                    {capitalize(req?.owner?.roles[0])}: {req?.owner?.name}
                  </p>
                </div>

                <div className="d-flex align-items-center ">
                  <div className="d-flex align-items-center ">
                    <img src={phone} alt="phone" />
                    <p
                      className="mb-0 ml-2"
                      style={{ fontSize: "14px", color: "#898989" }}
                    >
                      {req?.owner?.phone}
                    </p>
                  </div>
                </div>
              </div>
              <p className="mt-3 mb-5 pb-5" style={{ fontSize: "14px" }}>
                Address: {req?.owner?.address}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PickUpModal;
