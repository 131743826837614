import React, { useState } from "react";
import { PubNubProvider } from "pubnub-react";
import NewChatComponent from "./chatComponent";
import Pubnub from "pubnub";

// const publishKey = "pub-c-c0b39dfa-2fee-4f59-9550-fa5dc39e02b0";
// const subscribeKey = "sub-c-8da73e41-e74d-4891-8236-67ab8e4de999";
// const uuid = "sec-c-NGNiMzFmZTgtOWY0My00OGZlLWJjOTQtNWQ0MGJhODQ2MmI1";

// const publishKey = process.env.REACT_APP_PUBLIC_KEY;
// const subscribeKey = process.env.REACT_APP_SUBSCRIBE_KEY;
// const uuid = process.env.REACT_APP_UUID_KEY;

// const pubnubConfig = new Pubnub({
//   publishKey,
//   subscribeKey,
//   uuid,
// });

const currentUser = JSON.parse(localStorage.getItem("centerInfo"));

const PubnubMessages = () => {
  const [otherUser, setOtherUser] = useState();
  return (
    <div>
      {/* <PubNubProvider client={pubnubConfig} uuid={uuid}> */}
      <h3 className="text-center m-auto pt-5">Coming soon ...</h3>
      {/* <NewChatComponent
          uuid={uuid}
          otherUser={otherUser}
          setOtherUser={setOtherUser}
          currentUser={currentUser}
        />
      </PubNubProvider> */}
    </div>
  );
};

export default PubnubMessages;
