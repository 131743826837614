import React, { useState } from "react";
import avatar from "../../../../../../assets/images/avatarr.png";
import Selected from "./Selected";

const Resultss = ({
  req,
  index,
  driversList,
  handleShowDrop,
  assignDriver,
  data,
}) => {
  // console.log("data", data)
  // console.log("req", req)
  return (
    <div key={index}>
      <h6 className="py-2">{req}</h6>
      {data.map((date, index) => {
        return (
          <div key={index}>
            <div className="reqBg">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <div className="d-flex align-items-center ">
                    <img src={avatar} alt={avatar} />
                    <p className="mb-0 ml-2">{date.owner.name}</p>
                  </div>
                </div>

                <div className="addShow">
                  <p className="mb-0 mx-3 text-capitalize">
                    {date.owner.address}
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <div className="d-flex">
                    <Selected driversList={driversList} assignDriver={assignDriver} date={date} />
                  </div>
                  <div className="ml-4">
                    <div
                      className="buttonLike cursor-pointer"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        localStorage.setItem(
                          "pickUpDetails",
                          JSON.stringify(date)
                        );
                        handleShowDrop();
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          margin: "auto",
                        }}
                      >
                        &#62;
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        );
      })}
    </div>
  );
};

export default Resultss;
