import React, { useState, useEffect } from "react";
import Support from "../../../../../assets/images/contact_support.png";
import SuccessfulModal from "./modal/successful";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import { toast } from "react-toastify";

const SupportAggregation = ({ setTitleName }) => {
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const [settingsForm, setSettingsForm] = useState({
    subject: "",
    message: "",
  });

  const handleSettingsForm =
    (name) =>
    ({ target }) => {
      setSettingsForm({
        ...settingsForm,
        [name]: target.value,
      });
    };

  const handleSettings = () => {
    setLoading(true);
    let payload = {
      subject: settingsForm.subject,
      message: settingsForm.message,
    };
    axios
      .post(`${switchEndpoint}/send-admin-message`, payload)
      .then((response) => {
        // console.log(response.data);
        setLoading(false);
        handleShowSuccess();
        setSettingsForm({
          subject: "",
          message: "",
        });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const validate = () => {
    if (!settingsForm.subject) {
      toast.error("Subject not provided !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!settingsForm.message) {
      toast.error("Message not provided !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    handleSettings();
  };

  useEffect(() => {
    setTitleName("Support");
  }, []);
  return (
    <main>
        {/* Support */}
      <div className="p-3">
        <div className="d-flex align-items-center">
          <div>
            <h4 className="mb-0">Support</h4>
          </div>
          <div className="ml-2">
            <img src={Support} alt="support" />
          </div>
        </div>
        <div className="my-3">
          <p style={{ fontSize: "14px" }}>
            Want to get in touch with us? Let’s chat now.
          </p>
        </div>
        <div className="w-60 newW pt-3">
          <div className="form-group">
            <label className="label-control mb-0 labelStyle">Subject</label>
            <input
              type="text"
              className="form-control inputStyle"
              placeholder="Enter subject"
              value={settingsForm.subject}
              onChange={handleSettingsForm("subject")}
            />
          </div>

          <div className="form-group pt-2">
            <label className="label-control mb-0 labelStyle">
              Your Message
            </label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Enter your message."
              value={settingsForm.message}
              onChange={handleSettingsForm("message")}
            ></textarea>
          </div>
        </div>
        <div className="pt-3 w-25 adjW">
          <button type="button" className="btn loginBtn btn-sm btn-block" 
            onClick={validate}
            disabled={loading}>
            {loading ? (
              <span>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </span>
            ) : (
              "Send Message"
            )}
          </button>
        </div>
      </div>

      <SuccessfulModal
        handleCloseSuccess={handleCloseSuccess}
        showSuccess={showSuccess}
      />
    </main>
  );
};

export default SupportAggregation;
