import React from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import green from "../../../../../assets/images/confirmed.png";

const PaymentConfirmedModal = ({ showConfirmed, handleCloseConfirmed, handleShowRejected }) => {
  const history = useHistory();
  return (
    <div>
      <Modal
        show={showConfirmed}
        onHide={() => {
          handleCloseConfirmed();
          //   history.push("/drivers/start");
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="py-4">
              <img src={green} alt="green" />
            </div>

            <div className="pb-4">
              <p className="mt-2 mb-4" style={{ fontWeight: "600", fontSize: "16px" }}>
                Payment Request Confirmed
              </p>
              <div className="pb-3">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                    onClick={() => {
                        handleCloseConfirmed();
                        history.push("/drivers/review")
                    }}
                  //   disabled={loading}
                >
                  Proceed to Payment
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentConfirmedModal;
