import { SET_WALLET_TYPE } from "../types";

export const setDisplay = (payload) => ({
    type: SET_WALLET_TYPE,
    payload,
});

const exportedObject = {
    setDisplay,
}

export default exportedObject;