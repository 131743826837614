import React, { useState } from "react";
import "./index.css";
import newLogo from "../../../../assets/images/newLogo.png";
import { toast } from "react-toastify";
import * as EmailValidator from "email-validator";
import axios from "axios";
import { switchEndpoint } from "../../../../redux/actions/endpoints";

const SuperAdminFPW = (props) => {
  const [loading, setLoading] = useState(false);
  const [resetData, setResetData] = useState({
    email: "",
  });

  const handleLoginForm =
    (name) =>
    ({ target }) => {
      setResetData({
        ...resetData,
        [name]: target.value,
      });
    };

  // const handleReset = () => {
  //   console.log(resetData);
  //   let payload = {
  //     email: resetData.email,
  //   };
  //   localStorage.setItem("email", resetData.email);

  //   setLoading(true);
  //   axios
  //     .post(`${switchEndpoint}/auth/forgot-password-email`, payload)
  //     .then((response) => {
  //       console.log(response.data);
  //       setLoading(false);
  //       toast.success(response.data.message, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         pauseOnHover: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //       // localStorage.setItem("token", response.data.token);
  //       props.history.push("/aggregation/otp");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //       toast.error(error.response.data.errors, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         pauseOnHover: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //     });
  // };

  // const validate = () => {
  //   if (!resetData.email) {
  //     toast.error("Email not provided !", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       pauseOnHover: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     return;
  //   }
  //   if (!EmailValidator.validate(resetData.email)) {
  //     toast.error("Email not valid !", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       pauseOnHover: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     return;
  //   }
  //   handleReset();
  // };
  return (
    <div>
      <div className="row noMarginPadding">
        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-8 col-xs-12 PaddingLeft-10">
          <div className="container pl-4 pt-4 PaddingLeft-10">
            <div style={{ width: "150px" }}>
              <img src={newLogo} alt="logo" style={{ width: "100%" }} />
            </div>
            <div className="w-75 ml-5 mt-5 containerWidth">
              <h5 className="headerText headerh5">Switch Admin</h5>
              <p className="loginStyle mb-0">Forgot Password</p>
              <div className="form-group">
                <label className="label-control mb-0 labelStyle">
                  Email Address
                </label>
                <input
                  type="email"
                  className="form-control inputStyle"
                  placeholder="Enter email address"
                  value={resetData.email}
                  onChange={handleLoginForm("email")}
                />
              </div>

              <div className="mx-3">
                <button
                  type="button"
                  className="btn passwordBtn btn-sm btn-block mt-4 px-2"
                  // onClick={validate}
                  onClick={() => {
                    props.history.push("/admin/otp");
                  }}
                  disabled={loading}
                >
                  Send Password Reset Link
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-4 col-md-4 rightImg"></div>
      </div>
    </div>
  );
};

export default SuperAdminFPW;
