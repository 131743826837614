import React from "react";

const Result = ({ req, index, data, getSinglePickUp, avatar }) => {
  // console.log("dataaaaa" ,data);
  return (
    <div key={index}>
      <h6 className="pt-2 pb-0" style={{ fontSize: "14px" }}>
        {req}
      </h6>
      {data.map((datum, index) => {
        return (
          <div key={index}>
            <div className="reqBg">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <div className="d-flex align-items-center">
                    <img src={avatar} alt={avatar} />
                    <div>
                      <p
                        className="mb-0 ml-2"
                        style={{ fontSize: "16px", fontWeight: "600" }}
                      >
                        {datum?.owner?.name}
                      </p>
                      <p className="mb-1 ml-2">{datum?.owner?.address}</p>
                      <p
                        className="mb-0 ml-2"
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#c4da00",
                        }}
                      >
                        {datum?.owner?.phone}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="ml-2">
                    <div
                      className="buttonLike"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        // console.log(datum)
                        getSinglePickUp(datum.code);
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          margin: "auto",
                        }}
                      >
                        &#62;
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Result;
