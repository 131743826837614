import { Table } from "antd";
import moment from "moment";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import "./index.css";

const Transaction = ({
  switchToFundCenter,
  switchToFundDriver,
  transactionsList,
  setSelected,
  switchToFloat,
}) => {
  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      key: "id",
      sorter: false,
      render: (id, record, index) => {
        ++index;
        return index;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      //   render: (text) => <a>{text}</a>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amt) => {
        return <p className="mb-0">{`₦${amt}`}</p>;
      },
    },
    // {
    //   title: "Wallet Balance",
    //   dataIndex: "balance",
    //   key: "walletBalance",
    //   render: (bal) => {
    //     return <p className="mb-0">{`₦${bal}`}</p>;
    //   },
    // },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
      key: "transctionType",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (time) => (
        <p className="mb-0">{moment(time, "HH:mm:ss").format("hh:mm A")}</p>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "paymentMethod",
      render: (payment) => (
        <p className="mb-0">{payment === "transfer" ? "Transfer" : "Cash"}</p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const capitalized = status.charAt(0).toUpperCase() + status.slice(1);
        return (
          <p
            className={`mb-0 ${
              capitalized === "Successful" ? "successful" : "failed"
            }`}
          >
            {capitalized}
          </p>
        );
      },
    },

    {
      title: " ",
      key: "action",
      render: () => <BsThreeDotsVertical style={{ cursor: "pointer" }} />,
    },
  ];

  return (
    <div>
      <div className="requestss">
        <div className="cardss p-0 py-1 px-2">
          <div className="buttonHolders py-3">
            <button className="styled def" style={{ color: "#fff" }}>
              Transactions
            </button>
            {/* <button className="unstyled def ml-2" onClick={switchToFundCenter}>
              Fund Collection Center
            </button> */}
            <button className="unstyled def ml-2" onClick={switchToFundDriver}>
              Fund Driver
            </button>
            <button className="unstyled def ml-2" onClick={switchToFloat}>
              Float Requests
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex mb-3">
              <b>Transactions </b>({transactionsList.length})
              <div>
                <div className="form-check form-check-inline ml-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                    // checked
                    onClick={() => setSelected("all")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    All
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="option1"
                    onClick={() => setSelected("credit")}
                  />
                  <label className="form-check-label" for="inlineRadio2">
                    Credit
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    value="option1"
                    onClick={() => setSelected("debit")}
                  />
                  <label className="form-check-label" for="inlineRadio3">
                    Debit
                  </label>
                </div>
              </div>
            </div>
            <div className="fpw mr-2">
              <div className="d-flex align-items-centers">
                <p
                  style={{ color: "#231F20", fontSize: "13px" }}
                  className="mr-4"
                >
                  Export Table
                  <span style={{ color: "#c4da00", marginLeft: "5px" }}>
                    &#62;
                  </span>
                </p>
                <p style={{ color: "#231F20", fontSize: "13px" }} className="">
                  Filter By
                  <span style={{ color: "#c4da00", marginLeft: "5px" }}>
                    &#62;
                  </span>
                </p>
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={transactionsList}
            size="middle"
            pagination={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Transaction;
