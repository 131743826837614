import React, { useState, useEffect } from "react";
import Map from ".";
import DistanceMatrixComponent from "./distanceMatrix";
import { useLoadScript } from "@react-google-maps/api";
import NewMapComponent from "./newMap";

const MapWrapper = () => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_DIRECTION_KEY,
    // googleMapsApiKey: "AIzaSyDL5GhraegCpjAzomRuFlMQSk4GQZE0794",
    libraries: ["places"],
  });
  // console.log(process.env.REACT_GOOGLE_DIRECTION_KEY);
  const initiateDetails = JSON.parse(localStorage.getItem("initiate"));

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      // console.log("Called after 1 sec!");
      getLocation();
    }, 1000);

    return () => clearTimeout(timeout);
  }, [latitude, longitude]);

  useEffect(() => {
    getLocation();
  }, []);

  if (!isLoaded) return <div>Loading ...</div>;

  return (
    <div className="mapContainer">
      <NewMapComponent
        directionDetails={initiateDetails}
        longitude={longitude}
        latitude={latitude}
      />
    </div>
  );
};

export default MapWrapper;
