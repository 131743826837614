import React from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import green from "../../../../../assets/images/success.png";

const TransactionSuccessfulModal = ({ showSuccess, handleCloseSuccess }) => {
  const initiateDetails = JSON.parse(localStorage.getItem("initiate"));
  const pickUpDetails = JSON.parse(localStorage.getItem("pickUp"));
  const pickUpDetail = JSON.parse(localStorage.getItem("pickUpDetails"));
  const history = useHistory();
  return (
    <div>
      <Modal
        show={showSuccess}
        onHide={() => {
          handleCloseSuccess();
          history.push("/drivers/dashboard");
          localStorage.removeItem("pickUpDetails");
          //   history.push("/drivers/start");
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="py-3">
              <img src={green} alt="green" style={{ width: "60%" }} />
            </div>

            <div className="pb-4">
              <p
                className="mt-1 mb-3"
                style={{ fontWeight: "500", fontSize: "18px" }}
              >
                Transaction Successful
              </p>
              <p
                className="mt-1 mb-5"
                style={{ fontWeight: "400", fontSize: "14px" }}
              >
                {initiateDetails?.owner?.name} received{" "}
                {pickUpDetail
                  ? `₦${pickUpDetail?.pickup_details?.total_price}`
                  : `₦${pickUpDetails?.pickup_details?.total_price}`}{" "}
                from you
              </p>
              <div className="pb-3">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={() => {
                    history.push("/drivers/dashboard");
                    localStorage.removeItem("pickUpDetails");
                  }}
                  //   disabled={loading}
                >
                  Back to Home
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TransactionSuccessfulModal;
