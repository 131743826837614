import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Avatar from "../../../../../../../../assets/images/avatarr.png";

const FundModal = ({ handleCloseFund, showFund, handleShowAuthenticate }) => {
  const collectionsDetails = JSON.parse(localStorage.getItem("collections"));
  const [amount, setAmount] = useState("");
  // console.log(collectionsDetails);
  return (
    <div>
      <Modal
        show={showFund}
        onHide={handleCloseFund}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <h5 className="sec-headers mb-3">Fund Collection Center</h5>

              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="imgAvatarHolder ml-0">
                    <img src={Avatar} alt="image" />
                  </div>
                  <div className="ml-2">
                    <p
                      className="mb-0"
                      style={{ fontSize: "14px", fontWeight: "500" }}
                    >
                      {collectionsDetails?.name}
                    </p>
                  </div>
                </div>

                <div className="mr-4">
                  Wallet Balance:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {collectionsDetails?.chams_wallet.account_balance}
                  </span>
                </div>
              </div>
              <div className="d-flex">
                <div></div>
                <div className="ml-2">
                  <p className="mb-0 ml-5" style={{ fontSize: "12px" }}>
                    {collectionsDetails?.phone}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-50">
              <div className="mb-3 mt-4">
                <label className="mb-1 form-label">Wallet Amount</label>
                <input
                  type="text"
                  className="form-control form-control-lg inputStyle w-75"
                  placeholder="0.00"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>

              <div className="mt-4 mb-5 w-50">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block"
                  onClick={() => {
                    if (!amount) {
                      toast.error("Enter amount", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        progress: undefined,
                        theme: "light",
                      });
                    } else {
                      localStorage.setItem("amounts", JSON.stringify(amount));
                      handleCloseFund();
                      handleShowAuthenticate();
                    }
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FundModal;
